<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Theme Customization
            </h1>
            <div v-if="this.applicationFrame == 'family-connect'" class="d-flex flex-wrap p-3 mx-4">
                <div class="w-50">
                    <h3 style="font-weight: 700">Company logo*</h3>
                    <input
                        type="file"
                        name="company_logo"
                        id="company_logo"
                        placeholder="Upload Company logo"
                        hidden
                        @change="uploadImage($event, 'dashboard_builder_logo')"
                        accept=" .jpg, .webp, .png"
                    />
                    <label for="company_logo" class="d-flex justify-content-between align-items-center upload-section">
                        <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company Logo</span>
                        <span
                            v-if="companyLogoLoading"
                            class="upload-btn px-5 py-3"
                            style="
                                display: flex;
                                gap: 10px;
                                padding-left: 15px !important;
                                padding-right: 15px !important;
                            "
                        >
                            <SpinLoader />Uploading
                        </span>
                        <span v-else class="upload-btn px-5 py-3">Upload</span>
                    </label>
                    <span v-if="companyLogoErr" style="color: red">{{ companyLogoErr }}</span>
                    <span style="color: red">{{ errors.logo }}</span>

                    <div class="d-flex justify-content-between align-items-center my-3">
                        <img
                            :src="companyLogo.image"
                            style="width: 100px; height: auto; margin: 0 15% 0 0"
                            v-if="companyLogo && companyLogo.file_id"
                        />
                        <img
                            src="@/assets/px/fc_logo.png"
                            alt="FamilyConnect"
                            title="FamilyConnect"
                            style="width: 100px; height: auto; margin: 0 15% 0 0"
                            v-else
                        />
                    </div>
                </div>
                <div class="w-50">
                    <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                    <div class="grid">
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Image extension should JPG, WEBP & PNG</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Resolution should not be less than 130/60px.</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                            <span class="scenario-text">Image size should be no more than 10 MB.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex flex-wrap p-3" style="gap: 0.5em">
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Select and Upload logo*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input
                                type="file"
                                name="company_logo"
                                id="company_logo"
                                placeholder="Upload Company logo"
                                hidden
                                @change="uploadImage($event, 'dashboard_builder_logo')"
                                accept="image/,.jpg, .webp, .png"
                                :disabled="companyLogoLoading"
                            />
                            <label
                                for="company_logo"
                                class="d-flex justify-content-between align-items-center upload-section"
                            >
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company Logo</span>
                                <span v-if="companyLogoLoading" class="upload-btn py-3 px-3 d-flex" style="gap: 10px">
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div
                                    v-for="logo in companyLogoImages"
                                    :key="logo.id"
                                    style="position: relative; text-align: -webkit-center"
                                >
                                    <img
                                        :src="logo.image"
                                        :class="companyLogo && logo.id == companyLogo.id ? 'active-bg' : ''"
                                        style="height: 100px; max-width: 100px; border-radius: 20px; cursor: pointer"
                                        @click="selectCompanyLogo(logo)"
                                    />
                                </div>
                            </div>
                        </div>
                        <span v-if="companyLogoErr" style="color: red">{{ companyLogoErr }}</span>
                        <span style="color: red">{{ errors.logo }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can choose only one file at a time.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extensions should JPG, WEBP and PNG.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    ['kiosk', 'mobex-health-plus-v1'].includes(applicationFrame) ||
                    (applicationFrame === 'mobex-health-hub' && current_template == 1)
                "
                class="d-flex flex-wrap p-3"
                style="gap: 0.5em"
            >
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Select and Upload Background*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input
                                type="file"
                                name="background"
                                id="background"
                                placeholder="Upload Company logo"
                                hidden
                                @change="uploadImage($event, 'dashboard_builder_background_image')"
                                accept="image/,.jpg, .webp, .png"
                            />
                            <label
                                for="background"
                                class="d-flex justify-content-between align-items-center upload-section"
                            >
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload Background</span>
                                <span v-if="bgImageLoading" class="upload-btn py-3 px-3 d-flex" style="gap: 10px">
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div
                                    v-for="bg in backgroundImages"
                                    :key="bg.id"
                                    @click="selectBackground(bg)"
                                    style="position: relative; text-align: -webkit-center"
                                    @mouseover="hoveredBackgroundElementId = bg.id"
                                    @mouseleave="hoveredBackgroundElementId = null"
                                >
                                    <label>
                                        <img
                                            :src="bg.image"
                                            style="
                                                height: 100px;
                                                max-width: 100px;
                                                border-radius: 20px;
                                                cursor: pointer;
                                            "
                                            :class="backgroundPhoto && bg.id == backgroundPhoto.id ? 'active-bg' : ''"
                                        />
                                        <template v-if="hoveredBackgroundElementId == bg.id">
                                            <div class="w-100 h-100 action-buttons">
                                                <ejs-tooltip
                                                    class="tooltipcontainer"
                                                    content="Delete"
                                                    target="#target"
                                                    cssClass="customtooltip"
                                                    position="BottomRight"
                                                >
                                                    <span
                                                        id="target"
                                                        class="background"
                                                        @click.stop="removeBackground(bg)"
                                                    >
                                                        <img
                                                            src="@/assets/px/delete-icon.png"
                                                            alt=""
                                                            style="height: 15px"
                                                        />
                                                    </span>
                                                </ejs-tooltip>
                                            </div>
                                        </template>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <span v-if="bgImageErr" style="color: red">{{ bgImageErr }}</span>
                        <span style="color: red">{{ errors.background }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can choose only one file at a time.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extensions should JPG, WEBP and PNG.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="applicationFrame === 'kiosk'" class="d-flex flex-wrap p-3" style="gap: 0.5em">
                <div class="w-100 mx-4">
                    <h3 style="font-weight: 700">Select and Upload waiting animation*</h3>
                </div>
                <div class="d-flex w-100">
                    <div class="w-50 mx-4">
                        <div class="my-3" style="width: 38vw">
                            <input
                                type="file"
                                name="waiting_animation"
                                id="waiting_animation"
                                placeholder=" upload waiting_animation"
                                hidden
                                @change="uploadImage($event, 'dashboard_builder_waiting_animation')"
                                accept=".gif"
                                :disabled="waitingAnimationLoading"
                            />
                            <label
                                for="waiting_animation"
                                class="d-flex justify-content-between align-items-center upload-section"
                            >
                                <span style="font-size: 14px; margin: 0 0 0 5%">Upload waiting animation</span>
                                <span
                                    v-if="waitingAnimationLoading"
                                    class="upload-btn py-3 px-3 d-flex"
                                    style="gap: 10px"
                                >
                                    <SpinLoader />Uploading
                                </span>
                                <span v-else class="upload-btn px-5 py-3">Upload</span>
                            </label>
                        </div>
                        <div class="scroll-Wrapper">
                            <div class="scroll-container" style="gap: 1%">
                                <div
                                    v-for="gif in waitingAnimationGifs"
                                    :key="gif.id"
                                    style="position: relative; text-align: -webkit-center"
                                >
                                    <img
                                        :src="gif.image"
                                        :class="gif.id == waitingAnimationGif.id ? 'active-bg' : ''"
                                        style="height: 100px; max-width: 100px; border-radius: 20px; cursor: pointer"
                                        @click="selectWaitingAnimation(gif)"
                                    />
                                </div>
                            </div>
                        </div>
                        <span v-if="waitingAnimationError" style="color: red">{{ waitingAnimationError }}</span>
                        <span style="color: red">{{ errors.waitingAnimation }}</span>
                    </div>
                    <div class="w-50">
                        <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                        <div class="grid">
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">You can choose only one file at a time.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image extensions should GIF.</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                <span class="scenario-text">Image size should be no more than 10MB.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)"
                class="d-grid flex-wrap p-4"
                style="gap: 0.5em"
            >
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Button Color Scheme*</h3>
                </div>
                <div class="d-flex flex-wrap justify-content-between" style="gap: 1%">
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                <input
                                    type="radio"
                                    name="color_type_kiosk"
                                    id="primary_colors"
                                    value="primary_colors"
                                    style="transform: scale(0.8)"
                                    v-model="colorScheme.selectedOption"
                                    @change="selectColorScheme($event)"
                                />
                            </div>
                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                <div
                                    v-for="(value, index) in colorScheme.primary_colors"
                                    class="d-flex align-items-center px-2 w-25"
                                    :key="index"
                                    :style="{
                                        height: '30px',
                                        width: '50%',
                                        background: value,
                                        color: '#FFFFFF',
                                        'font-size': '12px',
                                        border: '2px solid black',
                                        'text-transform': 'uppercase',
                                    }"
                                >
                                    {{ value }}
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Primary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Secondary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Secondary Color Option</label>
                                <input
                                    type="radio"
                                    name="color_type_kiosk"
                                    id="secondary_colors"
                                    value="secondary_colors"
                                    style="transform: scale(0.8)"
                                    v-model="colorScheme.selectedOption"
                                    @change="selectColorScheme($event)"
                                />
                            </div>
                            <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                                <div
                                    v-for="(value, index) in colorScheme.secondary_colors"
                                    class="d-flex align-items-center px-2 w-25"
                                    :key="index"
                                    :style="{
                                        height: '30px',
                                        width: '50%',
                                        background: value,
                                        color: '#FFFFFF',
                                        'font-size': '12px',
                                        border: '2px solid black',
                                        'text-transform': 'uppercase',
                                    }"
                                >
                                    {{ value }}
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Primary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Secondary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                        <div class="m-4">
                            <div class="d-flex" style="gap: 1%">
                                <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                <input
                                    type="radio"
                                    name="color_type_kiosk"
                                    id="customized_colors"
                                    value="customized_colors"
                                    style="transform: scale(0.8)"
                                    v-model="colorScheme.selectedOption"
                                    @change="selectColorScheme($event)"
                                />
                            </div>

                            <label class="d-flex py-2" style="gap: 1%" for="customized_colors">
                                <div
                                    v-for="(value, index) in colorScheme.customized_colors"
                                    :key="index"
                                    class="d-flex align-items-center px-2 w-25"
                                >
                                    <input
                                        v-if="colorScheme.selectedOption == 'customized_colors'"
                                        type="color"
                                        name=""
                                        id=""
                                        v-model="colorScheme.customized_colors[index]"
                                        @change="selectCustomColorScheme($event, index)"
                                        class="w-100 p-0"
                                    />
                                    <div v-else class="default-color w-100"></div>
                                </div>
                            </label>
                            <label class="d-flex py-2 w-100" style="gap: 1%" for="default">
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Primary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Secondary Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Color
                                </div>
                                <div
                                    class="d-flex align-items-center w-25 justify-content-center"
                                    style="font-weight: 600; color: #000; font-size: 12px"
                                >
                                    Button Text Color
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <span style="color: red">{{ errors.colorScheme }}</span>
            </div>

            <div v-if="['mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)" class="p-4">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">App Color Scheme*</h3>
                </div>
                <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                    <div class="m-4">
                        <label class="d-flex py-2" style="gap: 1%" for="customized_color">
                            <div class="w-25">
                                <input type="color" name="" id="" v-model="appColors.background_color" class="w-100" />
                            </div>
                            <div class="w-25">
                                <input
                                    type="color"
                                    name=""
                                    id=""
                                    v-model="appColors.background_text_color"
                                    class="w-100"
                                />
                            </div>
                            <div class="w-25">
                                <input type="color" name="" id="" v-model="appColors.canvas_color" class="w-100" />
                            </div>
                            <div class="w-25">
                                <input type="color" name="" id="" v-model="appColors.canvas_text_color" class="w-100" />
                            </div>
                        </label>
                        <label class="d-flex py-2 w-100" style="gap: 1%">
                            <div
                                class="d-flex align-items-center w-25 justify-content-center"
                                style="font-weight: 600; color: #000; font-size: 12px"
                            >
                                Background Color
                            </div>
                            <div
                                class="d-flex align-items-center w-25 justify-content-center"
                                style="font-weight: 600; color: #000; font-size: 12px"
                            >
                                Background Text Color
                            </div>
                            <div
                                class="d-flex align-items-center w-25 justify-content-center"
                                style="font-weight: 600; color: #000; font-size: 12px"
                            >
                                Canvas Color
                            </div>
                            <div
                                class="d-flex align-items-center w-25 justify-content-center"
                                style="font-weight: 600; color: #000; font-size: 12px"
                            >
                                Canvas Text Color
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="p-4" v-if="this.applicationFrame == 'mobex-health-plus-v1'">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Application Color Theme*</h3>
                </div>
                <div class="d-flex flex-wrap justify-content-between">
                    <div class="d-flex color-box w-100 p-4">
                        <div class="d-flex flex-wrap justify-content-between gap-4 py-2 w-100">
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Top Header
                                <input
                                    type="color"
                                    v-model="colorScheme.topHeader"
                                    @input="handleMHPColorTheme"
                                    class="w-25"
                                />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Hover Color
                                <input
                                    class="w-25"
                                    type="color"
                                    v-model="colorScheme.hoverColor"
                                    @input="handleMHPColorTheme"
                                />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Font Color
                                <input
                                    class="w-25"
                                    type="color"
                                    v-model="colorScheme.fontColor"
                                    @input="handleMHPColorTheme"
                                />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Primary Button Color
                                <input
                                    class="w-25"
                                    type="color"
                                    v-model="colorScheme.primaryColor"
                                    @input="handleMHPColorTheme"
                                />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Secondary Button Color
                                <input
                                    class="w-25"
                                    type="color"
                                    v-model="colorScheme.secondaryColor"
                                    @input="handleMHPColorTheme"
                                />
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-between mx-2"
                                style="font-weight: 600; color: #000; font-size: 12px; width: 30%"
                            >
                                Outline Button Color
                                <input
                                    class="w-25"
                                    type="color"
                                    v-model="colorScheme.buttonColor"
                                    @input="handleMHPColorTheme"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <span style="color: red">{{ errors.colorScheme }}</span>
            </div>

            <div class="d-grid flex-wrap p-4" v-if="this.applicationFrame == 'mobex-health-hub'" style="gap: 0.5em">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Color Scheme</h3>
                </div>
                <div class="d-flex flex-wrap" style="gap: 1%">
                    <div class="d-flex flex-wrap w-100" style="gap: 1%">
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="default" style="font-weight: bold">Default Color</label>
                                    <input
                                        type="radio"
                                        name="color_type"
                                        id="default"
                                        value="default"
                                        v-model="colorScheme.selectedOption"
                                        style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)"
                                    />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="default">
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #595bc4; color: #fff; font-size: 10px"
                                    >
                                        #595BC4
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #ac2734; color: #fff; font-size: 10px"
                                    >
                                        #AC2734
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #4cbc9a; color: #fff; font-size: 10px"
                                    >
                                        #4CBC9A
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="option1" style="font-weight: bold">Option 1</label>
                                    <input
                                        type="radio"
                                        name="color_type"
                                        id="option1"
                                        value="option1"
                                        v-model="colorScheme.selectedOption"
                                        style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)"
                                    />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="option1">
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #000; color: #fff; font-size: 10px"
                                    >
                                        #000
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #3858e9; color: #fff; font-size: 10px"
                                    >
                                        #3858E9
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #15ae80; color: #fff; font-size: 10px"
                                    >
                                        #15AE80
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="option2" style="font-weight: bold">Option 2</label>
                                    <input
                                        type="radio"
                                        name="color_type"
                                        id="option2"
                                        value="option2"
                                        v-model="colorScheme.selectedOption"
                                        style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)"
                                    />
                                </div>
                                <label class="d-flex py-2" style="gap: 1%" for="option2">
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #2271b1; color: #fff; font-size: 10px"
                                    >
                                        #2271B1
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #9ea476; color: #fff; font-size: 10px"
                                    >
                                        #9EA476
                                    </div>
                                    <div
                                        class="w-33 d-flex align-items-center px-2"
                                        style="height: 30px; background: #dd823b; color: #fff; font-size: 10px"
                                    >
                                        #DD823B
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid color-box m-3">
                            <div class="m-4">
                                <div class="d-flex" style="gap: 1%">
                                    <label for="customized_color" style="font-weight: bold"
                                        >Customize Color Option</label
                                    >
                                    <input
                                        type="radio"
                                        name="color_type"
                                        id="customized_color"
                                        value="customized_color"
                                        v-model="colorScheme.selectedOption"
                                        style="transform: scale(0.8)"
                                        @change="selectColorScheme($event)"
                                    />
                                </div>
                                <label
                                    class="d-flex py-2"
                                    style="gap: 1%; flex-direction: column"
                                    for="customized_color"
                                >
                                    <div class="d-flex align-items-center py-1" style="height: 1px; font-size: 11px">
                                        Button Color
                                    </div>
                                    <div class="d-flex py-2" style="gap: 1%">
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[0][0]"
                                                @change="selectCustomColorScheme($event, 1)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[1][0]"
                                                @change="selectCustomColorScheme($event, 2)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[2][0]"
                                                @change="selectCustomColorScheme($event, 3)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-1" style="height: 1px; font-size: 11px">
                                        Button Text Color
                                    </div>
                                    <div class="d-flex py-2" style="gap: 1%">
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[0][1]"
                                                @change="selectCustomColorScheme($event, 4)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[1][1]"
                                                @change="selectCustomColorScheme($event, 5)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                        <div class="w-33">
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                value="#efefef"
                                                class="w-100"
                                                v-if="colorScheme.selectedOption == 'customized_color'"
                                                v-model="colorScheme.colors[2][1]"
                                                @change="selectCustomColorScheme($event, 6)"
                                            />
                                            <input
                                                type="color"
                                                name=""
                                                id=""
                                                :value="defaultColor"
                                                class="w-100"
                                                disabled
                                                v-else
                                            />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <span style="color: red">{{ errors.colorScheme }}</span>
                </div>
            </div>

            <template v-if="applicationFrame == 'kiosk' && enableQR">
                <div class="d-flex px-3 py-2 mx-4">
                    <span class="d-flex align-items-center">
                        <input
                            id="showCompanyLogoInQR"
                            type="checkbox"
                            class="color"
                            :checked="showCompanyLogoInQR"
                            @input="handleCompanyLogoInQR($event)"
                        />
                        <label for="showCompanyLogoInQR">
                            <h3 style="font-weight: 700">Show company logo in QR</h3>
                        </label>
                    </span>
                </div>

                <div v-if="showCompanyLogoInQR" class="d-flex flex-wrap p-3" style="gap: 0.5em">
                    <div class="w-100 mx-4">
                        <h3 style="font-weight: 700">Select and Upload logo (For QR code use only)*</h3>
                    </div>
                    <div class="d-flex w-100">
                        <div class="w-50 mx-4">
                            <div class="my-3" style="width: 38vw">
                                <input
                                    type="file"
                                    name="qr_company_logo"
                                    id="qr_company_logo"
                                    placeholder="Upload Company logo for QR"
                                    hidden
                                    @change="uploadImage($event, 'dashboard_builder_qr_logo')"
                                    accept="image/,.jpg, .webp, .png"
                                    :disabled="qrLogoLoading"
                                />
                                <label
                                    for="qr_company_logo"
                                    class="d-flex justify-content-between align-items-center upload-section"
                                >
                                    <span style="font-size: 14px; margin: 0 0 0 5%">Upload Company Logo for QR</span>
                                    <span v-if="qrLogoLoading" class="upload-btn py-3 px-3 d-flex" style="gap: 10px">
                                        <SpinLoader />Uploading
                                    </span>
                                    <span v-else class="upload-btn px-5 py-3">Upload</span>
                                </label>
                            </div>
                            <div class="scroll-Wrapper">
                                <div class="scroll-container" style="gap: 1%">
                                    <div
                                        v-for="logo in qrLogoImages"
                                        :key="logo.id"
                                        style="position: relative; text-align: -webkit-center"
                                    >
                                        <img
                                            :src="logo.image"
                                            :class="qrLogo && logo.id == qrLogo.id ? 'active-bg' : ''"
                                            style="
                                                height: 100px;
                                                max-width: 100px;
                                                border-radius: 20px;
                                                cursor: pointer;
                                            "
                                            @click="selectQRCompanyLogo(logo)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <span v-if="qrLogoErr" style="color: red">{{ qrLogoErr }}</span>
                            <span style="color: red">{{ errors.qrLogo }}</span>
                        </div>
                        <div class="w-50">
                            <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                            <div class="grid">
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">You can choose only one file at a time.</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Image extensions should JPG, WEBP and PNG.</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Resolution should be not less than 1920/1080px</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Image size should be no more than 10MB.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="applicationFrame == 'kiosk' && enableQR">
                <h3 v-if="$route.query.status === 'draft'" class="px-3 py-2 mx-4" style="font-weight: 700">
                    Note: QR Code only displays when Dashboard is fully Created
                </h3>
                <div v-else class="d-flex px-3 py-2 mx-4">
                    <span class="d-flex align-items-center">
                        <input
                            id="showStaticQR"
                            type="checkbox"
                            class="color"
                            :checked="showStaticQR"
                            @input="handleStaticQR($event)"
                        />
                        <label for="showStaticQR">
                            <h3 style="font-weight: 700">Display Static QR</h3>
                        </label>
                    </span>
                </div>
                <div v-if="showStaticQR" class="d-flex flex-column p-3">
                    <div class="w-100 mx-4 d-flex gap-4 align-items-center">
                        <h3 style="font-weight: 700">Scan Here to take me Home</h3>
                    </div>
                    <div
                        class="d-flex mx-4"
                        style="width: 200px"
                        @mouseover="hoverQRImage = true"
                        @mouseleave="hoverQRImage = false"
                    >
                        <img
                            v-if="qrImage"
                            :src="qrImage"
                            ref="qrImage"
                            alt="Static QR"
                            width="200"
                            style="height: 200px"
                        />
                        <div v-if="hoverQRImage == true" class="overlay">
                            <span class="upload-btn p-2 d-flex gap-2 align-items-center px-4" @click="downloadQRImage"
                                ><span class="material-symbols-outlined"> download </span>
                                Download
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="applicationFrame == 'kiosk'">
                <div class="d-flex px-3 py-2 mx-4">
                    <span class="d-flex align-items-center">
                        <input
                            id="showSplashScreen"
                            type="checkbox"
                            class="color"
                            :checked="showSplashScreen"
                            @input="handleSplashScreen($event)"
                        />
                        <label for="showSplashScreen">
                            <h3 style="font-weight: 700">Show Splash Screen</h3>
                        </label>
                    </span>
                </div>

                <div v-if="showSplashScreen" class="px-4 mx-4">
                    <span class="d-flex align-items-center my-2">
                        <Toggle
                            id="showDisplayName"
                            class="toggle-switch"
                            labelClass="switch"
                            spanClass="slider"
                            :value="showDisplayName"
                            @input="handleDisplayName($event)"
                        />
                        <label for="showDisplayName">
                            <h3 style="font-weight: 700">Display name</h3>
                        </label>
                    </span>
                    <span>
                        <label for="splashScreenMessage" style="font-weight: 700">Message </label>
                        <TextArea
                            :id="'splashScreenMessage'"
                            :name="'splashScreenMessage'"
                            :inputClass="'my-2'"
                            :rows="3"
                            :limit="500"
                            :placeholder="'Write your message'"
                            v-model="splashScreenMessage"
                            @input="handleSplashScreenMessage($event)"
                        />
                    </span>
                    <span style="color: red; font-weight: normal">{{ errors.splashScreenMessage }}</span>
                </div>
            </template>

            <template v-if="applicationFrame == 'kiosk'">
                <div class="d-flex px-3 py-2 mx-4">
                    <span class="d-flex align-items-center">
                        <input
                            type="checkbox"
                            id="showScreenSaver"
                            class="color"
                            :checked="showScreenSaver"
                            @input="handleScreenSaver($event)"
                        />
                        <label for="showScreenSaver">
                            <h3 style="font-weight: 700">Show Screen Saver</h3>
                        </label>
                    </span>
                </div>

                <div
                    class="d-flex flex-wrap p-3"
                    style="gap: 0.5em"
                    v-if="this.applicationFrame == 'kiosk' && showScreenSaver"
                >
                    <div class="w-100 mx-4">
                        <h3 style="font-weight: 700">Screen Saver Configuration*</h3>
                    </div>
                    <div class="d-flex w-100">
                        <div class="w-50 mx-4">
                            <div class="my-3" style="width: 38vw">
                                <input
                                    type="file"
                                    name="digital_signage"
                                    id="digital_signage"
                                    placeholder="Upload Screen Saver Image"
                                    hidden
                                    @change="uploadDigitalSignage($event)"
                                    accept=" .jpg, .webp, .png"
                                    multiple
                                    :disabled="digitalSignageLoading"
                                />
                                <label
                                    for="digital_signage"
                                    class="d-flex justify-content-between align-items-center upload-section"
                                    :style="{ cursor: digitalSignageLoading ? 'progress' : '' }"
                                >
                                    <span style="font-size: 14px; margin: 0 0 0 5%">Upload Screen Saver Image</span>
                                    <span
                                        v-if="digitalSignageLoading"
                                        class="upload-btn py-3 px-3 d-flex"
                                        style="gap: 10px"
                                    >
                                        <SpinLoader />Uploading
                                    </span>
                                    <span v-else class="upload-btn px-5 py-3">Upload</span>
                                </label>
                            </div>
                            <div class="scroll-Wrapper">
                                <div class="scroll-container" style="gap: 1%">
                                    <div
                                        v-for="ds in digitalSignageImages"
                                        :key="ds.id"
                                        style="position: relative; text-align: -webkit-center"
                                        @click="selectDigitalSignageImage(ds)"
                                        @mouseover="hoveredElementId = ds.id"
                                        @mouseleave="hoveredElementId = null"
                                    >
                                        <label>
                                            <img
                                                :src="ds.image"
                                                style="height: 100px; max-width: 100px; border-radius: 20px"
                                                :class="ds.selected ? 'active-bg' : ''"
                                            />
                                            <template v-if="hoveredElementId == ds.id">
                                                <div class="w-100 h-100 action-buttons">
                                                    <ejs-tooltip
                                                        class="tooltipcontainer"
                                                        content="Delete"
                                                        target="#target"
                                                        cssClass="customtooltip"
                                                        position="BottomRight"
                                                    >
                                                        <span
                                                            id="target"
                                                            class="background"
                                                            @click.stop="removeSignageImage(ds)"
                                                        >
                                                            <img
                                                                src="@/assets/px/delete-icon.png"
                                                                alt=""
                                                                style="height: 15px"
                                                            />
                                                        </span>
                                                    </ejs-tooltip>
                                                    <ejs-tooltip
                                                        v-if="ds.selected && showQRConfigureOption"
                                                        class="tooltipcontainer"
                                                        content="Edit"
                                                        target="#target"
                                                        cssClass="customtooltip"
                                                        position="BottomRight"
                                                    >
                                                        <span
                                                            id="target"
                                                            class="background"
                                                            @click.stop="openConfigureQR(ds)"
                                                        >
                                                            <img
                                                                src="@/assets/px/edit-icon.png"
                                                                alt=""
                                                                style="height: 15px"
                                                            />
                                                        </span>
                                                    </ejs-tooltip>
                                                </div>
                                            </template>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span v-if="digitalSignageErr" style="color: red">{{ digitalSignageErr }}</span>
                            <span style="color: red">{{ errors.digitalSignage }}</span>
                        </div>
                        <div class="w-50">
                            <h6 style="font-weight: 700">Scenarios for Image Upload</h6>
                            <div class="grid">
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">At least one image object is required</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Image extension should JPG, WEBP & PNG</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Resolution should be not less than 1920/1080px.</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">Image size should be no more than 10MB.</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="material-symbols-outlined icon scenario-item"> check_small </span>
                                    <span class="scenario-text">You can add only 10 images at maximum.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex w-100" style="margin-bottom: 20px">
                        <div class="w-50 mx-4">
                            <h3 style="font-weight: 700">Image Display Time (in seconds)*</h3>
                            <input
                                required
                                type="number"
                                v-model="timeText"
                                name="timeText"
                                id="timeText"
                                class="w-80"
                                min="1"
                                max="1800"
                                placeholder="Enter input in Seconds"
                                @input="handleTimeText($event)"
                            />
                            <span v-if="errors.timeText" style="color: red; font-weight: normal">{{
                                errors.timeText
                            }}</span>
                            <span v-else style="color: red; font-weight: normal">{{ timeTextErr }}</span>
                        </div>
                        <div class="w-50" style="margin-left: 5rem">
                            <h3 style="font-weight: 700">Screen Timeout (in seconds)*</h3>
                            <input
                                required
                                type="number"
                                v-model="timeoutText"
                                name="timeoutText"
                                id="timeoutText"
                                class="w-80"
                                min="1"
                                max="1800"
                                placeholder="Enter input in Seconds"
                                @input="handleTimeoutText($event)"
                            />
                            <span v-if="errors.timeoutText" style="color: red; font-weight: normal">{{
                                errors.timeoutText
                            }}</span>
                            <span v-else style="color: red; font-weight: normal">{{ timeoutTextErr }}</span>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="showSensitiveScreenTimeout" class="d-flex w-100 p-2" style="margin-bottom: 20px">
                <div class="w-50" style="margin-left: 2rem">
                    <h3 style="font-weight: 700">Sensitive Screen Timeout*</h3>
                    <Multiselect
                        id="sensitiveScreenTimeout"
                        track-by="id"
                        label="value"
                        class="position-relative w-80"
                        placeholder="Select timeout"
                        v-model="sensitiveScreenTimeout"
                        :options="sensitiveScreenTimeoutList"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="handleSensitiveScreenTimeout"
                    />
                    <span style="color: red; font-weight: normal">{{ errors.sensitiveScreenTimeout }}</span>
                </div>
                <div class="w-50" style="margin-left: 5rem">
                    <h3 style="font-weight: 700">Sensitive Screen Inactivity Timeout*</h3>
                    <Multiselect
                        id="sensitiveAlertTimeout"
                        track-by="id"
                        label="value"
                        class="position-relative w-80"
                        placeholder="Select timeout"
                        v-model="sensitiveAlertTimeout"
                        :options="sensitiveAlertTimeoutList"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @input="handleSensitiveAlertTimeout"
                    />
                    <span style="color: red; font-weight: normal">{{ errors.sensitiveAlertTimeout }}</span>
                </div>
            </div>

            <div class="w-90 p-2" style="margin-bottom: 20px">
                <div class="w-50" style="margin-left: 2rem; width: 21rem">
                    <h3 style="font-weight: 700">Buffer Duration (in seconds)*</h3>
                    <input
                        required
                        type="number"
                        v-model="bufferDuration"
                        name="bufferDuration"
                        id="bufferDuration"
                        class="w-80"
                        min="1"
                        max="86400"
                        placeholder="Enter input in Seconds"
                        @input="handleBufferDuration($event)"
                    />
                    <span v-if="errors.bufferDuration" style="color: red; font-weight: normal">{{
                        errors.bufferDuration
                    }}</span>
                    <span v-else style="color: red; font-weight: normal">{{ bufferDurationErr }}</span>
                </div>
            </div>
        </div>

        <ConfigureQRModal
            v-if="applicationFrame === 'kiosk' && showConfigureQR"
            :screenSaverData="screenSaverData"
            @save="saveConfigureQR"
            @cancel="showConfigureQR = false"
        />

        <Loading v-if="loading" />
    </div>
</template>
<script>
    import { dashboardBuilder, memberProfile, companies, cardColors } from '@/util/apiRequests';
    import ConfigureQRModal from './configureQRModal.vue';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import Loading from '@/components/general/loading/loading.vue';

    const sensitiveScreenTimeoutList = [
        {
            id: 30,
            value: '30 seconds',
        },
        {
            id: 60,
            value: '1 minute',
        },
        {
            id: 120,
            value: '2 minutes',
        },
        {
            id: 180,
            value: '3 minutes',
        },
        {
            id: 240,
            value: '4 minutes',
        },
        {
            id: 300,
            value: '5 minutes',
        },
    ];

    const sensitiveAlertTimeoutList = [
        {
            id: 15,
            value: '15 seconds',
        },
        {
            id: 30,
            value: '30 seconds',
        },
        {
            id: 60,
            value: '1 minute',
        },
    ];

    const initialAppColors = {
        background_color: '#F1f7ff',
        background_text_color: '#000000',
        canvas_color: '#1773b1',
        canvas_text_color: '#ffffff',
    };

    export default {
        name: 'CustomisedThemeDesign',
        props: {
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
            },
            errors: {
                type: Object,
            },
            selected_language: {
                type: String,
            },
            current_template: {
                type: Number,
            },
        },
        components: {
            ConfigureQRModal,
            SpinLoader,
            Loading,
        },

        data() {
            return {
                companyLogo: null,
                companyLogoErr: '',
                colorScheme: {},
                backgroundImages: [],
                backgroundPhoto: null,
                bgImageErr: '',
                digitalSignageErr: '',
                qrLogoErr: '',
                digitalSignageImages: [],
                selectedDigitalSignageImages: [],
                companyLogoImages: [],
                qrLogoImages: [],
                allImages: [],
                qrLogo: {},
                hoveredElementId: null,
                hoveredBackgroundElementId: null,
                timeText: null,
                timeoutText: null,
                timeTextErr: '',
                timeoutTextErr: '',
                bufferDuration: null,
                bufferDurationErr: '',
                dashboardId: '',
                defaultColor: '#efefef',
                changedColor: {},
                digitalSignageLoading: false,
                companyLogoLoading: false,
                qrLogoLoading: false,
                bgImageLoading: false,
                loading: true,
                showSplashScreen: false,
                showDisplayName: true,
                splashScreenMessage: '',
                showScreenSaver: true,
                selectedLanguage: null,
                sensitiveScreenTimeout: '',
                sensitiveScreenTimeoutList,
                sensitiveAlertTimeout: '',
                sensitiveAlertTimeoutList,
                waitingAnimationLoading: false,
                waitingAnimationGifs: [],
                waitingAnimationGif: {},
                waitingAnimationError: '',
                showConfigureQR: false,
                screenSaverData: null,
                showCompanyLogoInQR: false,
                enableQR: false,
                showStaticQR: false,
                hoverQRImage: false,
                qrImage: null,
                qrFileName: null,
                companyDetails: {},
                appColors: { ...initialAppColors },
            };
        },
        computed: {
            showSensitiveScreenTimeout() {
                return (
                    this.applicationFrame === 'kiosk' ||
                    (this.applicationFrame === 'mobex-health-hub' && this.current_template == 1)
                );
            },
            showQRConfigureOption() {
                const { enableQR, showQR } = this.pageData?.page2;
                return enableQR && showQR;
            },
        },
        methods: {
            async uploadImage(event, type) {
                type == 'dashboard_builder_logo' ? (this.companyLogoErr = '') : (this.bgImageErr = '');

                let file = event.target.files[0];
                if (file.size / 1024 / 1024 > 10) {
                    this.bgImageErr = 'Size should not be greater than 10MB';
                    return;
                }

                if (type == 'dashboard_builder_logo') {
                    this.companyLogoLoading = true;
                } else if (type == 'dashboard_builder_waiting_animation') {
                    this.waitingAnimationLoading = true;
                } else if (type == 'dashboard_builder_qr_logo') {
                    this.qrLogoLoading = true;
                } else {
                    this.bgImageLoading = true;
                }

                var reader = new FileReader();
                reader.readAsDataURL(file);
                let that = this;

                reader.onload = async function () {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('type', type);

                    const endpoint = dashboardBuilder.uploadImageAssets(that.dashboardId, type, 0);

                    let response = await that.$api.post(endpoint, formData);

                    if (response.data.success) {
                        if (type == 'dashboard_builder_logo') {
                            await that.getCompanyLogos(response.data.s3_link);
                            that.companyLogoLoading = false;
                        } else if (type == 'dashboard_builder_waiting_animation') {
                            await that.getWaitingAnimation(response.data.s3_link);
                            that.waitingAnimationLoading = false;
                        } else if (type == 'dashboard_builder_qr_logo') {
                            await that.getQRLogos(response.data.s3_link);
                            that.qrLogoLoading = false;
                        } else {
                            await that.getBackgroundImages(response.data.s3_link);
                            that.bgImageLoading = false;
                        }
                    }
                };
                reader.onerror = function (error) {
                    alert('Failed to upload background image !', error);
                    if (type == 'dashboard_builder_logo') {
                        that.companyLogoLoading = false;
                    } else if (type == 'dashboard_builder_waiting_animation') {
                        that.waitingAnimationLoading = false;
                    } else {
                        that.bgImageLoading = false;
                    }
                };
            },
            async uploadDigitalSignage(event) {
                this.digitalSignageErr = '';
                let files = event.target.files;
                let totalImages = files.length + this.digitalSignageImages.length;

                let successArray = [];
                let successCount = 0;
                let uploadedImages = [];
                this.digitalSignageLoading = true;

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];

                    if (file.size / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        continue;
                    }
                    successArray.push(
                        new Promise((resolve) => {
                            var reader = new FileReader();
                            let that = this;
                            reader.onload = function () {
                                let formData = new FormData();
                                formData.append('file', file);
                                formData.append('type', 'dashboard_builder_digital_signage_image');

                                const endpoint = dashboardBuilder.uploadImageAssets(
                                    that.dashboardId,
                                    'dashboard_builder_digital_signage_image',
                                    0
                                );

                                that.$api
                                    .post(endpoint, formData)
                                    .then((response) => {
                                        if (response.data.success) {
                                            resolve(true);
                                            that.digitalSignageErr = null;
                                            successCount++;
                                            uploadedImages.push(response.data);
                                            //leftImages--
                                        } else {
                                            resolve(false);
                                        }
                                    })
                                    .catch((error) => {
                                        resolve(false);
                                        console.error('Error uploading image:', error);
                                    });
                            };

                            reader.readAsDataURL(file);
                        })
                    );
                }

                this.digitalSignageErr = '';

                Promise.all(successArray).then(async (results) => {
                    const success = results.every((result) => result === true);
                    if (success) {
                        await this.getDigitalSignageImages(uploadedImages);
                        if (successCount != 0 && (files.length == 10 || totalImages == 10)) {
                            if (successCount == 1) {
                                this.$toasted.success(
                                    successCount + ' Image uploaded successfully! You have uploaded total 10 images.'
                                );
                            } else {
                                this.$toasted.success(
                                    successCount + ' Images uploaded successfully! You have uploaded total 10 images.'
                                );
                            }
                        } else if (successCount == 0 && files.length > 0) {
                            this.$toasted.error('No image uploaded! Image size should be less than 10MB');
                        } else if (successCount == 1) {
                            this.$toasted.success(successCount + ' Image uploaded successfully!');
                        } else {
                            this.$toasted.success(successCount + ' Images uploaded successfully!');
                        }
                    }
                });
            },
            async selectColorScheme(event) {
                try {
                    if (['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame)) {
                        let colors;
                        switch (event.target.value) {
                            case 'primary_colors':
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'primary_colors',
                                };
                                colors = this.colorScheme.primary_colors;
                                break;
                            case 'secondary_colors':
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'secondary_colors',
                                };
                                colors = this.colorScheme.secondary_colors;
                                break;
                            case 'customized_colors':
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'customized_colors',
                                };
                                colors = this.colorScheme.customized_colors;
                                break;
                            default:
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'primary_colors',
                                };
                                colors = this.colorScheme.primary_colors;
                                break;
                        }

                        if (event.target.value !== 'customized_colors') {
                            this.colorScheme = {
                                ...this.colorScheme,
                                colors: [colors[2], colors[3]],
                                customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                            };
                        } else {
                            this.colorScheme = {
                                ...this.colorScheme,
                                colors: [colors[2], colors[3]],
                            };
                        }

                        this.changedColor = {
                            primaryColor: colors[0],
                            secondaryColor: colors[1],
                            buttoncolor: colors[2],
                            textcolor: colors[3],
                            selectedSchemeOption: event.target.value,
                        };
                    } else {
                        switch (event.target.value) {
                            case 'option1':
                                this.colorScheme = {
                                    selectedOption: 'option1',
                                    colors: [
                                        ['#000', '#FFFFFF'],
                                        ['#3858E9', '#FFFFFF'],
                                        ['#15AE80', '#FFFFFF'],
                                    ],
                                };
                                this.changedColor = {
                                    buttoncolor: '#000',
                                    textcolor: '#FFFFFF',
                                };
                                break;
                            case 'option2':
                                this.colorScheme = {
                                    selectedOption: 'option2',
                                    colors: [
                                        ['#2271B1', '#FFFFFF'],
                                        ['#9EA476', '#FFFFFF'],
                                        ['#DD823B', '#FFFFFF'],
                                    ],
                                };
                                this.changedColor = {
                                    buttoncolor: '#2271B1',
                                    textcolor: '#FFFFFF',
                                };
                                break;
                            case 'customized_color':
                                this.colorScheme = {
                                    selectedOption: 'customized_color',
                                    colors: [
                                        ['#efefef', '#efefef'],
                                        ['#efefef', '#efefef'],
                                        ['#efefef', '#efefef'],
                                    ],
                                };
                                this.changedColor = {
                                    buttoncolor: '#efefef',
                                    textcolor: '#efefef',
                                };
                                break;
                            default:
                                this.colorScheme = {
                                    selectedOption: 'default',
                                    colors: [
                                        ['#595BC4', '#FFFFFF'],
                                        ['#AC2734', '#FFFFFF'],
                                        ['#4CBC9A', '#FFFFFF'],
                                    ],
                                };
                                this.changedColor = {
                                    buttoncolor: '#595BC4',
                                    textcolor: '#FFFFFF',
                                };
                                break;
                        }

                        Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach((group) => {
                            if (group === 'GSI') {
                                Object.keys(this.pageData.page4.template[group]).forEach((gsi) => {
                                    this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                        group
                                    ][gsi].style.replace(
                                        /background-color:[^;]*/,
                                        `background-color:${this.changedColor.buttoncolor};`
                                    );
                                    this.pageData.page4.template[group][gsi].style = this.pageData.page4.template[
                                        group
                                    ][gsi].style.replace(
                                        /(?:^|;)\s*color\s*:[^;]*/,
                                        `color:${this.changedColor.textcolor}`
                                    );
                                    this.pageData.page4.template[group][gsi].color = this.changedColor.buttoncolor;
                                    this.pageData.page4.template[group][gsi].textColor = this.changedColor.textcolor;
                                });
                            }
                            if (group === 'LI' && this.current_template === 6) {
                                Object.keys(this.pageData.page4.template[group]).forEach((li) => {
                                    this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                        li
                                    ].style.replace(
                                        /background-color:[^;]*/,
                                        `background-color:${this.changedColor.buttoncolor};`
                                    );
                                    this.pageData.page4.template[group][li].style = this.pageData.page4.template[group][
                                        li
                                    ].style.replace(/(?:^|;)\s*color\s*:[^;]*/, `color:${this.changedColor.textcolor}`);
                                    this.pageData.page4.template[group][li].color = this.changedColor.buttoncolor;
                                    this.pageData.page4.template[group][li].textColor = this.changedColor.textcolor;
                                });
                            }
                        });
                    }

                    await this.updateColor();
                } catch (err) {
                    this.$toasted.error('Failed to Update Color');
                }
            },
            async selectCustomColorScheme(event, colorNo) {
                try {
                    let color = event.target.value;
                    let _colorScheme = this.colorScheme;

                    if (['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame)) {
                        this.changedColor = {
                            primaryColor: this.colorScheme.customized_colors[0],
                            secondaryColor: this.colorScheme.customized_colors[1],
                            buttoncolor: this.colorScheme.customized_colors[2],
                            textcolor: this.colorScheme.customized_colors[3],
                            selectedSchemeOption: this.colorScheme.selectedOption,
                        };

                        switch (colorNo) {
                            case 0:
                                this.changedColor = {
                                    ...this.changedColor,
                                    primaryColor: color,
                                };
                                break;
                            case 1:
                                this.changedColor = {
                                    ...this.changedColor,
                                    secondaryColor: color,
                                };
                                break;
                            case 2:
                                this.changedColor = {
                                    ...this.changedColor,
                                    buttoncolor: color,
                                };
                                break;
                            case 3:
                                this.changedColor = {
                                    ...this.changedColor,
                                    textcolor: color,
                                };
                                break;
                        }

                        this.colorScheme = {
                            ...this.colorScheme,
                            colors: [this.colorScheme.customized_colors[2], this.colorScheme.customized_colors[3]],
                        };
                    } else {
                        switch (colorNo) {
                            case 1:
                                _colorScheme.colors[0][0] = color;
                                this.changedColor.buttoncolor = color;
                                break;
                            case 2:
                                _colorScheme.colors[1][0] = color;
                                break;
                            case 3:
                                _colorScheme.colors[2][0] = color;
                                break;
                            case 4:
                                _colorScheme.colors[0][1] = color;
                                this.changedColor.textcolor = color;
                                break;
                            case 5:
                                _colorScheme.colors[1][1] = color;
                                break;
                            case 6:
                                _colorScheme.colors[2][1] = color;
                                break;
                        }

                        if (colorNo == 1 || colorNo == 4) {
                            Object.keys(this.pageData.page4 != null && this.pageData.page4.template).forEach(
                                (group) => {
                                    if (group === 'GSI') {
                                        Object.keys(this.pageData.page4.template[group]).forEach((gsi) => {
                                            this.pageData.page4.template[group][gsi].style =
                                                this.pageData.page4.template[group][gsi].style.replace(
                                                    /background-color:[^;]*/,
                                                    `background-color:${this.changedColor.buttoncolor};`
                                                );
                                            this.pageData.page4.template[group][gsi].style =
                                                this.pageData.page4.template[group][gsi].style.replace(
                                                    /(?:^|;)\s*color\s*:[^;]*/,
                                                    `color:${this.changedColor.textcolor}`
                                                );
                                            this.pageData.page4.template[group][gsi].color =
                                                this.changedColor.buttoncolor;
                                            this.pageData.page4.template[group][gsi].textColor =
                                                this.changedColor.textcolor;
                                        });
                                    }
                                    if (group === 'LI' && this.current_template === 6) {
                                        Object.keys(this.pageData.page4.template[group]).forEach((li) => {
                                            this.pageData.page4.template[group][li].style =
                                                this.pageData.page4.template[group][li].style.replace(
                                                    /background-color:[^;]*/,
                                                    `background-color:${this.changedColor.buttoncolor};`
                                                );
                                            this.pageData.page4.template[group][li].style =
                                                this.pageData.page4.template[group][li].style.replace(
                                                    /(?:^|;)\s*color\s*:[^;]*/,
                                                    `color:${this.changedColor.textcolor}`
                                                );
                                            this.pageData.page4.template[group][li].color =
                                                this.changedColor.buttoncolor;
                                            this.pageData.page4.template[group][li].textColor =
                                                this.changedColor.textcolor;
                                        });
                                    }
                                }
                            );

                            this.colorScheme = _colorScheme;
                        }
                    }

                    await this.updateColor();
                } catch (err) {
                    this.$toasted.error('Failed to Update Color');
                }
            },
            selectCompanyLogo(logo) {
                this.companyLogo = {
                    id: logo.id,
                    image: logo.image,
                    fileId: logo.file_id,
                    file_id: logo.file_id,
                };
                this.errors.logo = null;
                this.$emit('captureValues', { page3: { logo: this.companyLogo } });
            },
            selectQRCompanyLogo(logo) {
                if (logo.file_id === this.qrLogo?.file_id) return;
                this.qrLogo = {
                    id: logo.id,
                    image: logo.image,
                    fileId: logo.file_id,
                    file_id: logo.file_id,
                };
                if (this.showStaticQR) {
                    this.fetchQR();
                }
                this.errors.qrLogo = null;
                this.$emit('captureValues', { page3: { qrLogo: this.qrLogo } });
            },
            selectBackground(bg) {
                this.backgroundPhoto = {
                    file_id: bg.file_id,
                    id: bg.id,
                    image: bg.image,
                };
                this.errors.background = null;
                this.$emit('captureValues', { page3: { background: bg } });
            },
            selectWaitingAnimation(Gif) {
                this.waitingAnimationGif = {
                    file_id: Gif.file_id,
                    id: Gif.id,
                    image: Gif.image,
                };
                this.errors.waitingAnimation = null;
                this.$emit('captureValues', { page3: { waitingAnimation: Gif } });
            },
            selectDigitalSignageImage(ds) {
                if (ds.selected) {
                    this.selectedDigitalSignageImages = this.selectedDigitalSignageImages.filter(
                        (item) => item.file_id !== ds.file_id
                    );
                } else {
                    this.selectedDigitalSignageImages.push({
                        ...ds,
                        position: 'none',
                        message: '',
                        textColor: '',
                    });
                }

                this.digitalSignageImages.map((item) => {
                    if (item.file_id == ds.file_id) {
                        item.selected = !ds.selected;
                    }
                });

                this.errors.digitalSignage = null;

                this.$emit('captureValues', { page3: { digitalSignage: this.selectedDigitalSignageImages } });
            },
            async getCompanyLogos(s3_link = null) {
                try {
                    const endpoint1 = dashboardBuilder.getAssets('dashboard_builder_logo', this.companyDetails?.id);
                    const assetsResponse = await this.$api.get(endpoint1);

                    if (assetsResponse.data.success) {
                        let data1 = this.allImages.filter((item) => item.type == 'company_logo');
                        let data2 = assetsResponse.data.list.filter((item) => item.dashboard_used == this.dashboardId);

                        this.companyLogoImages = [...data2, ...data1];

                        if (s3_link) {
                            const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3_link);
                            this.companyLogo = {
                                id: selectedImage.id,
                                image: selectedImage.image,
                                fileId: selectedImage.file_id,
                                file_id: selectedImage.file_id,
                            };
                            this.$emit('captureValues', { page3: { logo: selectedImage } });
                        }

                        this.$emit('captureValues', { page3: { companyLogoImages: data2 } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch dashboard_builder_logo');
                } finally {
                    this.companyLogoLoading = false;
                }
            },
            async getBackgroundImages(s3Link = null) {
                try {
                    const endpoint1 = dashboardBuilder.getAssets(
                        'dashboard_builder_background_image',
                        this.companyDetails?.id
                    );
                    const assetsResponse = await this.$api.get(endpoint1);

                    if (assetsResponse.data.success) {
                        let data1 = this.allImages.filter((item) => item.type == 'company_background_image');
                        let data2 = assetsResponse.data.list.filter((item) => item.dashboard_used == this.dashboardId);

                        this.backgroundImages = [...data1, ...data2];

                        if (s3Link) {
                            const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3Link);
                            this.backgroundPhoto = {
                                file_id: selectedImage.file_id,
                                id: selectedImage.id,
                                image: selectedImage.image,
                            };
                            this.$emit('captureValues', { page3: { background: selectedImage } });
                        }

                        this.$emit('captureValues', { page3: { backgroundImages: data2 } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch dashboard_builder_background_image');
                } finally {
                    this.bgImageLoading = false;
                }
            },
            async getDigitalSignageImages(uploadedImages = []) {
                try {
                    const endpoint1 = dashboardBuilder.getAssets(
                        'dashboard_builder_digital_signage_image',
                        this.companyDetails?.id
                    );
                    const assetsResponse = await this.$api.get(endpoint1);

                    if (assetsResponse.data.success) {
                        let data1 = this.allImages?.filter((item) => item.type == 'company_digital_signage_image');
                        let data2 = assetsResponse?.data?.list.filter(
                            (item) => item.dashboard_used == this.dashboardId
                        );

                        this.digitalSignageImages = [...data1, ...data2];

                        uploadedImages.forEach((item) => {
                            let data = this.digitalSignageImages.find((obj) => obj.file_id == item.s3_link);
                            if (data) {
                                this.selectedDigitalSignageImages.push({
                                    ...data,
                                    position: 'none',
                                    message: '',
                                    textColor: '',
                                });
                            }
                        });

                        this.digitalSignageImages.map((obj1) => {
                            obj1.selected = this.selectedDigitalSignageImages.some(
                                (obj2) => obj1.file_id === obj2.file_id
                            );
                        });

                        this.errors.digitalSignage = null;

                        if (uploadedImages.length) {
                            this.$emit('captureValues', {
                                page3: { digitalSignage: this.selectedDigitalSignageImages },
                            });
                        }

                        this.$emit('captureValues', { page3: { digitalSignageImages: data2 } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch dashboard_builder_digital_signage_image');
                } finally {
                    this.digitalSignageLoading = false;
                }
            },
            async getWaitingAnimation(s3Link = null) {
                try {
                    const endpoint1 = dashboardBuilder.getAssets(
                        'dashboard_builder_waiting_animation',
                        this.companyDetails?.id
                    );
                    const assetsResponse = await this.$api.get(endpoint1);

                    if (assetsResponse.data.success) {
                        let data1 = this.allImages.filter((item) => item.type == 'waiting_animation');
                        let data2 = assetsResponse.data.list.filter((item) => item.dashboard_used == this.dashboardId);
                        this.waitingAnimationGifs = [...data1, ...data2];

                        if (s3Link) {
                            const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3Link);
                            this.waitingAnimationGif = {
                                file_id: selectedImage.file_id,
                                id: selectedImage.id,
                                image: selectedImage.image,
                            };
                            this.$emit('captureValues', { page3: { waitingAnimation: selectedImage } });
                        }
                        this.$emit('captureValues', { page3: { waitingAnimationList: data2 } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch dashboard_builder_waiting_animation');
                } finally {
                    this.waitingAnimationLoading = false;
                }
            },
            async getQRLogos(s3Link = null) {
                try {
                    const endpoint1 = dashboardBuilder.getAssets('dashboard_builder_qr_logo', this.companyDetails?.id);
                    const assetsResponse = await this.$api.get(endpoint1);

                    if (assetsResponse.data.success) {
                        let data1 = this.allImages.filter((item) => item.type == 'company_qr_logo');
                        let data2 = assetsResponse.data.list.filter((item) => item.dashboard_used == this.dashboardId);
                        this.qrLogoImages = [...data1, ...data2];

                        if (s3Link) {
                            const selectedImage = assetsResponse.data.list.find((item) => item.file_id === s3Link);
                            this.qrLogo = {
                                file_id: selectedImage.file_id,
                                id: selectedImage.id,
                                image: selectedImage.image,
                            };
                            this.$emit('captureValues', { page3: { qrLogo: selectedImage } });
                        }
                        this.$emit('captureValues', { page3: { qrLogoImages: data2 } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch dashboard_builder_qr_logo');
                } finally {
                    this.qrLogoLoading = false;
                }
            },
            openConfigureQR(val) {
                this.showConfigureQR = true;
                let data = this.selectedDigitalSignageImages.find((item) => item.id === val.id);
                if (data) {
                    this.screenSaverData = { ...data, selected: true };
                }
            },
            saveConfigureQR(val) {
                this.selectedDigitalSignageImages.map((item) => {
                    if (item.id === val.id) {
                        item.position = val.position;
                        item.message = val.message;
                        item.textColor = val.textColor;
                    }
                });

                this.showConfigureQR = false;
                this.screenSaverData = null;

                this.$emit('captureValues', {
                    page3: {
                        digitalSignage: this.selectedDigitalSignageImages,
                    },
                });
            },
            async removeSignageImage(item) {
                if (item.type === 'company_digital_signage_image') {
                    this.$toasted.error('Company images can not be deleted from dashboard');
                    return;
                }
                this.digitalSignageErr = null;
                const endpoint = dashboardBuilder.deleteAsset(this.dashboardId, item.id);
                const assetsResponse = await this.$api.delete(endpoint);

                this.digitalSignageImages = this.digitalSignageImages.filter((element) => element.id != item.id);
                this.selectedDigitalSignageImages = this.selectedDigitalSignageImages.filter(
                    (element) => element.id !== item.id
                );

                this.$toasted.success('Image deleted successfully !');

                this.$emit('captureValues', {
                    page3: {
                        digitalSignage: this.selectedDigitalSignageImages,
                        digitalSignageImages: this.digitalSignageImages,
                    },
                });
                return assetsResponse;
            },
            async removeBackground(item) {
                if (item.type === 'company_background_image') {
                    this.$toasted.error('Company images can not be deleted from dashboard');
                    return;
                }

                if (item.id == this.backgroundPhoto.id) {
                    this.$toasted.error('Selected background image can not be deleted from dashboard');
                    return;
                }

                const endpoint = dashboardBuilder.deleteAsset(this.dashboardId, item.id);
                const assetsResponse = await this.$api.delete(endpoint);

                this.backgroundImages = this.backgroundImages.filter((element) => element.id != item.id);
                this.$toasted.success('Image deleted successfully !');

                this.$emit('captureValues', { page3: { backgroundImages: this.backgroundImages } });
                return assetsResponse;
            },
            handleTimeText(event) {
                let val = Number(event.target.value);
                this.errors.timeText = null;
                if (event.target.value == '') {
                    this.timeTextErr = 'This field is Required !';
                }
                if (val <= 0) {
                    this.timeTextErr = 'Value should be positive Integer !';
                }
                if (val > 1800) {
                    this.timeTextErr = 'Maximum limit is 1800 seconds!';
                } else {
                    this.timeTextErr = '';
                    this.timeText = val;
                }
                this.$emit('captureValues', { page3: { timeText: this.timeText } });
            },
            handleTimeoutText(event) {
                let val = Number(event.target.value);
                this.errors.timeoutText = null;
                if (event.target.value == '') {
                    this.timeoutTextErr = 'This field is Required !';
                }
                if (val <= 0) {
                    this.timeoutTextErr = 'Value should be positive Integer !';
                }
                if (val > 1800) {
                    this.timeoutTextErr = 'Maximum limit is 1800 seconds!';
                } else {
                    this.timeoutTextErr = '';
                    this.timeoutText = val;
                }
                if (val > this.sensitiveScreenTimeout?.id) {
                    this.errors.sensitiveScreenTimeout = null;
                }
                this.$emit('captureValues', { page3: { timeoutText: this.timeoutText } });
            },
            handleBufferDuration(event) {
                let val = Number(event.target.value);
                this.errors.bufferDuration = null;
                if (event.target.value == '') {
                    this.bufferDurationErr = 'This field is Required !';
                }
                if (!Number.isInteger(val) || val <= 0) {
                    this.bufferDurationErr = 'Value should be a valid positive Integer !';
                }
                if (val > 86400) {
                    this.bufferDurationErr = 'Maximum limit is 86400 seconds!';
                } else {
                    this.bufferDurationErr = '';
                    this.bufferDuration = val;
                }

                this.$emit('captureValues', { page3: { bufferDuration: this.bufferDuration } });
            },
            async getCompanyColor() {
                if (['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame)) {
                    this.colorScheme = JSON.parse(this.companyDetails.kiosk_colors);
                } else if (this.applicationFrame === 'mobex-health-hub') {
                    this.colorScheme = {
                        selectedOption: 'default',
                        colors: [
                            ['#595BC4', '#FFFFFF'],
                            ['#AC2734', '#FFFFFF'],
                            ['#4CBC9A', '#FFFFFF'],
                        ],
                    };
                } else if (this.applicationFrame === 'star-kid' || this.applicationFrame === 'mobex-health-plus') {
                    this.colorScheme = {
                        selectedOption: 'default',
                        colors: ['#FFFFFF', '#5155C3'],
                    };
                }
                // else if (this.applicationFrame === 'mobex-health-plus-v1') {
                //     this.colorScheme = {
                //         topHeader: '#595BC4',
                //         hoverColor: '#4CBC9A',
                //         fontColor: '#000000',
                //         primaryColor: '#AC2734',
                //         secondaryColor: '333333',
                //         buttonColor: '#AC2734',
                //     };
                // }

                this.$emit('captureValues', {
                    page3: {
                        colorScheme: this.colorScheme,
                    },
                });
            },
            async getAllImages() {
                try {
                    const endpoint = companies.getSavedAssetList(this.companyDetails?.id);
                    const assetsResponse = await this.$api.get(endpoint);

                    if (assetsResponse.data.success) {
                        this.allImages = assetsResponse?.data?.list;
                        this.companyLogoImages = this.allImages?.filter((item) => item.type == 'company_logo');
                        this.qrLogoImages = this.allImages?.filter((item) => item.type == 'company_qr_logo');
                        this.waitingAnimationGifs = this.allImages?.filter((item) => item.type == 'waiting_animation');
                        this.backgroundImages = this.allImages?.filter(
                            (item) => item.type == 'company_background_image'
                        );
                        this.digitalSignageImages = this.allImages?.filter(
                            (item) => item.type == 'company_digital_signage_image'
                        );

                        this.$emit('captureValues', { page3: { digitalSignageImages: this.digitalSignageImages } });
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetched assets');
                }
            },
            handleScreenSaver(event) {
                this.showScreenSaver = event.target.checked;

                if (!event.target.checked) {
                    this.selectedDigitalSignageImages = [];
                    this.digitalSignageImages.map((item) => {
                        item.selected = false;
                    });
                    this.timeText = null;
                    this.timeoutText = null;
                }

                this.errors.digitalSignage = null;
                this.errors.sensitiveAlertTimeout = null;
                this.errors.timeText = null;
                this.errors.timeoutText = null;
                this.errors.sensitiveScreenTimeout = null;
                this.errors.sensitiveAlertTimeout = null;

                this.$emit('captureValues', {
                    page3: {
                        showScreenSaver: event.target.checked ? 1 : 0,
                        digitalSignage: this.selectedDigitalSignageImages,
                        digitalSignageImages: this.digitalSignageImages,
                        timeText: this.timeText,
                        timeoutText: this.timeoutText,
                    },
                });
            },
            handleSplashScreen(event) {
                this.showSplashScreen = event.target.checked;
                if (event.target.checked) {
                    this.showDisplayName = true;
                    this.splashScreenMessage = '';
                }
                this.$emit('captureValues', {
                    page3: {
                        showSplashScreen: event.target.checked,
                        showDisplayName: this.showDisplayName,
                        splashScreenMessage: this.splashScreenMessage,
                    },
                });
            },
            handleDisplayName(value) {
                this.showDisplayName = value;
                this.$emit('captureValues', { page3: { showDisplayName: value } });
            },
            handleSplashScreenMessage(value) {
                this.errors.splashScreenMessage = null;
                this.$emit('captureValues', { page3: { splashScreenMessage: value } });
            },
            async geturl(file_id) {
                try {
                    const endpoint = dashboardBuilder.getUrls();
                    const requestData = {
                        fileIds: [file_id],
                    };
                    let response = await this.$api.post(endpoint, requestData);
                    return response?.data?.data[0];
                } catch (err) {
                    console.error(err);
                    return false;
                }
            },
            async getCompanyDetails() {
                try {
                    const endpoint = companies.getCompanyDetails(this.$store.state.user?.company_id);
                    const response = await this.$api.get(endpoint);
                    if (response?.data?.info[0]) {
                        this.companyDetails = response?.data?.info[0];
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch company details');
                }
            },
            handleSensitiveScreenTimeout(event) {
                this.$emit('captureValues', { page3: { sensitiveScreenTimeout: event.id } });
                if (this.timeoutText && Number(this.timeoutText) < event.id) {
                    this.errors.sensitiveScreenTimeout = 'Value should be less than screen saver timeout !';
                    return;
                }
                if (this.sensitiveAlertTimeout.id >= event.id) {
                    this.errors.sensitiveScreenTimeout = 'Value should be greater than sensitive inactivity timeout !';
                    return;
                }
                this.errors.sensitiveAlertTimeout = null;
                this.errors.sensitiveScreenTimeout = null;
            },
            handleSensitiveAlertTimeout(event) {
                this.$emit('captureValues', { page3: { sensitiveAlertTimeout: event.id } });
                if (this.sensitiveAlertTimeout.id && Number(this.sensitiveAlertTimeout.id) < event.id) {
                    this.errors.sensitiveAlertTimeout = 'Value should be less than sensitive screen timeout !';
                    return;
                }
                if (this.sensitiveScreenTimeout.id <= event.id) {
                    this.errors.sensitiveAlertTimeout = 'Value should be less than sensitive screen timeout !';
                    return;
                }
                this.errors.sensitiveAlertTimeout = null;
                this.errors.sensitiveScreenTimeout = null;
            },
            handleCompanyLogoInQR(e) {
                this.showCompanyLogoInQR = e.target.checked;
                if (!this.showCompanyLogoInQR) {
                    this.qrLogo = {};
                }
                if (!this.showCompanyLogoInQR && this.showStaticQR) {
                    this.fetchQR();
                }
                this.$emit('captureValues', {
                    page3: { showCompanyLogoInQR: this.showCompanyLogoInQR === true ? 1 : 0, qrLogo: null },
                });
            },
            handleStaticQR(e) {
                this.showStaticQR = e.target.checked;
                if (e.target.checked) {
                    this.fetchQR();
                }
                this.$emit('captureValues', {
                    page3: { showStaticQR: this.showStaticQR === true ? 1 : 0 },
                });
            },
            async fetchQR() {
                this.qrFileName =
                    `company:${this.companyDetails?.id}_dashboard:${this.dashboardId}` +
                    (this.qrLogo?.file_id
                        ? `_withLogoFile:${this.qrLogo.file_id.split('/').pop().split('.')[0]}`
                        : '_withoutLogo');

                if (this.qrLogo?.image) {
                    await this.generateQRWithLogo();
                } else {
                    await this.generateQRWithoutLogo();
                }
            },
            async getQRWithLogoByName() {
                try {
                    this.loading = true;

                    const res = await this.$api.get(dashboardBuilder.getQRWithLogoByName(`${this.qrFileName}.png`));

                    if (res.data.success) {
                        this.qrImage = res.data.response.qrLink;
                        return true;
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    this.loading = false;
                }
                return false;
            },
            async generateQRWithLogo() {
                try {
                    this.loading = true;

                    this.qrFileName = `company:${this.companyDetails?.id}_dashboard:${this.dashboardId}_withLogoFile:${
                        this.qrLogo.file_id.split('/').pop().split('.')[0]
                    }`;

                    const body = {
                        qrFileName: this.qrFileName,
                        qrLogoUrl: this.qrLogo?.image,
                        company: this.companyDetails?.company_name,
                        dashboard: this.pageData?.page1?.dashboardName,
                        dashboardId: this.dashboardId,
                    };

                    const res = await this.$api.post(dashboardBuilder.generateQRWithLogo(), body);

                    if (res.data.success) {
                        this.qrImage = res.data.response.qrLink;
                    }
                } catch (err) {
                    const errMsg = err ?? 'Failed to fetch QR';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
            async generateQRWithoutLogo() {
                try {
                    this.loading = true;

                    const body = {
                        qrFileName: this.qrFileName,
                        company: this.companyDetails?.company_name,
                        dashboard: this.pageData?.page1?.dashboardName,
                        dashboardId: this.dashboardId,
                    };

                    const res = await this.$api.post(dashboardBuilder.generateQRWithoutLogo(), body);

                    if (res.data.success) {
                        this.qrImage = res.data.response.qrLink;
                    }
                } catch (err) {
                    const errMsg = err ?? 'Failed to fetch QR';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
            async downloadQRImage() {
                try {
                    const res = await this.$api.get(dashboardBuilder.getQRBuffer(`${this.qrFileName}.png`), {
                        responseType: 'blob',
                    });
                    // Create a blob from the response data
                    const blob = new Blob([res.data], { type: 'image/png' });
                    const url = window.URL.createObjectURL(blob);

                    // Create a link element to trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.qrFileName}.png`); // Ensure file extension is included

                    // Append to body, click and remove
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link); // Clean up the link

                    // Free up memory
                    window.URL.revokeObjectURL(url);
                } catch (err) {
                    this.$toasted.error('Failed to download QR');
                }
            },
            handleMHPColorTheme() {
                this.$emit('captureValues', {
                    page3: { colorScheme: this.colorScheme },
                });
            },
            async getAppColors() {
                try {
                    const endpoint = cardColors.getCardColor(this.$store.state.user?.company_id);
                    const response = await this.$api.get(endpoint);
                    if (response?.data?.message) {
                        const { background_color, background_text_color, canvas_color, canvas_text_color } =
                            response?.data?.message[0];

                        this.appColors = { background_color, background_text_color, canvas_color, canvas_text_color };
                    }
                    this.$emit('captureValues', {
                        page3: { appColors: this.appColors },
                    });
                } catch (err) {
                    this.$toasted.error('Failed to fetch Card Details');
                }
            },
            async updateColor() {
                try {
                    this.loading = true;
                    if (this.$route.query.status !== 'draft') {
                        const endpoint = memberProfile.updateColor(this.dashboardId);
                        const response = await this.$api.put(endpoint, this.changedColor);
                        if (response?.data?.success) {
                            await this.fetchDashboardData();
                            this.$emit('captureValues', { page3: { colorScheme: this.colorScheme } });
                        }
                    } else {
                        this.$emit('captureValues', {
                            page4: {
                                template: null,
                                templateData: null,
                            },
                        });
                    }
                    await this.deleteUnSaved();
                } catch (err) {
                    console.error(err);
                    this.$toasted.error('Failed to update color');
                } finally {
                    this.loading = false;
                }
            },
            async fetchDashboardData() {
                try {
                    const response = await this.$api.get(dashboardBuilder.fetchDashboardData(this.dashboardId));
                    if (response.data?.templateMeta?.data) {
                        const data = JSON.parse(response.data.templateMeta.data);
                        const { template, templateData } = data?.page4;
                        this.$emit('captureValues', {
                            page4: {
                                template,
                                templateData,
                            },
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            async deleteUnSaved() {
                try {
                    const endpoint = memberProfile.deleteUnSaved(this.dashboardId);
                    await this.$api.post(endpoint);
                    this.$emit('resetRssFeed');
                } catch (err) {
                    console.error(err);
                }
            },
        },
        async created() {
            window.scrollTo(0, 0);

            this.dashboardId = this.$route.query.id;

            await this.getCompanyDetails();
            await this.getAllImages();
            await this.getCompanyLogos();

            if (['mobex-health-hub', 'kiosk'].includes(this.applicationFrame)) {
                await this.getBackgroundImages();
            }

            if (this.applicationFrame == 'kiosk') {
                await this.getDigitalSignageImages();
                await this.getWaitingAnimation();
                await this.getQRLogos();
            }

            if (this.applicationFrame !== 'family-connect' && !this.pageData.page3?.colorScheme) {
                await this.getCompanyColor();
            }

            if (
                ['mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame) &&
                !this.pageData.page3?.appColors
            ) {
                await this.getAppColors();
            }

            if (this.applicationFrame == 'family-connect') {
                this.companyLogo = {
                    file_id: null,
                    image: '@/assets/px/fc_logo.png',
                };
                this.errors.logo = null;
                this.$emit('captureValues', { page3: { logo: this.companyLogo } });
            }

            if (!this.pageData.page3.bufferDuration) {
                this.bufferDuration = this.companyDetails.bufferDuration;
                this.$emit('captureValues', { page3: { bufferDuration: this.bufferDuration } });
            }

            if (this.pageData.page3) {
                let {
                    logo,
                    background,
                    waitingAnimation,
                    colorScheme,
                    appColors,
                    timeText,
                    timeoutText,
                    digitalSignage,
                    showScreenSaver,
                    sensitiveScreenTimeout,
                    sensitiveAlertTimeout,
                    bufferDuration,
                    showSplashScreen,
                    showDisplayName,
                    splashScreenMessage,
                    showCompanyLogoInQR,
                    qrLogo,
                    showStaticQR,
                } = this.pageData.page3;
                if (logo && this.applicationFrame != 'family-connect') {
                    if (logo.fileId) {
                        logo.file_id = logo.fileId;
                    }
                    let company_logo = this.companyLogoImages.find((el) => el.file_id == logo.file_id);
                    if (company_logo) {
                        this.companyLogo = {
                            id: company_logo.id,
                            image: company_logo.image,
                            fileId: company_logo.file_id,
                            file_id: company_logo.file_id,
                        };
                    } else {
                        company_logo = await this.geturl(logo?.file_id);
                        this.companyLogo = {
                            id: Number(company_logo.fileId.split('/')[1].split('.')[0]),
                            image: company_logo.image,
                            fileId: company_logo.fileId,
                            file_id: company_logo.fileId,
                        };
                        this.companyLogoImages.unshift(this.companyLogo);
                    }
                    this.$emit('captureValues', { page3: { logo: this.companyLogo } });
                }
                if (colorScheme) {
                    this.colorScheme = colorScheme;
                }
                if (appColors) {
                    this.appColors = appColors;
                }
                if (background) {
                    if (background.fileId) {
                        background.file_id = background.fileId;
                    }
                    let company_background = this.backgroundImages.find((el) => el.file_id == background.file_id);
                    if (company_background) {
                        this.backgroundPhoto = {
                            id: company_background.id,
                            image: company_background.image,
                            file_id: company_background.file_id,
                        };
                    } else {
                        company_background = await this.geturl(background?.file_id);
                        this.backgroundPhoto = {
                            id: Number(company_background.fileId.split('/')[1].split('.')[0]),
                            image: company_background.image,
                            file_id: company_background.fileId,
                        };
                        this.backgroundImages.unshift(this.backgroundPhoto);
                    }
                    this.$emit('captureValues', { page3: { background: this.backgroundPhoto } });
                }
                if (this.applicationFrame === 'kiosk') {
                    if (waitingAnimation) {
                        let waiting_animation = this.waitingAnimationGifs.find(
                            (el) => el.file_id == waitingAnimation.file_id
                        );
                        if (waiting_animation) {
                            this.waitingAnimationGif = {
                                id: waiting_animation.id,
                                image: waiting_animation.image,
                                file_id: waiting_animation.file_id,
                            };
                        }
                        this.$emit('captureValues', { page3: { waitingAnimation: this.waitingAnimationGif } });
                    }
                    if (digitalSignage) {
                        this.digitalSignageImages.forEach((item1) => {
                            let data = digitalSignage.find((item2) => item1.id === item2.id);
                            if (data) {
                                data.position = data.position ?? 'none';
                                data.message = data.message ?? '';
                                data.textColor = data.textColor ?? '';
                                this.selectedDigitalSignageImages.push(data);
                            }
                        });

                        this.digitalSignageImages.map((obj1) => {
                            obj1.selected = this.selectedDigitalSignageImages.some(
                                (obj2) => obj1.file_id === obj2.file_id
                            );
                        });

                        this.$emit('captureValues', {
                            page3: {
                                digitalSignage: this.selectedDigitalSignageImages,
                                digitalSignageImages: this.digitalSignageImages,
                            },
                        });
                    }
                    if (qrLogo) {
                        let company_qr_logo = this.qrLogoImages.find((el) => el.file_id == qrLogo.file_id);
                        if (company_qr_logo) {
                            this.qrLogo = {
                                id: company_qr_logo.id,
                                image: company_qr_logo.image,
                                file_id: company_qr_logo.file_id,
                            };
                        } else {
                            company_qr_logo = await this.geturl(qrLogo?.file_id);
                            this.qrLogo = {
                                id: Number(company_qr_logo.fileId.split('/')[1].split('.')[0]),
                                image: company_qr_logo.image,
                                file_id: company_qr_logo.fileId,
                            };
                            this.qrLogoImages.unshift(this.qrLogo);
                        }
                        this.$emit('captureValues', { page3: { qrLogo: this.qrLogo } });
                    }
                    if (showCompanyLogoInQR) {
                        this.showCompanyLogoInQR = showCompanyLogoInQR;
                    }

                    this.enableQR = this.pageData?.page2?.enableQR === 1 ? true : false;
                    this.showCompanyLogoInQR = this.enableQR && Object.keys(this.qrLogo).length > 0;
                    this.$emit('captureValues', {
                        page3: {
                            showCompanyLogoInQR: this.showCompanyLogoInQR ? 1 : 0,
                            qrLogo: this.showCompanyLogoInQR ? undefined : null,
                        },
                    });

                    if (showStaticQR) {
                        this.showStaticQR = true;
                        await this.fetchQR();
                    }
                    if (timeText) {
                        this.timeText = timeText;
                    }
                    if (timeoutText) {
                        this.timeoutText = timeoutText;
                    }
                    if (showSplashScreen != undefined) {
                        this.showSplashScreen = showSplashScreen;
                    }
                    if (showDisplayName != undefined) {
                        this.showDisplayName = showDisplayName;
                    }
                    if (splashScreenMessage) {
                        this.splashScreenMessage = splashScreenMessage;
                    }
                    if (showScreenSaver != undefined) {
                        this.showScreenSaver = showScreenSaver;
                    }
                }
                if (bufferDuration) {
                    this.bufferDuration = bufferDuration;
                }
                if (this.showSensitiveScreenTimeout) {
                    if (sensitiveScreenTimeout) {
                        this.sensitiveScreenTimeout = this.sensitiveScreenTimeoutList.find(
                            (item) => item.id == sensitiveScreenTimeout
                        );
                    }
                    if (sensitiveAlertTimeout) {
                        this.sensitiveAlertTimeout = this.sensitiveAlertTimeoutList.find(
                            (item) => item.id == sensitiveAlertTimeout
                        );
                    }
                    if (this.sensitiveScreenTimeout == '') {
                        this.sensitiveScreenTimeout = this.sensitiveScreenTimeoutList.find(
                            (item) => item.id == this.companyDetails.sensitiveScreenTimeout
                        );
                    }
                    if (this.sensitiveAlertTimeout == '') {
                        this.sensitiveAlertTimeout = this.sensitiveAlertTimeoutList.find(
                            (item) => item.id == this.companyDetails.sensitiveAlertTimeout
                        );
                    }

                    this.$emit('captureValues', {
                        page3: {
                            sensitiveScreenTimeout: this.sensitiveScreenTimeout?.id,
                            sensitiveAlertTimeout: this.sensitiveAlertTimeout?.id,
                        },
                    });
                }
            }

            this.loading = false;
        },
    };
</script>

<style scoped>
    .scenario-item {
        color: gray;
        font-size: 20px;
        font-weight: 600;
    }

    .scenario-text {
        font-size: 13px;
    }

    .color-box {
        width: 45%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);
    }

    .upload-btn {
        border-radius: 6px;
        background: #5155c3;
        color: #fff;
        cursor: pointer;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .scroll-Wrapper {
        width: 32vw;
        min-height: 100px;
        margin-bottom: 5%;
    }

    .scroll-container {
        overflow: auto;
        white-space: nowrap;
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
    }

    .scroll-container::-webkit-scrollbar {
        height: 5px;
    }

    /* Track */
    .scroll-container::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    .scroll-container::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    .scroll-container::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }

    .color {
        accent-color: var(--base-theme-tab-color);
    }

    .upload-section {
        margin: 0 15% 0 0;
        border-radius: 10px;
        border: 2px solid var(--grey, #7d7d7d);
        background: #fff;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }

    .upload-btn {
        border-radius: 6px;
        background: #5155c3;
        color: #fff;
        font-family: 'Roboto';
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .active-bg {
        width: 95px !important;
        height: 95px !important;
        border: 2px solid lime;
        padding: 2px;
    }

    .action-buttons {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        top: 0;
        background: rgb(89, 90, 194, 0.85);
        border-radius: 20px;
    }

    .overlay {
        width: 200px;
        height: 200px;
        border-radius: 8px;
        padding: 0;
        background: rgb(235 219 219 / 75%);
        align-items: center;
        position: absolute;
        display: flex;
        justify-content: center;
    }

    .default-color {
        height: 1.93rem;
        background-color: #efefef;
        border: 2px solid black;
    }
</style>

<style>
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        opacity: 1;
    }
</style>
