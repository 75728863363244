<template>
    <div class="bg-white rounded-5">
        <ProgressBar :current_step="current_step" />
        <div class="px-5 mx-4">
            <div class="d-flex justify-content-start">
                <SelectCompanyApplication
                    v-if="current_step === 1 && dataFetched == true"
                    @captureValues="captureValues"
                    :errors="page1Errors"
                    :pageData="pageData"
                    :healthCareProviderData="healthCareProvider"
                    :applicationFrame="applicationFrame"
                    :templateType="templateType"
                    :dashboardName="dashboardName"
                />
                <SelectModules
                    v-if="current_step === 2"
                    @captureValues="captureValues"
                    :errors="page2Errors"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :current_template="current_template"
                    :dashboardId="dashboardId"
                />
                <CustomisedThemeDesign
                    v-if="current_step === 3"
                    @captureValues="captureValues"
                    @resetRssFeed="resetRssFeed"
                    :errors="page3Errors"
                    :pageData="pageData"
                    :applicationFrame="applicationFrame"
                    :selected_language="selected_language"
                    :current_template="current_template"
                />
                <BuildDashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 1"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_FC_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 4"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_Call_button_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 6"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_MHH_3_Call_button_Dashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-hub' && current_template === 8"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildKioskDashboard
                    v-if="current_step === 4 && this.applicationFrame == 'kiosk'"
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureEducationFeed="captureEducationFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildFamilyConnectDashboard
                    v-if="current_step === 4 && this.applicationFrame == 'family-connect'"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildStarKidDashboard
                    v-if="current_step === 4 && applicationFrame === 'star-kid'"
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <BuildMobexHealthPlusDashboard
                    v-if="current_step === 4 && applicationFrame === 'mobex-health-plus-v1'"
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_Health_Home_Dashboard
                    v-if="
                        current_step === 4 &&
                        ((applicationFrame === 'mobex-health-home' && current_template === 10) ||
                            (applicationFrame === 'mobex-health-plus' && current_template === 12))
                    "
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
                <Build_Health_Home2_Dashboard
                    v-if="
                        current_step === 4 &&
                        ((applicationFrame === 'mobex-health-home' && current_template === 11) ||
                            (applicationFrame === 'mobex-health-plus' && current_template === 13))
                    "
                    :rssFeedsDetails="rssFeedsDetails"
                    @captureRssFeed="captureRssFeed"
                    @captureValues="captureValues"
                    :pageData="pageData"
                    @selection-changed="getCurrentTemplate"
                    :current_template="current_template"
                    :applicationFrame="applicationFrame"
                    :dashboardId="dashboardId"
                />
            </div>
        </div>

        <div class="mx-5">
            <div class="py-5 d-flex justify-content-end">
                <button class="other-btn" @click="onClickBack" v-if="current_step > 1">Back to Previous</button>
                <button
                    v-if="[2, 3].includes(current_step) && pageData.status != 'completed'"
                    class="other-btn"
                    @click="updateTemplate('draft')"
                >
                    Save as Draft
                </button>
                <button v-if="current_step == 4" class="other-btn" @click="openLauncherModal = true">Preview</button>
                <button v-if="current_step < max_step" class="save-btn" @click="onClickNext">Next</button>
                <button v-if="current_step == max_step" class="save-btn" @click="updateTemplate('completed')">
                    Save
                </button>
            </div>
        </div>

        <div v-if="openLauncherModal" class="previewdashboard">
            <PreviewModal
                v-if="this.applicationFrame == 'mobex-health-hub' && current_template == 1"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalMHH2
                v-if="this.applicationFrame == 'mobex-health-hub' && current_template == 4"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalMHH_CallButton
                v-if="this.applicationFrame == 'mobex-health-hub' && current_template == 6"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalMHH_3CallButton
                v-if="this.applicationFrame == 'mobex-health-hub' && current_template == 8"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalFC
                v-if="this.applicationFrame == 'family-connect'"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>

        <div v-if="openLauncherModal" class="previewdashboardkiosk">
            <PreviewModalKiosk
                v-if="this.applicationFrame == 'kiosk'"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalStarKid
                v-if="applicationFrame === 'star-kid'"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalMobexHealthPlus
                v-if="applicationFrame === 'mobex-health-plus-v1'"
                :open="openLauncherModal"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalHealthHome
                v-if="
                    (applicationFrame === 'mobex-health-home' && current_template === 10) ||
                    (applicationFrame === 'mobex-health-plus' && current_template === 12)
                "
                :open="openLauncherModal"
                :rssFeedsDetails="rssFeedsDetails"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />

            <PreviewModalHealthHome2
                v-if="
                    (applicationFrame === 'mobex-health-home' && current_template === 11) ||
                    (applicationFrame === 'mobex-health-plus' && current_template === 13)
                "
                :open="openLauncherModal"
                :rssFeedsDetails="rssFeedsDetails"
                @close="close"
                :pageData="pageData"
                :applicationFrame="applicationFrame"
            />
        </div>

        <Loading v-if="loading" />
    </div>
</template>
<script>
    import ProgressBar from './progress_bar.vue';
    import SelectCompanyApplication from './select_company_application.vue';
    import SelectModules from './select_modules.vue';
    import CustomisedThemeDesign from './customised_theme_design.vue';
    import BuildDashboard from './build_dashboard.vue';
    import Build_MHH_FC_Dashboard from './build_Mhh_fc_dashboard.vue';
    import Build_MHH_Call_button_Dashboard from './build_Mhh_call_button_dashboard.vue';
    import Build_MHH_3_Call_button_Dashboard from './build_mhh_3_call_button_dashboard.vue';
    import Build_Health_Home_Dashboard from './build_health_home_dashboard.vue';
    import Build_Health_Home2_Dashboard from './build_health_home2_dashboard.vue';
    import BuildKioskDashboard from './build_kiosk_dashboard.vue';
    import BuildStarKidDashboard from './build_starkid_dashboard.vue';
    import BuildMobexHealthPlusDashboard from './build_mobex_health_plus.vue';
    import BuildFamilyConnectDashboard from './build_familyconnect_dashboard.vue';
    import PreviewModal from './preview_modal.vue';
    import PreviewModalMHH2 from './preview_modal_mhh2.vue';
    import PreviewModalMHH_CallButton from './preview_modal_mhh_call_button.vue';
    import PreviewModalMHH_3CallButton from './preview_modal_mhh_3_call_button.vue';
    import PreviewModalKiosk from './preview_modal_kiosk.vue';
    import PreviewModalStarKid from './preview_modal_starkid.vue';
    import PreviewModalHealthHome from './preview_health_home_modal.vue';
    import PreviewModalHealthHome2 from './preview_health_home2_modal.vue';
    import PreviewModalFC from './preview_modal_fc.vue';
    import PreviewModalMobexHealthPlus from './preview_modal_mobex_health_plus.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { dashboardBuilder, clients, memberProfile, starkidsDashboard } from '@/util/apiRequests';
    import { dashboardBuildNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';

    const initialPage1Errors = {
        healthCareProvider: null,
        applicationFrame: null,
        templateType: null,
        dashboardName: null,
    };

    const initialPage2Errors = {
        modules: null,
    };

    const initialPage3Errors = {
        logo: null,
        qrLogo: null,
        color: null,
        background: null,
        digitalSignage: null,
        timeText: null,
        timeoutText: null,
        bufferDuration: null,
        showScreenSaver: null,
        sensitiveScreenTimeout: null,
        sensitiveAlertTimeout: null,
        splashScreenMessage: null,
    };

    export default {
        name: 'DashboardBuilder',
        components: {
            ProgressBar,
            SelectCompanyApplication,
            SelectModules,
            CustomisedThemeDesign,
            BuildDashboard,
            Build_MHH_FC_Dashboard,
            Build_MHH_Call_button_Dashboard,
            Build_MHH_3_Call_button_Dashboard,
            Build_Health_Home_Dashboard,
            Build_Health_Home2_Dashboard,
            BuildKioskDashboard,
            BuildStarKidDashboard,
            BuildMobexHealthPlusDashboard,
            PreviewModal,
            PreviewModalMHH2,
            PreviewModalMHH_CallButton,
            PreviewModalMHH_3CallButton,
            PreviewModalKiosk,
            PreviewModalStarKid,
            PreviewModalMobexHealthPlus,
            PreviewModalHealthHome,
            PreviewModalHealthHome2,
            BuildFamilyConnectDashboard,
            PreviewModalFC,
            Loading,
        },
        data() {
            return {
                current_step: 1,
                current_template: 1,
                max_step: 4,
                pageData: {
                    page1: null,
                    page2: null,
                    page3: null,
                    page4: null,
                    status: 'draft',
                },
                openLauncherModal: false,
                healthCareProvider: null,
                applicationFrame: null,
                educationFeedDetails: null,
                templateType: null,
                clientEmails: null,
                dashboardName: null,
                page1Errors: { ...initialPage1Errors },
                page2Errors: { ...initialPage2Errors },
                page3Errors: { ...initialPage3Errors },
                dashboardId: null,
                widgetsList: [],
                dataFetched: false,
                loading: true,
                rssFeedsDetails: {},
                previousRssFeedsDetails: {},
                selected_language: 'English',
                companyLogoImages: [],
                backgroundImages: [],
                digitalSignageImages: [],
                waitingAnimationList: [],
                qrLogoImages: [],
                isNextButtonProcessing: false,
            };
        },
        computed: {
            progress: function () {
                let _class = '';
                if (this.current_step === 1) {
                    _class = '';
                } else if (this.current_step === 2) {
                    _class = 'w-33';
                } else if (this.current_step === 3) {
                    _class = 'w-67';
                } else if (this.current_step === 4) {
                    _class = 'w-100';
                }
                return _class;
            },
        },
        methods: {
            async fetchDashboardData() {
                try {
                    const templateResponse = await this.$api.get(dashboardBuilder.fetchDashboardData(this.dashboardId));
                    const templateMeta = templateResponse.data?.templateMeta || {};
                    const data = templateMeta?.data ? JSON.parse(templateMeta.data) : {};

                    if (templateMeta?.status !== 'draft') {
                        this.updateDashboardUpdateState();
                        this.$router.replace({
                            name: 'DashboardBuilder',
                            query: { id: this.dashboardId, status: templateMeta?.status },
                        });
                    }

                    this.pageData.status = templateMeta?.status || '';
                    this.healthCareProvider = templateMeta?.health_care_provider
                        ? JSON.parse(templateMeta.health_care_provider)
                        : null;
                    this.templateType = templateMeta?.selected_template
                        ? JSON.parse(templateMeta.selected_template)
                        : null;
                    this.dashboardName = templateMeta?.dashboardName || '';
                    this.selected_language = templateMeta?.selected_language || '';
                    this.applicationFrame = templateMeta?.application || null;
                    this.current_template = +this.templateType?.id || null;

                    this.pageData.page1 = {
                        healthCareProvider: this.healthCareProvider,
                        applicationFrame: this.applicationFrame,
                        templateType: this.templateType,
                        dashboardName: this.dashboardName,
                        current_template: this.current_template,
                    };

                    if (data?.page2) {
                        this.pageData.page2 = {
                            coreFunctions: data.page2?.coreFunctions,
                            thirdPartyFunctions: data.page2?.thirdPartyFunctions,
                            genericFunctions: data.page2?.genericFunctions,
                            enableQR: templateMeta?.enableQR ?? 0,
                            showQR: templateMeta?.showQR ?? 0,
                            isMaster: templateMeta?.isMaster ?? 0,
                            bidirectional_messaging: templateMeta?.bidirectional_messaging ?? 0,
                            bidirectionalDisclaimer: data.page2?.bidirectionalDisclaimer ?? '',
                            tags: templateMeta?.tags ? JSON.parse(templateMeta.tags) : [],
                        };
                    }

                    if (data?.page3) {
                        this.pageData.page3 = {
                            logo: data.page3?.logo,
                            qrLogo: data.page3?.qrLogo,
                            colorScheme: data.page3?.colorScheme,
                            appColors: data.page3?.appColors,
                            waitingAnimation: data.page3?.waitingAnimation,
                            background: data.page3?.background,
                            digitalSignage: data.page3?.digitalSignage,
                            timeText: data.page3?.timeText,
                            timeoutText: data.page3?.timeoutText,
                            showScreenSaver: data.page3?.showScreenSaver ?? 0,
                            showCompanyLogoInQR: data.page3?.showCompanyLogoInQR ?? 0,
                            sensitiveScreenTimeout: templateMeta?.sensitiveScreenTimeout ?? 30,
                            sensitiveAlertTimeout: templateMeta?.sensitiveAlertTimeout ?? 15,
                            bufferDuration: templateMeta?.bufferDuration,
                            showSplashScreen: templateMeta?.showSplashScreen ?? 0,
                            showDisplayName: data.page3?.showDisplayName ?? 0,
                            splashScreenMessage: data.page3?.splashScreenMessage ?? '',
                            showStaticQR: data.page3?.showStaticQR ?? 0,
                        };
                    }

                    if (data?.page4) {
                        this.pageData.page4 = {
                            template: data.page4?.template,
                            templateData: data.page4?.templateData,
                            templateStyles: data.page4?.templateStyles,
                            sectionTitle: data.page4?.sectionTitle,
                            upperSectionTitle: data.page4?.upperSectionTitle,
                            lowerSectionTitle: data.page4?.lowerSectionTitle,
                        };
                    }

                    const rssInfo = templateMeta?.rssinfo ? JSON.parse(templateMeta.rssinfo) : {};
                    this.rssFeedsDetails = rssInfo;
                    this.previousRssFeedsDetails = rssInfo;

                    return true;
                } catch (error) {
                    this.$toasted.error('Error fetching dashboard data:', error);
                    throw new Error(error);
                }
            },
            async onClickNext() {
                if (this.isNextButtonProcessing) {
                    return;
                }

                try {
                    this.isNextButtonProcessing = true;

                    if (this.current_step === 1) {
                        if (!this.healthCareProvider) {
                            this.page1Errors.healthCareProvider = 'Please select health care provider !';
                            return;
                        } else if (!this.applicationFrame) {
                            this.page1Errors.healthCareProvider = null;
                            this.page1Errors.applicationFrame = 'Please select deliver application !';
                            return;
                        } else if (!this.templateType) {
                            this.page1Errors.templateType = null;
                            this.page1Errors.templateType = 'Please select a dashboard !';
                            return;
                        } else if (!this.dashboardName) {
                            this.page1Errors.dashboardName = null;
                            this.page1Errors.dashboardName = 'Please enter dashboard Name';
                            return;
                        }
                        const endpoint = dashboardBuilder.checkDashboardName();
                        const res = await this.$api.post(endpoint, {
                            dashboardName: this.dashboardName,
                            id: this.dashboardId,
                        });
                        if (!res.data.success) {
                            this.page1Errors.dashboardName = null;
                            this.page1Errors.dashboardName = res.data.error;
                            return;
                        }
                        if (!this.$route?.query?.id) {
                            const response = await this.createTemplate('draft');
                            if (!response) {
                                return;
                            }
                        }
                    } else if (this.current_step === 2) {
                        if (
                            !this.pageData.page2 ||
                            (this.pageData.page2.coreFunctions?.length === 0 &&
                                this.pageData.page2.genericFunctions?.length === 0)
                        ) {
                            this.page2Errors.modules = 'Please select at least one module !';
                            return;
                        }
                    } else if (this.current_step === 3) {
                        let err = 0;

                        const {
                            logo,
                            background,
                            waitingAnimation,
                            showCompanyLogoInQR,
                            showScreenSaver,
                            digitalSignage,
                            timeText,
                            timeoutText,
                            sensitiveScreenTimeout,
                            sensitiveAlertTimeout,
                            bufferDuration,
                            qrLogo,
                        } = this.pageData.page3;

                        if (!logo) {
                            this.page3Errors.logo = 'Please select/upload company logo !';
                            err++;
                        }
                        if (
                            !background &&
                            (['kiosk', 'mobex-health-plus-v1'].includes(this.applicationFrame) ||
                                (this.applicationFrame === 'mobex-health-hub' && this.current_template == 1))
                        ) {
                            this.page3Errors.background = 'Please select/upload company Background !';
                            err++;
                        }
                        if (!waitingAnimation && this.applicationFrame === 'kiosk') {
                            this.page3Errors.waitingAnimation = 'Please select/upload Waiting Animation !';
                            err++;
                        }
                        if (showCompanyLogoInQR && !qrLogo) {
                            this.page3Errors.qrLogo = 'Please select/upload company logo for QR !';
                            err++;
                        }
                        if (this.applicationFrame === 'kiosk') {
                            if (showScreenSaver) {
                                if (!digitalSignage?.length) {
                                    this.page3Errors.digitalSignage = 'Please select at least one image !';
                                    err++;
                                }
                                if (!timeText || !Number.isInteger(timeText) || timeText <= 0 || timeText > 1800) {
                                    this.page3Errors.timeText = !timeText
                                        ? 'Please enter valid time !'
                                        : !Number.isInteger(Number(timeText)) || timeText <= 0
                                        ? 'Value should be a valid positive integer !'
                                        : 'Maximum limit is 1800 seconds!';
                                    err++;
                                }
                                if (
                                    !timeoutText ||
                                    !Number.isInteger(timeoutText) ||
                                    timeoutText <= 0 ||
                                    timeoutText > 1800
                                ) {
                                    this.page3Errors.timeoutText = !timeoutText
                                        ? 'Please enter valid timeout !'
                                        : !Number.isInteger(Number(timeoutText)) || timeoutText <= 0
                                        ? 'Value should be a valid positive integer !'
                                        : 'Maximum limit is 1800 seconds!';
                                    err++;
                                }
                                if (timeoutText && timeoutText < Number(sensitiveScreenTimeout)) {
                                    this.page3Errors.sensitiveScreenTimeout =
                                        'Value should be less than screen saver timeout !';
                                    err++;
                                }
                                if (sensitiveScreenTimeout <= sensitiveAlertTimeout) {
                                    this.page3Errors.sensitiveScreenTimeout =
                                        'Value should be greater than sensitive inactivity timeout !';
                                    this.page3Errors.sensitiveAlertTimeout =
                                        'Value should be less than sensitive screen timeout !';
                                    err++;
                                }
                            }
                        }
                        if (
                            !bufferDuration ||
                            !Number.isInteger(bufferDuration) ||
                            bufferDuration <= 0 ||
                            bufferDuration > 86400
                        ) {
                            this.page3Errors.bufferDuration = !bufferDuration
                                ? 'Please enter valid duration !'
                                : !Number.isInteger(Number(bufferDuration)) || bufferDuration <= 0
                                ? 'Value should be a valid positive integer !'
                                : 'Maximum limit is 86400 seconds!';
                            err++;
                        }
                        if (err) return;
                    }

                    this.resetPageError();

                    // Move to the next step
                    if (this.current_step < this.max_step) {
                        this.current_step++;
                    }
                } catch (error) {
                    console.error('Error in onClickNext:', error);
                } finally {
                    this.isNextButtonProcessing = false; // Unlock the function
                }
            },
            onClickBack() {
                this.resetPageError();
                this.current_step > 1 ? this.current_step-- : this.current_step;
            },
            resetPageError() {
                this.page1Errors = { ...initialPage1Errors };
                this.page2Errors = { ...initialPage2Errors };
                this.page3Errors = { ...initialPage3Errors };
            },
            close() {
                this.openLauncherModal = false;
            },
            getStatusClass(section) {
                let _class = '';
                if (section === this.current_step) {
                    _class = 'current';
                } else if (section < this.current_step) {
                    _class = 'completed';
                } else if (section > this.current_step) {
                    _class = 'upcoming';
                }
                return _class;
            },
            getCurrentTemplate(option) {
                this.current_template = option;
            },
            async getDashboardRelatedClientsEmail() {
                try {
                    const endpoint = clients.getClientsEmail(this.dashboardId);
                    const clientEmailsResponse = await this.$api.get(endpoint);

                    const {
                        data: { emails, message },
                    } = clientEmailsResponse;
                    if (emails.length != 0) {
                        this.clientEmails = emails;
                    } else {
                        throw new Error(message);
                    }

                    return true;
                } catch (err) {
                    this.$toasted.success(`This dashboard is not assigned to any member !`);
                }
            },
            async sendDashboardBuildNotification() {
                if (!this.clientEmails) {
                    await this.getDashboardRelatedClientsEmail();
                }
                if (this.clientEmails != null && this.clientEmails.length) {
                    try {
                        await dashboardBuildNotification(this.clientEmails, this.applicationFrame);
                    } catch (err) {
                        return this.$toasted.error('Failed to send notifications to members');
                    }

                    return true;
                } else {
                    return false;
                }
            },
            async getDashboardUpdatingStatus() {
                try {
                    const endpoint = dashboardBuilder.getDashboardUpdatingStatus(this.dashboardId);
                    const res = await this.$api.get(endpoint);
                    if (!res?.data?.data?.in_update_state) {
                        await this.fetchDashboardData();
                        await this.deleteUnSaved();
                    } else {
                        this.$router.push({ name: 'DashboardBuilderList' });
                        this.$toasted.error(
                            'The Dashboard is currently being updated by another user, please try again later'
                        );
                    }
                } catch (err) {
                    return this.$toasted.error('Failed to get dashboard update state');
                }
            },
            async updateDashboardUpdateState() {
                try {
                    const endpoint = dashboardBuilder.updateDashboardUpdateState(this.dashboardId);
                    await this.$api.put(endpoint);
                } catch (err) {
                    return this.$toasted.error('Failed to set dashboard update state');
                }
            },
            async captureValues(event) {
                const { page1, page2, page3, page4 } = event;
                if (page1) {
                    const { healthCareProvider, applicationFrame, templateType, dashboardName } = page1;
                    this.healthCareProvider = healthCareProvider ? healthCareProvider : this.healthCareProvider;
                    this.applicationFrame = applicationFrame ? applicationFrame : this.applicationFrame;
                    this.templateType = templateType ? templateType : this.templateType;
                    this.dashboardName = dashboardName ? dashboardName : this.dashboardName;
                    this.current_template = +this.templateType?.id;
                    this.pageData.page1 = {
                        healthCareProvider: this.healthCareProvider,
                        applicationFrame: this.applicationFrame,
                        templateType: this.templateType,
                        dashboardName: this.dashboardName,
                        current_template: +this.templateType?.id,
                    };
                }
                if (page2) {
                    const {
                        coreFunctions,
                        thirdPartyFunctions,
                        genericFunctions,
                        enableQR,
                        showQR,
                        isMaster,
                        bidirectional_messaging,
                        bidirectionalDisclaimer,
                        tags,
                        signupFieldList,
                        introSlideList,
                        deletedSlideIds,
                    } = page2;
                    this.pageData.page2 = {
                        coreFunctions: coreFunctions ?? this.pageData.page2?.coreFunctions,
                        thirdPartyFunctions: thirdPartyFunctions ?? this.pageData.page2?.thirdPartyFunctions,
                        genericFunctions: genericFunctions ?? this.pageData.page2?.genericFunctions,
                        tags: tags ?? this.pageData.page2?.tags,
                        signupFieldList: signupFieldList ?? this.pageData.page2?.signupFieldList,
                        introSlideList: introSlideList ?? this.pageData.page2?.introSlideList,
                        deletedSlideIds: deletedSlideIds ?? this.pageData.page2?.deletedSlideIds,
                        enableQR: enableQR ?? this.pageData.page2?.enableQR,
                        showQR: showQR ?? this.pageData.page2?.showQR,
                        isMaster: isMaster ?? this.pageData.page2?.isMaster ?? 0,
                        bidirectional_messaging:
                            bidirectional_messaging !== undefined
                                ? bidirectional_messaging
                                : this.pageData?.page2?.bidirectional_messaging,
                        bidirectionalDisclaimer:
                            bidirectionalDisclaimer ?? this.pageData?.page2?.bidirectionalDisclaimer,
                    };
                }
                if (page3) {
                    const {
                        logo,
                        qrLogo,
                        colorScheme,
                        appColors,
                        background,
                        waitingAnimation,
                        waitingAnimationList,
                        qrLogoImages,
                        companyLogoImages,
                        backgroundImages,
                        digitalSignage,
                        digitalSignageImages,
                        timeText,
                        timeoutText,
                        showSplashScreen,
                        showScreenSaver,
                        showCompanyLogoInQR,
                        selected_language,
                        sensitiveScreenTimeout,
                        sensitiveAlertTimeout,
                        bufferDuration,
                        showDisplayName,
                        splashScreenMessage,
                        showStaticQR,
                    } = page3;

                    if (this.applicationFrame == 'kiosk') {
                        const updatedShowCompanyLogoInQR =
                            showCompanyLogoInQR ?? this.pageData.page3?.showCompanyLogoInQR;

                        this.pageData.page3 = {
                            logo: logo ?? this.pageData.page3?.logo,
                            showCompanyLogoInQR: updatedShowCompanyLogoInQR,
                            qrLogo: updatedShowCompanyLogoInQR ? qrLogo ?? this.pageData.page3?.qrLogo : null,
                            colorScheme: colorScheme ?? this.pageData.page3?.colorScheme,
                            background: background ?? this.pageData.page3?.background,
                            waitingAnimation: waitingAnimation ?? this.pageData.page3?.waitingAnimation,
                            showScreenSaver: showScreenSaver ?? this.pageData.page3?.showScreenSaver ?? 1,
                            digitalSignage: digitalSignage ?? this.pageData.page3?.digitalSignage,
                            timeText: timeText !== undefined ? timeText : this.pageData.page3?.timeText,
                            timeoutText: timeoutText !== undefined ? timeoutText : this.pageData.page3?.timeoutText,
                            sensitiveScreenTimeout:
                                sensitiveScreenTimeout ?? this.pageData.page3?.sensitiveScreenTimeout,
                            sensitiveAlertTimeout: sensitiveAlertTimeout ?? this.pageData.page3?.sensitiveAlertTimeout,
                            bufferDuration: bufferDuration ?? this.pageData.page3?.bufferDuration,
                            showSplashScreen: showSplashScreen ?? this.pageData.page3?.showSplashScreen ?? 0,
                            showDisplayName: showDisplayName ?? this.pageData.page3?.showDisplayName ?? 0,
                            splashScreenMessage: splashScreenMessage ?? this.pageData?.page3?.splashScreenMessage,
                            showStaticQR: showStaticQR ?? this.pageData?.page3?.showStaticQR,
                        };
                    } else {
                        this.pageData.page3 = {
                            logo: logo ?? this.pageData.page3?.logo,
                            colorScheme: colorScheme ?? this.pageData.page3?.colorScheme,
                            appColors: appColors ?? this.pageData.page3?.appColors,
                            background: background ?? this.pageData.page3?.background,
                            waitingAnimation: waitingAnimation ?? this.pageData.page3?.waitingAnimation,
                            sensitiveScreenTimeout:
                                sensitiveScreenTimeout ?? this.pageData.page3?.sensitiveScreenTimeout,
                            sensitiveAlertTimeout: sensitiveAlertTimeout ?? this.pageData.page3?.sensitiveAlertTimeout,
                            bufferDuration: bufferDuration ?? this.pageData.page3?.bufferDuration,
                        };
                    }
                    if (companyLogoImages) {
                        this.companyLogoImages = companyLogoImages;
                    }
                    if (backgroundImages) {
                        this.backgroundImages = backgroundImages;
                    }
                    if (digitalSignageImages) {
                        this.digitalSignageImages = digitalSignageImages;
                    }
                    if (waitingAnimationList) {
                        this.waitingAnimationList = waitingAnimationList;
                    }
                    if (qrLogoImages) {
                        this.qrLogoImages = qrLogoImages;
                    }
                    if (selected_language) {
                        this.selected_language = selected_language;
                    }
                }
                if (page4) {
                    const {
                        template,
                        templateStyles,
                        templateData,
                        sectionTitle,
                        upperSectionTitle,
                        lowerSectionTitle,
                    } = page4;
                    this.pageData.page4 = {
                        template: template !== undefined ? template : this.pageData.page4?.template,
                        templateStyles: templateStyles ? templateStyles : this.pageData.page4?.templateStyles,
                        templateData: templateData !== undefined ? templateData : this.pageData.page4?.templateData,
                        sectionTitle: sectionTitle ?? this.pageData.page4?.sectionTitle,
                        lowerSectionTitle: lowerSectionTitle ?? this.pageData.page4?.lowerSectionTitle,
                        upperSectionTitle: upperSectionTitle ?? this.pageData.page4?.upperSectionTitle,
                    };
                }
            },
            resetRssFeed() {
                this.rssFeedsDetails = { ...this.previousRssFeedsDetails };
            },
            captureRssFeed(data) {
                this.rssFeedsDetails = { ...data };
            },
            captureEducationFeed(data) {
                this.educationFeedDetails = { ...data };
            },
            async createTemplate(status) {
                try {
                    const templateData = {
                        health_care_provider: this.healthCareProvider,
                        template_meta_id: 1,
                        application: this.applicationFrame,
                        template: 1,
                        status,
                        selected_template: this.templateType,
                        dashboardName: this.dashboardName,
                    };

                    if (this.applicationFrame == 'mobex-health-hub') {
                        templateData.template =
                            this.current_template == 1
                                ? 1
                                : this.current_template == 4
                                ? 2
                                : this.current_template == 6
                                ? 3
                                : 4;
                    } else if (this.applicationFrame == 'mobex-health-home') {
                        templateData.template = this.current_template == 10 ? 1 : 2;
                    } else if (this.applicationFrame == 'mobex-health-plus') {
                        templateData.template = this.current_template == 12 ? 1 : 2;
                    }

                    const endpoint = dashboardBuilder.createTemplate();
                    const templateCreatedResponse = await this.$api.post(endpoint, templateData);
                    if (!templateCreatedResponse.data.success) {
                        throw templateCreatedResponse.data.error;
                    }

                    if (templateCreatedResponse?.data?.response) {
                        this.dashboardId = templateCreatedResponse?.data?.response?.id;
                        this.$router.replace({
                            name: 'DashboardBuilder',
                            query: { id: this.dashboardId, status: 'draft' },
                        });
                    }

                    return true;
                } catch (error) {
                    this.$toasted.error(error);
                    throw new Error(error);
                } finally {
                    this.loading = false;
                }
            },
            async updateTemplate(status) {
                try {
                    this.loading = true;

                    const { page2, page3, page4 } = this.pageData;

                    const data = {
                        page2: { ...page2 },
                        page3: { ...page3 },
                        page4: { ...page4 },
                    };

                    const mapFunctions = (functions) =>
                        functions?.map(
                            ({ id, function_name, function_type, image, active, secondary_function_name }) => ({
                                id,
                                function_name,
                                secondary_function_name,
                                function_type,
                                image,
                                active,
                            })
                        ) ?? [];

                    data.page2 = {
                        coreFunctions: mapFunctions(data.page2.coreFunctions),
                        genericFunctions: mapFunctions(data.page2.genericFunctions),
                        thirdPartyFunctions: mapFunctions(data.page2.thirdPartyFunctions),
                        enableQR: data?.page2?.enableQR ?? 0,
                        showQR: data?.page2?.showQR ?? 0,
                        isMaster: data?.page2?.isMaster ?? 0,
                        bidirectional_messaging: data?.page2?.bidirectional_messaging ?? 0,
                        bidirectionalDisclaimer: data?.page2?.bidirectionalDisclaimer ?? '',
                    };

                    // Page 3 data construction
                    data.page3 = {
                        logo: data.page3?.logo,
                        qrLogo: data.page3?.qrLogo,
                        colorScheme: data.page3?.colorScheme,
                        appColors: page3?.appColors,
                        background: data.page3?.background,
                        waitingAnimation: data.page3?.waitingAnimation,
                        digitalSignage: data.page3?.digitalSignage,
                        timeText: Number(data.page3?.timeText),
                        timeoutText: Number(data.page3?.timeoutText),
                        showScreenSaver: data.page3?.showScreenSaver ?? 0,
                        sensitiveScreenTimeout: data.page3?.sensitiveScreenTimeout,
                        sensitiveAlertTimeout: data.page3?.sensitiveAlertTimeout,
                        bufferDuration: data.page3?.bufferDuration,
                        showSplashScreen: data.page3?.showSplashScreen ?? 0,
                        showDisplayName: data.page3?.showDisplayName ?? 0,
                        splashScreenMessage: data.page3?.splashScreenMessage ?? '',
                        showCompanyLogoInQR: data.page3?.showCompanyLogoInQR ?? 0,
                        showStaticQR: data.page3?.showStaticQR ?? 0,
                    };

                    if (['mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame)) {
                        if (!data?.page4?.sectionTitle) {
                            delete data.page4?.sectionTitle;
                        }
                        if (!data?.page4?.upperSectionTitle) {
                            delete data.page4?.upperSectionTitle;
                        }
                        if (!data?.page4?.lowerSectionTitle) {
                            delete data.page4?.lowerSectionTitle;
                        }
                    } else {
                        delete data.page4?.sectionTitle;
                        delete data.page4?.upperSectionTitle;
                        delete data.page4?.lowerSectionTitle;
                    }

                    // RSS feed handling for kiosk
                    if (this.applicationFrame === 'kiosk') {
                        const rssFeedsChanged =
                            JSON.stringify(this.previousRssFeedsDetails) !== JSON.stringify(this.rssFeedsDetails);

                        if (
                            !Object.keys(this.rssFeedsDetails).length &&
                            Object.keys(this.previousRssFeedsDetails).length
                        ) {
                            await this.deleteRSS();
                        } else if (rssFeedsChanged) {
                            await this.updateRSS();
                        }
                    }

                    const isRSSSelected = data?.page2?.genericFunctions?.find((el) => el.function_name === 'RSS Feed');

                    if (!isRSSSelected) {
                        this.rssFeedsDetails = {};
                    }

                    const templateData = {
                        data: data,
                        rssinfo: this.rssFeedsDetails,
                        selected_template: this.templateType,
                        health_care_provider: this.healthCareProvider,
                        template_meta_id: 1,
                        application: this.applicationFrame,
                        template: 1,
                        status,
                        dashboardName: this.dashboardName,
                        sensitiveScreenTimeout: this.pageData?.page3?.sensitiveScreenTimeout,
                        sensitiveAlertTimeout: data?.page3?.sensitiveAlertTimeout,
                        isMaster: this.pageData?.page2?.isMaster,
                        showSplashScreen: data?.page3?.showSplashScreen,
                        selected_language: this.selected_language,
                        in_update_state: 0,
                    };

                    if (this.applicationFrame == 'mobex-health-hub') {
                        templateData.template =
                            this.current_template == 1
                                ? 1
                                : this.current_template == 4
                                ? 2
                                : this.current_template == 6
                                ? 3
                                : 4;
                    } else if (this.applicationFrame == 'mobex-health-home') {
                        templateData.template = this.current_template == 10 ? 1 : 2;
                    } else if (this.applicationFrame == 'mobex-health-plus') {
                        templateData.template = this.current_template == 12 ? 1 : 2;
                    }
                    // else if (this.applicationFrame === 'mobex-health-plus-v1') {
                    //     const mapUpdatedOrCreated = (items) =>
                    //         items
                    //             ?.map((el) => {
                    //                 const {
                    //                     id,
                    //                     label_name,
                    //                     function_name,
                    //                     secondary_function_name,
                    //                     function_type,
                    //                     image,
                    //                     isCreated,
                    //                     isUpdated,
                    //                     description,
                    //                     file_id,
                    //                     is_required,
                    //                     is_active,
                    //                 } = el;
                    //                 if (isCreated || isUpdated) {
                    //                     return {
                    //                         id: isUpdated ? id : undefined,
                    //                         function_name,
                    //                         label_name,
                    //                         secondary_function_name,
                    //                         function_type,
                    //                         image,
                    //                         description,
                    //                         file_id,
                    //                         is_required,
                    //                         is_active,
                    //                     };
                    //                 }
                    //             })
                    //             .filter(Boolean) ?? [];

                    //     const { coreFunctions, signupFieldList, introSlideList } = this.pageData?.page2;

                    //     templateData.tags = this.pageData?.page2?.tags ?? [];
                    //     templateData.profileFunction = mapUpdatedOrCreated(coreFunctions);
                    //     templateData.signUpFields = mapUpdatedOrCreated(signupFieldList);
                    //     templateData.slides = mapUpdatedOrCreated(introSlideList);
                    // }

                    const endpoint = dashboardBuilder.updateTemplate(this.dashboardId);
                    const templateCreatedResponse = await this.$api.put(endpoint, templateData);
                    if (!templateCreatedResponse.data.success) {
                        throw templateCreatedResponse.data.error;
                    }
                    let temp = [];
                    let temp1 = [];

                    if (data?.page4?.template) {
                        Object.keys(data.page4?.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(data.page4.template[group]).forEach((li) => {
                                    if (
                                        data.page4.template[group][li].elements.length &&
                                        data.page4.template[group][li].elements[0].id != null &&
                                        data.page4.template[group][li].elements[0].function_type === 'generic'
                                    ) {
                                        if (data.page4.template[group][li].elements[0].function_name == 'Dashboard') {
                                            temp1.push(data.page4.template[group][li].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][li].elements[0].id);
                                        }
                                    }
                                });
                            }
                            if (group === 'GSI') {
                                Object.keys(data.page4.template[group]).forEach((gsi) => {
                                    if (
                                        data.page4.template[group][gsi].elements.length &&
                                        data.page4.template[group][gsi].elements[0].id != null &&
                                        data.page4.template[group][gsi].elements[0].function_type === 'generic'
                                    ) {
                                        if (data.page4.template[group][gsi].elements[0].function_name == 'Dashboard') {
                                            temp1.push(data.page4.template[group][gsi].elements[0].id);
                                        } else {
                                            temp.push(data.page4.template[group][gsi].elements[0].id);
                                        }
                                    }
                                });
                            }
                        });
                    }

                    const endpoint1 = memberProfile.changeActiveStatus();
                    const res = await this.$api.post(endpoint1, {
                        ids: temp,
                        embeddedids: temp1,
                        isMaster: this.pageData?.page2?.isMaster,
                        dashboardId: this.dashboardId,
                    });
                    if (!res.data.success) {
                        throw res.data.error;
                    }

                    let ids = [];

                    this.companyLogoImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    this.backgroundImages.forEach((item) => {
                        ids.push(item.id);
                    });

                    if (this.applicationFrame == 'kiosk') {
                        this.digitalSignageImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_digital_signage_image') {
                                ids.push(item.id);
                            }
                        });
                        this.waitingAnimationList.forEach((item) => {
                            if (item.type == 'dashboard_builder_waiting_animation') {
                                ids.push(item.id);
                            }
                        });
                        this.qrLogoImages.forEach((item) => {
                            if (item.type == 'dashboard_builder_qr_logo') {
                                ids.push(item.id);
                            }
                        });
                    }

                    const endpoint2 = dashboardBuilder.changeActiveStatusofDigitalSignage();
                    const result = await this.$api.post(endpoint2, { ids });

                    if (!result.data.success) {
                        throw result.data.error;
                    }

                    if (this.pageData?.page2?.deletedSlideIds?.length) {
                        const endpoint1 = starkidsDashboard.deleteSlides();
                        const res = await this.$api.post(endpoint1, { ids: this.pageData?.page2.deletedSlideIds });
                        if (!res.data.success) {
                            throw res.data.error;
                        }
                    }

                    this.$router.push('/dashboard-builder');
                    this.$toasted.success('Template updated successfully');

                    if (status !== 'draft' && this.applicationFrame != 'family-connect') {
                        await this.sendDashboardBuildNotification();
                    }
                } catch (error) {
                    this.$toasted.error(error);
                    throw new Error(error);
                } finally {
                    this.loading = false;
                }
            },
            async deleteUnSaved() {
                const endpoint2 = dashboardBuilder.deleteActiveStatusofDigitalSignage();
                const res1 = await this.$api.put(endpoint2);
                if (!res1.data.success) {
                    throw res1.data.error;
                }
            },
            async updateRSS() {
                try {
                    const data = {
                        rss_id: this.rssFeedsDetails?.id ?? null,
                        title: this.rssFeedsDetails?.feedtitle ?? '',
                        url: this.rssFeedsDetails?.url ?? '',
                        dashboard_id: this.dashboardId,
                        language: 'en',
                        language_title: 'English',
                        module_category: this.rssFeedsDetails?.module_category ?? null,
                    };
                    const endpoint = dashboardBuilder.updateRSS();
                    await this.$api.post(endpoint, data);
                } catch (err) {
                    console.error('Failed to update RSS', err);
                }
            },
            async deleteRSS() {
                try {
                    const endpoint = dashboardBuilder.deleteRSS(this.dashboardId);
                    await this.$api.delete(endpoint);
                } catch (err) {
                    console.error('Failed to delete RSS', err);
                }
            },
        },
        async created() {
            window.scrollTo(0, 0);

            if (this.$route.query.id) {
                this.dashboardId = Number(this.$route.query.id);
                await this.getDashboardUpdatingStatus();
            }

            this.dataFetched = true;
            this.loading = false;
        },
    };
</script>

<style scoped>
    .other-btn {
        background: #fff;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: #5057c3;
        width: 200px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid 5057C3;
        font-weight: 500;
    }

    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 150px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }
</style>
