<template>
    <div class="rounded-5 w-100 my-5">
        <div class="d-grid dashboard-builder w-50 px-4">
            <h1 style="font-weight: bold">Select Healthcare Provider</h1>
            <Multiselect
                class="calendar-search-box radius-10"
                label="name"
                track-by="id"
                :options="healthCareProviderOptions"
                :searchable="true"
                :placeholder="'Select Hospital / Healthcare Provider'"
                :show-labels="false"
                :value="healthCareProvider"
                @select="(e) => handleHealthCareSelection(e)"
            />

            <span style="color: red; font-weight: bold">{{ errors.healthCareProvider }}</span>
        </div>

        <div class="bg-white my-5 rounded-4" style="max-width: 85rem">
            <div class="w-100">
                <h1 class="px-5 py-3 page-heading" style="background: #f1f7ff; font-weight: bold">
                    Select an application
                </h1>
            </div>
            <div class="d-flex justify-content-start py-4" style="max-width: 85rem; overflow: auto">
                <div
                    @click="selectApp('kiosk')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 15rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'kiosk'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'kiosk'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/dashboard2.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Community</span>
                    </div>
                </div>
                <div
                    @click="selectApp('mobex-health-hub')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 18rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-hub'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-hub'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/frame4.png" alt="" class="l-img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Hub</span>
                    </div>
                </div>
                <div
                    @click="selectApp('mobex-health-home')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 15rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-home'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-home'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/mobexHealthHome.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Home</span>
                    </div>
                </div>
                <div
                    v-if="isDefaultCompany"
                    @click="selectApp('star-kid')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 15rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'star-kid'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'star-kid'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/starkid.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>STAR Kids</span>
                    </div>
                </div>
                <div
                    @click="selectApp('mobex-health-plus')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 15rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-plus'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-plus'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/demoAppDashboard.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Plus</span>
                    </div>
                </div>
                <!-- <div
                    @click="selectApp('mobex-health-plus-v1')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 15rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'mobex-health-plus-v1'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'mobex-health-plus-v1'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/demoAppDashboard.png" alt="" class="img" />
                    </div>
                    <div class="text-center py-4">
                        <span>MobexHealth Plus V1</span>
                    </div>
                </div> -->
                <div
                    @click="selectApp('family-connect')"
                    class="d-grid justify-content-center select-application-box"
                    :class="$route.query.id ? 'cursor-not-allowed' : 'cursor-pointer'"
                    style="min-width: 20rem"
                >
                    <div class="d-flex justify-content-center my-4">
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication != 'family-connect'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            radio_button_unchecked
                        </span>
                        <span
                            class="material-symbols-outlined"
                            v-if="deliverApplication == 'family-connect'"
                            style="font-weight: bold; font-size: 30px; color: #5158c3"
                        >
                            check_circle
                        </span>
                    </div>
                    <div class="d-flex justify-content-center my-2">
                        <img src="@/assets/px/drag-and-drop/frame5.png" alt="" class="l-img" />
                    </div>
                    <div class="text-center py-4">
                        <span>Family Connect</span>
                    </div>
                </div>
            </div>
            <span style="color: red; font-weight: bold">{{ errors.applicationFrame }}</span>
        </div>

        <TemplateDetails
            v-if="this.healthCareProvider != null && applicationFrame != null"
            :templateType="templateType"
            :pageData="pageData"
            :errors="errors"
            :applicationFrame="applicationFrame"
            @capturetemplateName="capturetemplateName"
            :dashboardName="dashboardName"
        />

        <Loading v-if="loading" />
    </div>
</template>

<script>
    import TemplateDetails from './template_details.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { companies } from '@/util/apiRequests';
    export default {
        name: 'SelectCompanyApplication',
        components: {
            TemplateDetails,
            Loading,
        },
        props: {
            errors: {
                type: Object,
            },
            pageData: {
                type: Object,
            },
            healthCareProviderData: {
                type: Object,
            },
            templateType: {
                type: Object,
            },
            applicationFrame: {
                type: String,
            },
            dashboardName: {
                type: String,
            },
        },
        data() {
            return {
                healthCareProvider: null,
                templateTypeData: null,
                deliverApplication: null,
                healthCareProviderOptions: [],
                builderPageNo: this.pageNo,
                openTemplateModal: false,
                loading: true,
            };
        },
        computed: {
            isDefaultCompany() {
                return Number(process.env.VUE_APP_mobex_plus_company_id) === this.$store.state.user.company_id;
            },
        },
        methods: {
            async capturetemplateName(event) {
                const { page1 } = event;
                if (page1) {
                    const { templateType, dashboardName } = page1;
                    this.$emit('captureValues', { page1: { templateType, dashboardName } });
                }
            },
            openTemplateDetailModal() {
                this.openTemplateModal = true;
            },
            handleHealthCareSelection(e) {
                this.healthCareProvider = e;
                this.$emit('captureValues', { page1: { healthCareProvider: e } });
            },
            selectApp(app) {
                if (this.$route.query.id) return;

                this.deliverApplication = app;
                if (this.deliverApplication == 'mobex-health-hub') {
                    this.$emit('captureValues', { page1: { applicationFrame: app, templateType: null } });
                } else {
                    this.$emit('captureValues', { page1: { applicationFrame: app } });
                }
                this.errors.applicationFrame = null;
            },
            async getCompany() {
                try {
                    let result = await this.$api.get(companies.getCompanyInfo());
                    let temp = [];
                    temp.push({ id: '1', name: result.data[0].company_name });
                    this.healthCareProviderOptions = temp;
                    this.handleHealthCareSelection({ id: '1', name: result.data[0].company_name });
                } catch (err) {
                    console.log(err);
                }
            },
        },
        async created() {
            window.scrollTo(0, 0);

            await this.getCompany();
            this.templateTypeData = this.templateType;
            this.deliverApplication = this.applicationFrame;
            this.loading = false;
        },
    };
</script>

<style scoped>
    .select-application-box {
        border-radius: 30px;
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        margin-inline: 10px;
        cursor: pointer;
    }

    @media only screen and (max-width: 2000px) {
        .img {
            width: 200px;
            height: 350px;
        }

        .l-img {
            width: 320px;
            height: 230px;
        }
    }

    @media only screen and (min-width: 1500px) and (max-width: 2000px) {
        .img {
            width: 150px;
            height: 250px;
        }

        .l-img {
            width: 280px;
            height: 200px;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1500px) {
        .img {
            width: 130px;
            height: 230px;
        }

        .l-img {
            width: 230px;
            height: 180px;
        }
    }

    @media only screen and (max-width: 1200px) {
        .img {
            width: 100px;
            height: 200px;
        }

        .l-img {
            width: 180px;
            height: 100px;
        }
    }

    .s-img {
        max-width: 60%;
    }
</style>
