<template>
    <div class="education-feed-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <template v-if="showSelectSvgModal">
                            <span class="label-app-list-header">Select SVG</span>
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="d-flex search-svg-icon mx-4 my-2">
                                    <input
                                        type="text"
                                        placeholder="Find icon here"
                                        v-model="searchedSvg"
                                        @input="handleSearchedSVG"
                                    />
                                    <img
                                        src="@/assets/px/drag-and-drop/search-icon.svg"
                                        alt=""
                                        class="img mx-2"
                                        style="width: 20px"
                                    />
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <span class="label-app-list-header"> Education Feed </span>
                            <button v-if="!loading && !isAddField" @click="addNew" class="no-bg btn-info px-4 mx-4">
                                Add Item
                            </button>
                        </template>
                    </div>
                </div>
                <template v-if="!loading">
                    <!-- Main Form -->
                    <template v-if="!showSelectSvgModal && !isAddField">
                        <div v-if="!isHomeTemplate && feedList.length == 0" class="mx-5 text-center">
                            Add Feed items to show
                        </div>
                        <template v-if="feedList.length">
                            <div v-for="(feed, index) in feedList" :key="index">
                                <div
                                    class="profile-listing-body d-flex mx-5 justify-content-between align-items-center"
                                >
                                    <span class="w-70 label-app-list-header list-title">{{ feed.title }}</span>
                                    <div class="d-flex">
                                        <ejs-tooltip
                                            class="tooltipcontainer"
                                            content="Edit"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="BottomRight"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined icon-edit me-2"
                                                role="button"
                                                @click="editFeed(feed)"
                                            >
                                                edit_square
                                            </span>
                                        </ejs-tooltip>
                                        <ejs-tooltip
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="BottomRight"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined icon-delete"
                                                role="button"
                                                @click="deleteFeed(feed.id)"
                                            >
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <hr v-if="isHomeTemplate && feedList.length" />
                        <div v-if="isHomeTemplate" class="section-body add-new-app-form mx-0">
                            <ValidationObserver ref="form">
                                <form style="box-shadow: none">
                                    <div
                                        class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3"
                                    >
                                        <label class="me-3 d-flex justify-content-end add-new-app-label"
                                            >Icon/Images*</label
                                        >

                                        <div class="d-flex align-items-center w-100 gap-1">
                                            <button
                                                type="button"
                                                class="w-40 no-bg text-button btn-add-new-app"
                                                @click="chooseFile"
                                            >
                                                Choose file
                                            </button>
                                            <button
                                                type="button"
                                                class="no-bg text-button btn-add-new-app w-40"
                                                @click="chooseFromCompany"
                                            >
                                                Choose icon
                                            </button>
                                            <input
                                                id="fileid"
                                                type="file"
                                                name="upload"
                                                ref="upload"
                                                size="100000"
                                                @change="uploadFile"
                                                hidden
                                                required
                                                accept=".svg, .png, .jpg, .jpeg"
                                            />

                                            <div
                                                class="img-container-small d-flex justify-content-center align-items-center"
                                            >
                                                <img
                                                    v-if="!uploadedImg && !showImgLoader && !isSvgPresent"
                                                    src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                                />

                                                <img
                                                    v-if="!showImgLoader && isSvgPresent"
                                                    :src="svgToBase64"
                                                    alt="image not found"
                                                />
                                                <img
                                                    v-if="uploadedImg && !showImgLoader && !isSvgPresent"
                                                    :src="uploadedImg"
                                                    alt="image not uploaded"
                                                />

                                                <div
                                                    class="d-flex flex-column"
                                                    v-if="showImgLoader"
                                                    style="margin-top: 15px"
                                                >
                                                    <div
                                                        class="d-flex justify-content-center align-items-center img-loader-main"
                                                    >
                                                        <b-spinner variant="primary" class="img-loader"></b-spinner>
                                                    </div>
                                                    <span class="img-loader-text">Uploading...</span>
                                                </div>
                                                <ejs-tooltip
                                                    v-if="uploadedImg || svgImg"
                                                    class="tooltipcontainer"
                                                    content="Delete"
                                                    target="#target"
                                                    cssClass="customtooltip"
                                                    position="top"
                                                >
                                                    <span
                                                        id="target"
                                                        class="material-symbols-outlined img-delete-small"
                                                        role="button"
                                                        @click="resetFileUpload()"
                                                    >
                                                        delete
                                                    </span>
                                                </ejs-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap my-4 flex-column gap-2">
                                        <div class="d-flex color-box w-100">
                                            <div class="d-flex my-2 mx-4 w-100">
                                                <div class="d-flex align-items-center w-40" style="gap: 1%">
                                                    <input
                                                        type="radio"
                                                        name="color_type_kiosk"
                                                        id="primary_colors"
                                                        value="primary_colors"
                                                        style="transform: scale(0.8)"
                                                        v-model="functionInfo.selectedSchemeOption"
                                                        @change="selectColorScheme($event)"
                                                    />
                                                    <label for="option2" style="font-weight: bold"
                                                        >Primary Color Option</label
                                                    >
                                                </div>
                                                <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                                    <div
                                                        v-for="(value, index) in colorScheme.primary_colors"
                                                        class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                        :key="index"
                                                        :style="{ background: value }"
                                                    >
                                                        {{ value }}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex color-box w-100">
                                            <div class="d-flex my-2 mx-4 w-100">
                                                <div class="d-flex align-items-center w-40" style="gap: 1%">
                                                    <input
                                                        type="radio"
                                                        name="color_type_kiosk"
                                                        id="secondary_colors"
                                                        value="secondary_colors"
                                                        style="transform: scale(0.8)"
                                                        v-model="functionInfo.selectedSchemeOption"
                                                        @change="selectColorScheme($event)"
                                                    />
                                                    <label for="option2" style="font-weight: bold"
                                                        >Secondary Color Option</label
                                                    >
                                                </div>
                                                <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                                    <div
                                                        v-for="(value, index) in colorScheme.secondary_colors"
                                                        class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                        :key="index"
                                                        :style="{ background: value }"
                                                    >
                                                        {{ value }}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                            <div class="d-flex my-2 mx-4 w-100">
                                                <div class="d-flex align-items-center w-40" style="gap: 1%">
                                                    <input
                                                        type="radio"
                                                        name="color_type_kiosk"
                                                        id="customized_colors"
                                                        value="customized_colors"
                                                        style="transform: scale(0.8)"
                                                        v-model="functionInfo.selectedSchemeOption"
                                                        @change="selectColorScheme($event)"
                                                    />
                                                    <label for="option2" style="font-weight: bold"
                                                        >Custom Color Option</label
                                                    >
                                                </div>
                                                <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                                    <div
                                                        v-for="(value, index) in colorScheme.customized_colors"
                                                        class="d-flex align-items-center px-2 w-25 text-uppercase color-box-item"
                                                        :key="index"
                                                        :style="{ background: value }"
                                                    >
                                                        {{ value }}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="d-flex color-box w-100">
                                            <div class="d-flex my-2 mx-4 w-100">
                                                <div class="d-flex align-items-center w-40" style="gap: 1%">
                                                    <input
                                                        type="radio"
                                                        name="color_type_kiosk"
                                                        id="module_customized_colors"
                                                        value="module_customized_colors"
                                                        style="transform: scale(0.8)"
                                                        v-model="functionInfo.selectedSchemeOption"
                                                        @change="selectColorScheme($event)"
                                                    />
                                                    <label for="option2" style="font-weight: bold"
                                                        >Custom Module Color Option</label
                                                    >
                                                </div>
                                                <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                                    <div
                                                        v-for="(value, index) in colorScheme.module_customized_colors"
                                                        class="d-flex align-items-center px-2 w-25"
                                                        :key="index"
                                                    >
                                                        <input
                                                            v-if="
                                                                functionInfo.selectedSchemeOption ==
                                                                'module_customized_colors'
                                                            "
                                                            type="color"
                                                            name=""
                                                            id=""
                                                            v-model="colorScheme.module_customized_colors[index]"
                                                            @change="selectCustomColorScheme($event, index)"
                                                            class="w-100 p-0"
                                                        />
                                                        <div v-else class="default-color"></div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-5 px-3">
                                        <Input
                                            label="Title"
                                            labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                            type="text"
                                            class="field-container d-flex align-items-center mb-4"
                                            name="title"
                                            placeholder="Enter Title"
                                            v-model="functionInfo.title"
                                            required
                                        />
                                        <span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                for="category"
                                                class="me-3 d-flex add-new-app-label justify-content-end"
                                                style="width: 28%"
                                                >Category</label
                                            >
                                            <Multiselect
                                                id="category"
                                                track-by="id"
                                                label="title"
                                                class="w-70"
                                                placeholder="Search or Select Category"
                                                v-model="categoryInfo"
                                                :options="categoryList"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="functionInfo.module_category = $event.id"
                                            />
                                        </span>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>

                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center" style="bottom: 10%">
                                <button
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateGenericModule"
                                >
                                    Save
                                </button>
                                <button type="button" class="no-bg btn-cancel px-4" @click="handleCancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>

                    <!-- Education Item -->
                    <template v-if="!showSelectSvgModal && isAddField">
                        <div class="d-flex px-5 w-80 align-items-center pt-2">
                            <label style="font-size: 1rem" for="sorting"> Use External Link </label>
                            <Toggle
                                id="sorting"
                                class="toggle-switch"
                                :labelClass="'switch'"
                                :spanClass="'slider'"
                                v-model="rssDetails.isExternalUrl"
                            />
                        </div>

                        <div class="m-4 py-4 add-new-app-form" style="min-height: 10rem">
                            <ValidationObserver ref="form">
                                <form style="box-shadow: none" class="p-0">
                                    <div v-if="rssDetails.isExternalUrl" class="mx-5 px-3">
                                        <Input
                                            label="Title"
                                            labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                            type="text"
                                            class="field-container d-flex align-items-center mb-4"
                                            name="title"
                                            placeholder="Enter Title"
                                            v-model="rssDetails.title"
                                            required
                                        />
                                        <Input
                                            label="URL"
                                            labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                            type="text"
                                            class="field-container d-flex align-items-center mb-4"
                                            name="url"
                                            rules="ValidateUrl"
                                            placeholder="Enter URL"
                                            v-model="rssDetails.url"
                                            required
                                        />
                                        <span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                for="category"
                                                class="me-3 d-flex add-new-app-label justify-content-end"
                                                style="width: 28%"
                                                >Category</label
                                            >
                                            <Multiselect
                                                id="category"
                                                track-by="id"
                                                label="title"
                                                class="position-relative w-70"
                                                placeholder="Search or Select Category"
                                                v-model="categoryInfo"
                                                :options="categoryList"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="rssDetails.module_category = $event.id"
                                            />
                                        </span>
                                    </div>
                                    <div v-else class="mx-5 px-3">
                                        <span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                class="me-3 d-flex add-new-app-label w-20 justify-content-end"
                                                for="rss"
                                            >
                                                Select Feed*
                                            </label>
                                            <Multiselect
                                                id="rss"
                                                track-by="id"
                                                label="feedtitle"
                                                class="w-70"
                                                placeholder="Search RSS"
                                                v-model="selected_rss"
                                                :options="rss_list"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                            />
                                        </span>
                                        <span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                for="category"
                                                class="me-3 d-flex add-new-app-label w-20 justify-content-end"
                                                >Category</label
                                            >
                                            <Multiselect
                                                id="category"
                                                track-by="id"
                                                label="title"
                                                class="w-70"
                                                placeholder="Search or Select Category"
                                                v-model="categoryInfo"
                                                :options="categoryList"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="rssDetails.module_category = $event.id"
                                            />
                                        </span>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>

                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center" style="bottom: 10%">
                                <button type="button" class="primary btn-save" @click="saveRssFeed">Save</button>
                                <button type="button" class="no-bg btn-cancel px-4" @click="resetRssFeed">
                                    Go Back
                                </button>
                            </div>
                        </div>
                    </template>

                    <!-- Select SVG -->
                    <div v-if="showSelectSvgModal" class="modal-list-third-party-app">
                        <ValidationObserver ref="svgForm">
                            <form style="box-shadow: none" class="p-0">
                                <template v-if="!loading && showSelectSvgModal">
                                    <div
                                        v-if="svgList.length"
                                        class="d-flex flex-wrap gap-3 m-3 p-3 overflow-auto"
                                        style="max-height: 350px; min-height: 200px; padding: 5px; padding-bottom: 10px"
                                    >
                                        <div
                                            v-for="(svg, index) in svgList"
                                            :key="index"
                                            :class="(selectedSvg.id === svg.id ? 'active-bg ' : '') + 'img-container'"
                                            style="max-height: 50px"
                                            @click="selectedSvg = svg"
                                        >
                                            <ejs-tooltip
                                                target="#target"
                                                :content="svg.title"
                                                class="tooltipcontainer"
                                                cssClass="customtooltip"
                                            >
                                                <img
                                                    id="target"
                                                    :src="svg.imageUrl"
                                                    alt=""
                                                    class="img"
                                                    style="width: 50px"
                                                />
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                    <div v-else-if="searchedSvg !== ''" class="w-100">
                                        <div
                                            class="d-flex justify-content-center align-items-center py-5"
                                            style="min-height: 200px"
                                        >
                                            <h4>No SVG available</h4>
                                        </div>
                                    </div>
                                    <div v-else class="w-100">
                                        <div
                                            class="d-flex justify-content-center align-items-center py-5"
                                            style="min-height: 200px"
                                        >
                                            <h4>No SVG Added</h4>
                                        </div>
                                    </div>
                                    <div class="section-footer add-new-app-form my-4">
                                        <div class="d-flex justify-content-center">
                                            <button type="button" class="primary btn-save-application" @click="saveSvg">
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                class="no-bg text-button btn-cancel px-4"
                                                @click="showSelectSvgModal = false"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </form>
                        </ValidationObserver>
                    </div>
                </template>

                <div
                    v-if="loading"
                    class="d-flex flex-column justify-content-center align-items-center loader-main"
                    style="min-height: 25rem"
                >
                    <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                    <span class="ms-3">Loading ...</span>
                </div>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    import { deleteAllEducationResourcesByDashboardId } from '@/util/dashboard';
    import {
        dashboardBuilder,
        memberProfile,
        rssFeeds,
        starkidEducation,
        education,
        svgPicker,
    } from '@/util/apiRequests';
    import { category } from '../../util/apiRequests';

    const initialFunctionInfoState = {
        title: '',
        image: '',
        url: '',
        module_category: null,
    };

    const initialRssDetails = {
        id: null,
        rssfeedid: null,
        status: 0,
        defaultpinstatus: 0,
        url: '',
        link: '',
        title: '',
        type: 'rssfeed',
        isExternalUrl: false,
    };

    export default {
        name: 'EducationFeedEditmodal',
        components: { NoButtonModal, ValidationObserver },
        data() {
            return {
                rssDetails: { ...initialRssDetails },
                functionInfo: { ...initialFunctionInfoState },
                loading: true,
                isAddField: false,
                Position: '',
                selected_rss: '',
                svgImg: '',
                searchedSvg: '',
                uploadedImg: '',
                feedList: [],
                all_rss_list: [],
                rss_list: [],
                allSvgList: [],
                svgList: [],
                selectedSvg: {},
                colorScheme: {},
                showImgLoader: false,
                isSvgPresent: false,
                showSelectSvgModal: false,
                isModuleLogoUpdated: false,
                categoryInfo: '',
                categoryList: [],
            };
        },
        props: {
            clickedProfileFunction: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
            dashboardId: {
                type: Number,
            },
            group: {
                type: String,
            },
        },
        computed: {
            isHomeTemplate() {
                return ['mobex-health-home','mobex-health-plus'].includes(this.applicationFrame);
            },
            svgToBase64() {
                if (!this.svgImg) {
                    return;
                }

                // Get the outer HTML representation of the SVG element
                const svgHtml = this.svgImg.outerHTML;

                // Encode the SVG HTML string to base64
                const base64Svg = btoa(svgHtml);

                // Return the base64 encoded SVG data
                return 'data:image/svg+xml;base64,' + base64Svg;
            },
        },
        methods: {
            async getCategories() {
                try {
                    const endpoint = category.getCategories(this.$store.state.user.company_id);
                    const response = await this.$api.get(endpoint);
                    this.categoryList = response.data ?? [];
                } catch (err) {
                    this.$toasted.error('Failed to fetch Categories.');
                }
            },
            async fetchApplications() {
                try {
                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.Position,
                        this.dashboardId,
                        this.applicationFrame,
                        this.group === 'LI' ? 'Upper' : 'Lower'
                    );

                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    const { info } = functionResponse.data;

                    if (info.length) {
                        this.functionInfo.id = info[0].id;
                        this.functionInfo.title = info[0].title;
                        this.functionInfo.image = info[0].image;
                        this.functionInfo.color = info[0].color;
                        this.functionInfo.textColor = info[0].textColor;
                        this.functionInfo.primaryColor = info[0].primaryColor;
                        this.functionInfo.secondaryColor = info[0].secondaryColor;
                        this.functionInfo.selectedSchemeOption = info[0].selectedSchemeOption;
                        this.functionInfo.module_category = info[0].module_category;
                        this.uploadedImg = info[0].imageUrl;

                        this.categoryInfo = this.categoryList.find(
                            (category) => category.id === info[0].module_category
                        );

                        if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                            this.colorScheme = {
                                ...this.colorScheme,
                                selectedOption: 'module_customized_colors',
                                module_customized_colors: [
                                    info[0].primaryColor,
                                    info[0].secondaryColor,
                                    info[0].color,
                                    info[0].textColor,
                                ],
                            };
                        }

                        if (this.functionInfo?.image) {
                            let fileExtension = this.functionInfo.image?.split('.')[1];
                            if (['svg', 'svg+xml'].includes(fileExtension)) {
                                await this.urlToSvg(this.uploadedImg);
                            }
                        }

                        await this.getEducationById();
                    }
                    // else {
                    //     await deleteAllEducationResourcesByDashboardId(this.applicationFrame, this.dashboardId);
                    // }
                } catch (err) {
                    this.$toasted.error('Failed to fetch Data');
                }
            },
            async getRssList() {
                try {
                    this.loading = true;
                    let list = await this.$api.get(rssFeeds.getRssList());
                    this.all_rss_list = list.data.info;
                } catch (err) {
                    this.$toasted.error('Failed to fetched RSS List');
                } finally {
                    this.loading = false;
                }
            },
            async getEducationById() {
                try {
                    const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                    const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                    const endpoint = isStarkidApp
                        ? starkidEducation.getEducationById(this.dashboardId, appFrame)
                        : education.getEducationById(this.dashboardId);

                    const response = await this.$api.get(endpoint);
                    if (response?.data?.success) {
                        this.feedList = response.data.message;
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch feed details');
                }
            },
            async deleteFeed(id) {
                try {
                    const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                    const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                    const endpoint = isStarkidApp
                        ? starkidEducation.deleteEducationResource(id, appFrame)
                        : education.deleteEducationResource(id);

                    await this.$api.delete(endpoint);
                    await this.getEducationById();
                } catch (err) {
                    this.$toasted.error('Failed to delete RSS');
                }
            },
            async saveRssFeed() {
                try {
                    this.loading = true;
                    const { isExternalUrl, url, title, id, module_category } = this.rssDetails;

                    if (isExternalUrl && (!url || !title)) {
                        return this.$toasted.error('Please fill all required fields');
                    }
                    if (!isExternalUrl && !this.selected_rss) {
                        return this.$toasted.error('Please select any feed');
                    }

                    const data = {
                        id: id || null,
                        type: isExternalUrl ? 'hyperlink' : 'rssfeed',
                        title: isExternalUrl ? title : this.selected_rss.feedtitle,
                        link: isExternalUrl ? url : undefined,
                        rssfeedid: !isExternalUrl ? this.selected_rss.id : undefined,
                        dashboard_id: this.dashboardId,
                        defaultpinstatus: false,
                        module_category,
                    };

                    const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                    const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                    const endpoint = isStarkidApp
                        ? starkidEducation[!id ? 'addEducation' : 'updateEducation'](appFrame, id)
                        : education[!id ? 'addEducation' : 'updateEducation'](!id ? this.applicationFrame : id);

                    await this.$api[!id ? 'post' : 'put'](endpoint, data);

                    if (!this.functionInfo.id) {
                        await this.createGenericModule();
                    }

                    this.resetRssFeed();
                } catch (e) {
                    this.$toasted.error('Failed to save RSS');
                } finally {
                    this.loading = false;
                }
            },
            async resetRssFeed() {
                this.isAddField = false;
                this.rssDetails = { ...initialRssDetails };
                await this.fetchApplications();
            },
            async createGenericModule() {
                try {
                    const requestData = {
                        ...this.functionInfo,
                        issaved: 0,
                        assigned_to: this.clickedProfileFunction,
                        isMaster: this.pageData?.page2?.isMaster,
                        position: this.Position,
                        dashboard_assigned: this.dashboardId,
                        application: this.applicationFrame,
                    };

                    const endpoint = memberProfile.createGenericModule();
                    const saveFunctionResponse = await this.$api.post(endpoint, requestData);

                    if (!saveFunctionResponse?.data?.success) {
                        throw saveFunctionResponse.data.error;
                    }
                } catch (err) {
                    const errMsg = err ?? 'Failed to create module';
                    this.$toasted.error(errMsg);
                }
            },
            async updateGenericModule() {
                try {
                    // Validate the form for "mobex-health-home"
                    if (this.isHomeTemplate) {
                        const form = this.$refs.form;
                        const isFormValid = await form.validate();
                        if (!isFormValid) {
                            this.$toasted.error('Please fill all required fields');
                            return;
                        }

                        if (!this.isFileAvailable() && !this.functionInfo?.image?.length) {
                            this.$toasted.error('Please upload or select Image');
                            return;
                        }
                    }

                    // Validate feed list
                    if (!this.feedList.length) {
                        this.$toasted.error('At least one item should be added');
                        return;
                    }

                    this.loading = true;

                    if (this.isSvgPresent && this.isModuleLogoUpdated) {
                        let isImageUploaded = await this.uploadModuleLogo();

                        if (!isImageUploaded) {
                            return;
                        }
                    }

                    if (this.isHomeTemplate) {
                        const requestData = {
                            ...this.functionInfo,
                            assigned_to: this.clickedProfileFunction,
                            isMaster: this.pageData?.page2?.isMaster,
                            position: this.Position,
                            dashboard_assigned: this.dashboardId,
                            application: this.applicationFrame,
                            generic_function: this.clickedProfileFunction,
                            section: this.group === 'LI' ? 'Upper' : 'Lower',
                        };

                        delete requestData.id;

                        const endpoint = memberProfile.updateGenericModule(this.functionInfo.id);
                        const saveFunctionResponse = await this.$api.put(endpoint, requestData);

                        if (!saveFunctionResponse?.data?.success) {
                            throw saveFunctionResponse.data.error;
                        }

                        const { template } = this.pageData.page4;

                        Object.keys(template).forEach((group) => {
                            if (group !== this.group) return;

                            Object.keys(template[group]).forEach(async (key) => {
                                if (key !== `${group} ${this.position}`) return;

                                const updateItemStyles = (item, functionInfo) => {
                                    item.style = item.style.replace(
                                        /background-color:[^;]*/,
                                        `background-color:${functionInfo.color}`
                                    );

                                    const styleArray = item.style.split(';');
                                    const colorIndex = styleArray.findIndex(
                                        (style) => style.split(':')[0].trim() === 'color'
                                    );

                                    if (colorIndex !== -1) {
                                        styleArray[colorIndex] = `color:${functionInfo.textColor}`;
                                        item.style = styleArray.join(';');
                                    }

                                    // Update color properties
                                    item.primaryColor = functionInfo.primaryColor;
                                    item.secondaryColor = functionInfo.secondaryColor;
                                };

                                // Usage
                                const item = template[group][key];
                                updateItemStyles(item, this.functionInfo);

                                // Update title and image
                                item.title = this.functionInfo?.title;
                                if (this.functionInfo.image) {
                                    item.elements[0].image_id = this.functionInfo.image;
                                    const imageUrl = await this.geturl(this.functionInfo.image);
                                    item.elements[0].image = imageUrl;
                                }
                            });
                        });
                    }

                    // Success message and cancel event
                    this.$toasted.success(
                        `Education Module ${this.functionInfo.id ? 'updated' : 'added'} successfully!`
                    );
                    this.$emit('cancel');
                } catch (err) {
                    const errMsg = err ?? 'Failed to update module';
                    this.$toasted.error(errMsg);
                } finally {
                    this.loading = false;
                }
            },
            selectColorScheme(event) {
                let colors;
                switch (event.target.value) {
                    case 'primary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                    case 'secondary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'secondary_colors',
                        };
                        colors = this.colorScheme.secondary_colors;
                        break;
                    case 'customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'customized_colors',
                        };
                        colors = this.colorScheme.customized_colors;
                        break;
                    case 'module_customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'module_customized_colors',
                        };
                        colors = this.colorScheme.module_customized_colors;
                        break;
                    default:
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                }

                if (event.target.value !== 'module_customized_colors') {
                    this.colorScheme = {
                        ...this.colorScheme,
                        module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                    };
                }

                this.functionInfo.primaryColor = colors[0];
                this.functionInfo.secondaryColor = colors[1];
                this.functionInfo.color = colors[2];
                this.functionInfo.textColor = colors[3];

                if (this.isSvgPresent) {
                    this.renderSvg(this.svgElement, true);
                    this.isModuleLogoUpdated = true;
                }
            },
            selectCustomColorScheme(event, colorNo) {
                const { selectedOption } = this.colorScheme;
                let colors = [...this.colorScheme[selectedOption]];
                colors[colorNo] = event.target.value;

                this.functionInfo.primaryColor = colors[0];
                this.functionInfo.secondaryColor = colors[1];
                this.functionInfo.color = colors[2];
                this.functionInfo.textColor = colors[3];

                if ((colorNo == 0 || colorNo == 1) && this.isSvgPresent) {
                    this.renderSvg(this.svgElement, true);
                    this.isModuleLogoUpdated = true;
                }
            },
            async uploadModuleLogo() {
                try {
                    let filename = 'module-logo.svg';
                    let svgElement = this.svgImg;

                    const svgData = new XMLSerializer().serializeToString(svgElement);

                    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                    const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                    const formData = new FormData();
                    formData.append('file', svgFile);

                    const endpoint1 = memberProfile.uploadProfileFunctionsImage();
                    const uploadImgResponse = await this.$api.post(endpoint1, formData);

                    if (!uploadImgResponse.data.success) {
                        throw uploadImgResponse.data.error;
                    }

                    this.functionInfo.image = uploadImgResponse.data.s3_link;

                    return true;
                } catch (err) {
                    this.$toasted.error('Failed to upload Image');
                }
                return false;
            },
            addNew() {
                this.isAddField = true;
                this.selected_rss = '';
                this.rssDetails = { ...initialRssDetails };
                this.categoryInfo = '';
                const rssFeedIds = new Set(this.feedList.map((item) => item.rssfeedid).filter(Boolean));
                this.rss_list = this.all_rss_list.filter((item) => !rssFeedIds.has(item.id));
            },
            editFeed(data) {
                this.isAddField = true;

                this.rssDetails = {
                    ...data,
                    url: data.link,
                    isExternalUrl: data.type === 'hyperlink',
                };

                this.categoryInfo = this.categoryList.find((category) => category.id === data.module_category);

                if (this.rssDetails.rssfeedid) {
                    this.selected_rss = this.all_rss_list.find((item) => item.id === this.rssDetails.rssfeedid) || '';
                }

                const rssFeedIds = new Set(
                    this.feedList
                        .filter((item) => data.rssfeedid !== item.rssfeedid)
                        .map((item) => item.rssfeedid)
                        .filter(Boolean)
                );

                this.rss_list = this.all_rss_list.filter((item) => !rssFeedIds.has(item.id));
            },
            handleCancel() {
                this.isAddField = false;
                this.$emit('cancel');
            },
            async getSvgList() {
                try {
                    const endpoint = svgPicker.getSvgList();
                    const res = await this.$api.get(endpoint);
                    this.allSvgList = res.data;
                    this.svgList = res.data;
                    this.searchedSvg = '';
                } catch (err) {
                    this.$toasted.error('Failed to fetch SVG List');
                }
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.$refs.upload.value = '';
                this.functionInfo.image = '';
                this.svgImg = '';
                this.isSvgPresent = false;
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG', 'svg'].includes(fileExtension)) {
                        this.$toasted.error('Only svg, png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }
                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    //HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImg = readerResult;

                        if (fileExtension === 'svg') {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, false);
                            this.isSvgPresent = true;
                        } else {
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.functionInfo.image = uploadImgResponse.data.s3_link;
                            this.isSvgPresent = false;
                        }
                        this.isModuleLogoUpdated = true;
                        this.$toasted.success('Module image uploaded successfully !');
                    } catch (e) {
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            chooseFromCompany() {
                this.showSelectSvgModal = true;
                this.searchedSvg = '';
                this.svgList = this.allSvgList;

                const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.functionInfo?.image);
                this.selectedSvg = selectedSvgData ?? {};
            },
            handleSearchedSVG() {
                const searchedText = this.searchedSvg?.toLowerCase();
                this.svgList = this.allSvgList.filter(
                    (item) =>
                        item.title.toLowerCase().includes(searchedText) ||
                        item.categories.toLowerCase().includes(searchedText)
                );
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async saveSvg() {
                if (!this.selectedSvg?.file_id) {
                    return this.$toasted.error('Please select any svg image');
                }

                this.loading = true;

                const img = await this.geturl(this.selectedSvg?.file_id);
                await this.urlToSvg(img, false);

                this.functionInfo.image = this.selectedSvg.file_id;
                this.showSelectSvgModal = false;
                this.isModuleLogoUpdated = true;
                this.loading = false;
            },
            async urlToSvg(url, fetchedImg = true) {
                const reader = new FileReader();
                let img = await fetch(url);
                let imgBlob = await img.blob();
                reader.readAsDataURL(imgBlob);

                reader.onload = () => {
                    const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                    this.svgImg = atob(base64Data);
                    this.svgElement = this.svgImg;
                    this.renderSvg(this.svgImg, false, fetchedImg);
                    this.isSvgPresent = true;
                };
            },
            base64ToSvg(base64String) {
                const base64Data = base64String.replace(/^data:image\/svg\+xml;base64,/, '');
                const svgXml = atob(base64Data);
                return svgXml;
            },
            renderSvg(svgXml, isColorChanged, fetchImage = false) {
                const primaryColor = this.functionInfo.primaryColor;
                const secondaryColor = this.functionInfo.secondaryColor;

                if (!primaryColor || !secondaryColor) {
                    return;
                }

                // Parse SVG XML string
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

                // Get the SVG element
                const svgElement = svgDoc.documentElement;

                if (fetchImage) {
                    this.svgImg = svgElement;
                    return;
                }

                const allPaths = svgElement.querySelectorAll('path');

                allPaths.forEach((path) => {
                    if (path.hasAttribute('fill')) {
                        let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                        let colorTypeAvailable = false;
                        if (isColorChanged) {
                            let colorType = path.getAttribute('id');
                            if (colorType === 'primaryColor') {
                                path.setAttribute('fill', primaryColor);
                                colorTypeAvailable = true;
                            } else if (colorType === 'secondaryColor') {
                                path.setAttribute('fill', secondaryColor);
                                colorTypeAvailable = true;
                            }
                        }
                        if (!colorTypeAvailable) {
                            if (
                                (color[0] === 'a' ||
                                    color[0] === 'b' ||
                                    color[0] === 'c' ||
                                    color[0] === 'd' ||
                                    color[0] === 'e' ||
                                    color[0] === 'f') &&
                                (color[2] === 'a' ||
                                    color[2] === 'b' ||
                                    color[2] === 'c' ||
                                    color[2] === 'd' ||
                                    color[2] === 'e' ||
                                    color[2] === 'f') &&
                                (color[4] === 'a' ||
                                    color[4] === 'b' ||
                                    color[4] === 'c' ||
                                    color[4] === 'd' ||
                                    color[4] === 'e' ||
                                    color[4] === 'f')
                            ) {
                                path.setAttribute('id', 'primaryColor');
                                path.setAttribute('fill', primaryColor);
                            } else {
                                path.setAttribute('id', 'secondaryColor');
                                path.setAttribute('fill', secondaryColor);
                            }
                        }
                    }
                });

                this.svgImg = svgElement;
            },
        },
        async created() {
            this.Position = this.position;

            if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1 && this.group === 'GSI') {
                this.Position = '1' + this.Position;
            }

            if (this.isHomeTemplate) {
                const { colorScheme } = this.pageData.page3;
                const { selectedOption } = colorScheme;
                this.colorScheme = {
                    ...colorScheme,
                    module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                };
                this.isCustosmizedThemeSelected = colorScheme.selectedOption == 'customized_colors';
                this.functionInfo.selectedSchemeOption = this.colorScheme.selectedOption;
                this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                this.functionInfo.color = colorScheme[selectedOption][2];
                this.functionInfo.textColor = colorScheme[selectedOption][3];
            }

            await this.getCategories();
            await this.fetchApplications();
            await this.getRssList();

            if (this.isHomeTemplate) {
                await this.getSvgList();
            }

            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .search-svg-icon {
        background: #f1f7ff;
        border-radius: 10px;
        border: 2px solid #b1d0ff;
    }

    .search-svg-icon input {
        box-shadow: none;
        background: transparent !important;
    }

    .search-svg-icon input:focus-visible,
    .search-svg-icon input:focus {
        outline: none;
    }

    .search-svg-icon input::placeholder {
        color: #919fad;
        font-size: 14px;
    }

    .color-box {
        width: 45%;
        border-radius: 10px;
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.12);
        /*box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);*/
    }

    .default-color {
        height: 1.93rem;
        width: 4.24rem;
        background-color: #efefef;
        border: 2px solid black;
    }

    .color-box-item {
        color: #ffffff;
        font-size: 12px;
        border: 2px solid black;
    }

    .active-bg {
        width: 50px !important;
        height: 50px !important;
        border: 2px solid lime;
        padding: 2px;
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .list-title {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
        text-transform: capitalize;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
    .btn-info,
    .btn-info:hover {
        border-radius: 10px;
        border: 2px solid #5155c2;
        color: #5155c2;
    }
</style>

<style>
    .education-feed-modal .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
