<template>
    <div class="rounded-5 w-100 my-5 d-flex gap-5 flex-column">
        <div class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Select Module For Application
            </h1>
            <div
                v-if="applicationFrame === 'mobex-health-plus-v1'"
                class="d-flex flex-wrap p-4 justify-content-between gap-2"
            >
                <MHPProfileFunctionCard
                    v-for="coreFunction in coreFunctionList"
                    :key="coreFunction.id"
                    :profileFunction="coreFunction"
                    @save="updateCoreFunction"
                    @onToggle="coreFunctionToggle($event)"
                />
            </div>
            <div v-else class="d-flex flex-wrap p-4" style="gap: 0.5em">
                <ProfileFunctionCard
                    v-for="coreFunction in coreFunctionList"
                    :key="coreFunction.id"
                    :profileFunction="coreFunction"
                    @onToggle="coreFunctionToggle($event)"
                />
            </div>
            <div class="d-flex justify-content-center" v-if="coreFunctionList.length">
                <hr style="width: 95%" />
            </div>
            <div class="d-grid p-4" v-if="genericModuleList.length">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <span class="app-launcher-label">Generic Modules</span>
                    </div>
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: 0.5em">
                    <ProfileFunctionCard
                        v-for="genericFunction in genericModuleList"
                        :key="genericFunction.id"
                        :profileFunction="genericFunction"
                        @onToggle="genericFunctionToggle($event)"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-center" v-if="genericModuleList.length">
                <hr style="width: 95%" />
            </div>
            <div class="d-grid p-4">
                <div class="d-flex justify-content-between align-items-end">
                    <div>
                        <label for="app-launcher" class="app-launcher-label">3rd Party app launcher</label>
                        <Toggle
                            id="app-launcher"
                            class="toggle-switch"
                            labelClass="switch"
                            v-model="openLauncherModal"
                            spanClass="slider"
                        />
                    </div>
                    <LauncherModal
                        v-if="openLauncherModal"
                        :open="openLauncherModal"
                        @close="close"
                        :coreFunctionList="thirdPartyApps"
                        @onSaveThirdPartyApplication="onSaveThirdPartyApplication"
                        @onDeleteThirdParty="onDeleteThirdParty"
                        :showSaveSetting="showSaveSetting"
                        :applicationFrame="applicationFrame"
                    />
                </div>
                <div class="d-flex flex-wrap py-4" style="gap: 0.5em">
                    <ProfileFunctionCard
                        v-for="thirdPartyApp in thirdPartyApps"
                        :key="thirdPartyApp.id"
                        :profileFunction="thirdPartyApp"
                        :coreFunctionList="thirdPartyApps"
                        @onToggle="thirdPartyFunctionToggle($event)"
                    />
                </div>
            </div>
            <div
                v-if="['mobex-health-hub', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)"
                class="d-grid p-4"
            >
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-flex justify-content-between flex-column">
                    <div>
                        <label for="bidirectionalMessaging" class="app-launcher-label"
                            >Allow Bidirectional Message</label
                        >
                        <Toggle
                            id="bidirectionalMessaging"
                            class="toggle-switch"
                            labelClass="switch"
                            spanClass="slider"
                            v-model="bidirectionalMessaging"
                            @input="handleBiDirectionalMessaging"
                        />
                    </div>
                    <div v-if="bidirectionalMessaging" class="w-75">
                        <label for="disclaimer" class="my-2">Disclaimer message</label>
                        <TextArea
                            id="disclaimer"
                            class="my-2"
                            type="text"
                            name="disclaimer"
                            label=""
                            :placeholder="'Enter disclaimer message'"
                            v-model="bidirectionalDisclaimer"
                            @input="handleBiDirectionalDisclaimer"
                        />
                    </div>
                </div>
            </div>
            <div v-if="applicationFrame === 'kiosk'">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-grid p-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <label for="enableQR" class="app-launcher-label">App Download Button</label>
                            <Toggle
                                id="enableQR"
                                class="toggle-switch"
                                labelClass="switch"
                                spanClass="slider"
                                v-model="enableQR"
                                @input="enableQRButtonToggle"
                            />
                            <div v-if="enableQR" class="p-1">
                                <Toggle
                                    id="showQR"
                                    class="toggle-switch"
                                    labelClass="switch"
                                    spanClass="slider"
                                    v-model="showQR"
                                    @input="showQRButtonToggle"
                                />
                                <label for="showQR" class="p-1" style="color: rgb(160, 160, 160); font-weight: 600"
                                    >Show QR on screen saver</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="applicationFrame === 'kiosk' || applicationFrame === 'star-kid'">
                <div class="d-flex justify-content-center">
                    <hr style="width: 95%" />
                </div>
                <div class="d-grid p-4">
                    <div class="d-flex justify-content-between align-items-end">
                        <div>
                            <label
                                for="isMaster"
                                class="app-launcher-label"
                                :style="{ cursor: disableMasterButton || isMasterExist ? 'not-allowed' : '' }"
                                >Master Dashboard</label
                            >
                            <Toggle
                                id="isMaster"
                                class="toggle-switch"
                                labelClass="switch"
                                v-model="isMaster"
                                spanClass="slider"
                                @input="handleMasterDashboard"
                                :disabled="!!(disableMasterButton || isMasterExist)"
                            />
                        </div>
                    </div>
                    <span v-if="isMasterExist" class="p-1" style="color: rgb(160, 160, 160); font-weight: 600"
                        >Master Dashboard already exist for selected application</span
                    >
                    <span
                        v-else-if="disableMasterButton || isMasterExist"
                        class="p-1"
                        style="color: rgb(160, 160, 160); font-weight: 600"
                        >Master dashboard can only be set while creating a dashboard</span
                    >
                </div>
            </div>
            <span class="m-3" style="color: red; font-weight: bold">{{ errors.modules }}</span>
            <div class="third-party-app-delete-modal">
                <AlertDeleteThirdPartyApp
                    v-if="showThirdPartyDeleteAlert"
                    :open="showThirdPartyDeleteAlert"
                    @cancel="
                        showThirdPartyDeleteAlert = false;
                        openLauncherModal = true;
                    "
                    @handleDelete="handleThirdPartyDelete"
                >
                </AlertDeleteThirdPartyApp>
            </div>
        </div>
        <div v-if="applicationFrame === 'mobex-health-plus-v1'" class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading d-flex justify-content-between"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Select Project Tags
            </h1>
            <div class="d-flex gap-2 m-2">
                <div class="w-30 p-4 d-flex align-items-center">
                    <Multiselect
                        id="clientDemographicsChosenTheme"
                        track-by="name"
                        label="name"
                        placeholder="Select Project Tags"
                        :options="tagsList"
                        :searchable="true"
                        :show-labels="false"
                        @input="handleTags($event)"
                    />
                </div>
                <div class="d-flex flex-wrap gap-2 p-4">
                    <span
                        v-for="(tag, index) in tags"
                        :key="index"
                        class="d-flex align-items-center gap-3 cursor-pointer py-2 px-4"
                        style="
                            background: #ebeff4;
                            border-radius: 20px;
                            font-size: 15px !important;
                            width: fit-content;
                            text-transform: capitalize;
                        "
                    >
                        <ejs-tooltip
                            class="tooltipcontainer"
                            :content="tag.description ? tag.description : 'No Description Added'"
                            target="#tag"
                            cssClass="customtooltip"
                            ><span id="tag" style="font-size: 15px !important">{{ tag.name }}</span>
                        </ejs-tooltip>
                        <ejs-tooltip
                            class="tooltipcontainer"
                            content="Remove"
                            target="#remove"
                            cssClass="customtooltip"
                        >
                            <img
                                id="remove"
                                src="@/assets/px/cancel.svg"
                                alt=""
                                style="height: 20px"
                                @click="removeTags(tag)"
                            />
                        </ejs-tooltip>
                    </span>
                </div>
            </div>
        </div>
        <template v-if="applicationFrame === 'mobex-health-plus-v1'">
            <div class="bg-white w-100 rounded-4 border">
                <h1
                    class="px-5 py-3 page-heading d-flex justify-content-between"
                    style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
                >
                    Customize Sign up fields
                    <button type="button" class="text-button btn-save" @click="showSignupEditModal = true">
                        Add Fields
                    </button>
                </h1>
                <div class="d-flex flex-wrap p-4" style="gap: 0.5em">
                    <SignupFieldCard
                        v-for="signupField in signupFieldList"
                        :key="signupField.id"
                        :profileFunction="signupField"
                        @onToggle="signupFieldToggle($event)"
                        @handleSignupField="handleSignupField"
                    />
                </div>
                <EditSignupFieldModal
                    v-if="showSignupEditModal"
                    @save="handleSignupField"
                    @cancel="showSignupEditModal = false"
                />
            </div>
            <div class="bg-white w-100 rounded-4 border">
                <h1
                    class="px-5 py-3 page-heading d-flex justify-content-between"
                    style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
                >
                    Product Intro Slides
                    <button type="button" class="text-button btn-save" @click="showIntroSlidesEditModal = true">
                        Add Slides
                    </button>
                </h1>
                <div class="d-flex flex-column p-4" style="gap: 0.5em">
                    <div
                        v-if="introSlideList.length === 0"
                        class="fs-4 text-bolder d-flex align-items-center justify-content-center"
                        style="min-height: 100px"
                    >
                        No items added
                    </div>
                    <IntroSlideCard
                        v-for="productSlide in introSlideList"
                        :key="productSlide.id"
                        :profileFunction="productSlide"
                        @handleIntroSlide="handleIntroSlide"
                        @onToggle="introSlideToggle($event)"
                        @handleDelete="handleDeleteIntroSlide"
                    />
                </div>
                <EditIntroSlideModal
                    v-if="showIntroSlidesEditModal"
                    @save="handleIntroSlide"
                    @cancel="showIntroSlidesEditModal = false"
                />
            </div>
        </template>
        <Loading v-if="loading" />
    </div>
</template>
<script>
    import ProfileFunctionCard from '../../components/company/member-profiles/ProfileFunctionCard.vue';
    import MHPProfileFunctionCard from '../../components/company/member-profiles/MHPProfileFunctionCard.vue';
    import SignupFieldCard from '../../components/company/member-profiles/SignupFieldCard.vue';
    import EditSignupFieldModal from '../../components/company/member-profiles/EditSignupFieldModal.vue';
    import LauncherModal from '../../components/company/member-profiles/LauncherModal.vue';
    import AlertDeleteThirdPartyApp from '../../components/company/member-profiles/DeleteThirdPartyAlert.vue';
    import IntroSlideCard from '../../components/company/member-profiles/IntroSlideCard.vue';
    import EditIntroSlideModal from '../../components/company/member-profiles/EditIntroSlideModal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { memberProfile, dashboardBuilder, companies, starkidsDashboard } from '@/util/apiRequests';

    const initialSignupFields = [
        {
            id: 1,
            label_name: 'Guardian Name',
            is_required: true,
            is_active: true,
        },
        {
            id: 2,
            label_name: 'First & Last Name',
            is_required: true,
            is_active: true,
        },
        {
            id: 3,
            label_name: 'Full Name',
            is_required: true,
            is_active: true,
        },
        {
            id: 4,
            label_name: 'Email Address ',
            is_required: true,
            is_active: true,
        },
        {
            id: 5,
            label_name: 'Phone Number',
            is_required: true,
            is_active: true,
        },
        {
            id: 6,
            label_name: 'Date of Birth',
            is_required: true,
            is_active: true,
        },
        {
            id: 7,
            label_name: 'Language',
            is_required: true,
            is_active: true,
        },
    ];

    export default {
        name: 'SelectModules',
        props: {
            errors: {
                type: Object,
            },
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
            },
            current_template: {
                type: Number,
            },
            dashboardId: {
                type: Number,
            },
        },
        components: {
            ProfileFunctionCard,
            MHPProfileFunctionCard,
            SignupFieldCard,
            EditSignupFieldModal,
            IntroSlideCard,
            EditIntroSlideModal,
            AlertDeleteThirdPartyApp,
            LauncherModal,
            Loading,
        },
        computed: {
            disableMasterButton() {
                return this.$route?.query?.status !== 'draft';
            },
        },
        data() {
            return {
                coreFunctionList: [],
                genericModuleList: [],
                openLauncherModal: false,
                showSaveSetting: false,
                thirdPartyApps: [],
                showThirdPartyDeleteAlert: false,
                thirdPartyFunctionIdToDelete: null,
                enableQR: false,
                showQR: false,
                loading: true,
                isMaster: false,
                isMasterExist: false,
                bidirectionalMessaging: false,
                bidirectionalDisclaimer: '',
                showSignupEditModal: false,
                showIntroSlidesEditModal: false,
                tagsList: [],
                tags: [],
                signupFieldList: [...initialSignupFields],
                introSlideList: [],
                deletedSlideIds: [],
            };
        },
        methods: {
            async getCoreFunctions() {
                try {
                    let endpoint = memberProfile.getFunction('core', this.applicationFrame);

                    // Not in use for now
                    // if (this.applicationFrame === 'mobex-health-plus-v1' && this.$route.query.status === 'draft') {
                    //     endpoint = memberProfile.getFunction('core', this.applicationFrame, this.dashboardId);
                    // }

                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    if (!fetchFunctionResponse.data.success) {
                        throw fetchFunctionResponse.data.error;
                    }

                    let { data } = fetchFunctionResponse.data;

                    this.coreFunctionList = data.map((el) => {
                        el.active = el.by_default_selected ? 1 : 0;
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    if (this.applicationFrame == 'mobex-health-hub') {
                        let MHH_new_template_modules = [
                            'Scheduling',
                            'Care Team',
                            'Games',
                            'Medication',
                            'Web Links',
                            'Content & Education',
                            "Let's Connect",
                        ];
                        if (this.current_template == 1) {
                            this.coreFunctionList = this.coreFunctionList.filter(
                                (item) => item.function_name != 'Games'
                            );
                        } else {
                            this.coreFunctionList = this.coreFunctionList.filter((item) =>
                                MHH_new_template_modules.includes(item.function_name)
                            );
                        }
                    }
                    return true;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to get tablet functions !';
                    this.$toasted.error(errorMesage);
                }
            },
            async getGenericWidgets() {
                try {
                    const endpoint = memberProfile.getFunction('generic', this.applicationFrame);
                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    if (!fetchFunctionResponse.data.success) {
                        throw fetchFunctionResponse.data.error;
                    }

                    let { data } = fetchFunctionResponse.data;
                    this.genericModuleList = data.map((el) => {
                        el.active = el.by_default_selected ? 1 : 0;
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    this.genericModuleList.forEach((item) => {
                        if (item.function_name == 'Video Link') {
                            item.name = 'Video';
                        } else if (item.function_name == 'PDF Link') {
                            item.name = 'PDF';
                        } else if (item.function_name == 'Dashboard Page') {
                            item.name = '3 Layer Module';
                        } else {
                            item.name = item.function_name;
                        }
                    });

                    if (this.applicationFrame == 'mobex-health-hub') {
                        if (this.current_template == 1 || this.current_template == 4) {
                            this.genericModuleList = this.genericModuleList.filter(
                                (el) => el.function_name != 'Call Button'
                            );
                        }
                    }
                    return true;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to get tablet functions !';
                    this.$toasted.error(errorMesage);
                }
            },
            async getTagList() {
                try {
                    let res = await this.$api.get(companies.getTags(this.$store.state.user.company_id));
                    if (res.data.success) {
                        this.tagsList = res.data.info;
                    }
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Project Tags !';
                    this.$toasted.error(errorMesage);
                }
            },
            async getSignupFields() {
                try {
                    let res = await this.$api.get(starkidsDashboard.getSignupField(this.dashboardId));
                    if (res.data.success) {
                        this.signupFieldList = res.data.message;
                    }
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch signup fields !';
                    this.$toasted.error(errorMesage);
                }
            },
            async getSlides() {
                try {
                    let res = await this.$api.get(starkidsDashboard.getSlide(this.dashboardId));
                    if (res.data.success) {
                        this.introSlideList = res.data.message;
                    }
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Slides !';
                    this.$toasted.error(errorMesage);
                }
            },
            coreFunctionToggle(event) {
                this.coreFunctionList = this.coreFunctionList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.active = event.value ? 1 : 0;
                        // if (this.applicationFrame === 'mobex-health-plus-v1') {
                        //     el.isUpdated = true;
                        // }
                    }
                    return el;
                });

                let _coreFunctionList = this.coreFunctionList;
                if (this.applicationFrame !== 'mobex-health-plus-v1') {
                    _coreFunctionList = this.coreFunctionList.filter((el) => el.active);
                }
                this.$emit('captureValues', { page2: { coreFunctions: _coreFunctionList } });
            },
            updateCoreFunction(data) {
                this.coreFunctionList = this.coreFunctionList.map((item) =>
                    item.function_name === data.function_name ? { ...item, ...data, isUpdated: true } : item
                );

                this.$emit('captureValues', { page2: { coreFunctions: this.coreFunctionList } });
            },
            genericFunctionToggle(event) {
                this.genericModuleList = this.genericModuleList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.active = event.value ? 1 : 0;
                    }
                    return el;
                });
                let _genericFunctionList = this.genericModuleList.filter((el) => el.active);
                this.$emit('captureValues', { page2: { genericFunctions: _genericFunctionList } });
            },
            onSaveThirdPartyApplication(selectedThirdPartyApplications) {
                this.thirdPartyApps = selectedThirdPartyApplications
                    .filter((el) => el.active == 1)
                    .map((el) => {
                        delete el.created_at;
                        delete el.deleted;
                        delete el.updated_at;
                        return el;
                    });
                this.thirdPartyApps;
                this.$emit('captureValues', { page2: { thirdPartyFunctions: this.thirdPartyApps } });
            },
            thirdPartyFunctionToggle(event) {
                let index = this.thirdPartyApps.findIndex((el) => el.id == event.profileFunction.id);
                this.thirdPartyApps.splice(index, 1);
                this.$emit('captureValues', { page2: { thirdPartyFunctions: this.thirdPartyApps } });
            },
            onDeleteThirdParty(thirdPartyFunctionId) {
                this.openLauncherModal = false;
                this.showThirdPartyDeleteAlert = true;
                this.thirdPartyFunctionIdToDelete = thirdPartyFunctionId;
            },
            close() {
                this.openLauncherModal = false;
            },
            async handleThirdPartyDelete() {
                try {
                    this.showThirdPartyDeleteAlert = false;
                    this.showLoader = true;

                    // HTTP call for delete third party application
                    const endpoint = memberProfile.deleteFunction(this.thirdPartyFunctionIdToDelete);
                    const deleteThirdPartyAppResponse = await this.$api.delete(endpoint);
                    if (!deleteThirdPartyAppResponse.data.success) {
                        throw deleteThirdPartyAppResponse.data.Message;
                    }

                    this.$toasted.success('Application Deleted successfully !');
                } catch (e) {
                    const errorMessage = e ? e : 'Failed to delete application !';
                    this.$toasted.error(errorMessage);
                } finally {
                    this.showLoader = false;
                    this.openLauncherModal = true;
                }
            },
            enableQRButtonToggle() {
                this.showQR = false;
                this.$emit('captureValues', {
                    page2: { enableQR: this.enableQR === true ? 1 : 0, showQR: 0 },
                });
            },
            showQRButtonToggle() {
                this.$emit('captureValues', {
                    page2: { showQR: this.showQR === true ? 1 : 0 },
                });
            },
            async checkMasterDashboardAvailable() {
                const endpoint = dashboardBuilder.isMasterExist();
                const response = await this.$api.post(endpoint, { application: this.applicationFrame });
                this.isMasterExist = response?.data?.masterExists;
            },
            handleMasterDashboard(value) {
                this.$emit('captureValues', { page2: { isMaster: value ? 1 : 0 } });
            },
            handleBiDirectionalMessaging() {
                if (this.bidirectionalMessaging) {
                    this.bidirectionalDisclaimer =
                        'Messages sent to Provider will be responded to within 2 business days. For urgent assistance, please contact provider directly by phone';
                }
                this.$emit('captureValues', {
                    page2: {
                        bidirectional_messaging: this.bidirectionalMessaging === true ? 1 : 0,
                        bidirectionalDisclaimer: this.bidirectionalMessaging ? this.bidirectionalDisclaimer : '',
                    },
                });
            },
            handleBiDirectionalDisclaimer() {
                this.$emit('captureValues', {
                    page2: { bidirectionalDisclaimer: this.bidirectionalDisclaimer },
                });
            },
            handleTags(value) {
                this.tags.push(value);
                this.tagsList = this.tagsList.filter((tag) => tag.id != value.id);
                this.$emit('captureValues', {
                    page2: { tags: this.tags },
                });
            },
            removeTags(value) {
                this.tagsList.push(value);
                this.tags = this.tags.filter((tag) => tag.id != value.id);
                this.$emit('captureValues', {
                    page2: { tags: this.tags },
                });
            },
            signupFieldToggle(event) {
                this.signupFieldList = this.signupFieldList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.is_active = event.value ? 1 : 0;
                        el.isUpdated = true;
                    }
                    return el;
                });

                this.$emit('captureValues', { page2: { signupFieldList: this.signupFieldList } });
            },
            handleSignupField(data) {
                if (data.id) {
                    this.signupFieldList = this.signupFieldList.map((el) => {
                        if (data.id === el.id) {
                            data.isUpdated = true;
                            return data;
                        }
                        return el;
                    });
                } else {
                    const isFieldAlreadyPresent = this.signupFieldList.find(
                        (el) => el?.label_name?.trim().toLowerCase() === data?.label_name?.trim().toLowerCase()
                    );
                    if (isFieldAlreadyPresent) {
                        this.$toasted.error('Field with same name already exists');
                        return;
                    }
                    this.signupFieldList.push({ ...data, id: this.signupFieldList.length + 1, isCreated: true });
                }

                this.showSignupEditModal = false;
                this.$emit('captureValues', { page2: { signupFieldList: this.signupFieldList } });
            },
            introSlideToggle(event) {
                this.introSlideList = this.introSlideList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.is_active = event.value ? 1 : 0;
                        el.isUpdated = true;
                    }
                    return el;
                });

                this.$emit('captureValues', { page2: { introSlideList: this.introSlideList } });
            },
            handleIntroSlide(data) {
                if (data.id) {
                    this.introSlideList = this.introSlideList.map((el) => {
                        if (data.id === el.id) {
                            data.isUpdated = true;
                            return data;
                        }
                        return el;
                    });
                } else {
                    this.introSlideList.push({ ...data, id: this.introSlideList.length + 1, isCreated: true });
                }

                this.showIntroSlidesEditModal = false;
                this.$emit('captureValues', { page2: { introSlideList: this.introSlideList } });
            },
            handleDeleteIntroSlide(data) {
                this.introSlideList = this.introSlideList.filter((el) => data.id !== el.id);
                if (!data.isCreated) {
                    this.deletedSlideIds.push(data.id);
                }
                this.$emit('captureValues', {
                    page2: { introSlideList: this.introSlideList, deletedSlideIds: this.deletedSlideIds },
                });
            },
        },
        async created() {
            window.scrollTo(0, 0);

            await this.getCoreFunctions();
            await this.getGenericWidgets();

            // if (this.applicationFrame === 'mobex-health-plus-v1') {
            //     await this.getTagList();
            // }

            if (this.pageData.page2) {
                if (this.pageData.page2.coreFunctions) {
                    // if (this.applicationFrame === 'mobex-health-plus-v1') {
                    //     this.coreFunctionList = this.coreFunctionList.map((el) => {
                    //         const functionPresent = this.pageData.page2.coreFunctions.find(
                    //             (el1) => el1.function_name === el.function_name
                    //         );
                    //         if (functionPresent) {
                    //             return { ...el, ...functionPresent };
                    //         } else {
                    //             el.active = 0;
                    //         }
                    //         return el;
                    //     });
                    //     this.$emit('captureValues', {
                    //         page2: {
                    //             coreFunctions: this.coreFunctionList,
                    //         },
                    //     });
                    // } else {
                    let coreFunctionIds = this.pageData.page2.coreFunctions.map((el) => el.id);
                    this.coreFunctionList = this.coreFunctionList.map((el) => {
                        if (coreFunctionIds.includes(el.id)) {
                            el.active = 1;
                        } else {
                            el.active = 0;
                        }
                        return el;
                    });
                    // }
                }
                if (this.pageData.page2.genericFunctions) {
                    if (this.applicationFrame == 'mobex-health-hub') {
                        if (this.current_template == 1 || this.current_template == 4) {
                            this.pageData.page2.genericFunctions = this.pageData.page2.genericFunctions.filter(
                                (el) => el.function_name != 'Call Button'
                            );
                        }
                    }

                    let genericFunctionIds = this.pageData.page2.genericFunctions.map((el) => el.id);
                    this.genericModuleList = this.genericModuleList.map((el) => {
                        if (genericFunctionIds.includes(el.id)) {
                            el.active = 1;
                        } else {
                            el.active = 0;
                        }
                        return el;
                    });
                }
                if (this.pageData.page2.thirdPartyFunctions) {
                    this.thirdPartyApps = this.pageData.page2.thirdPartyFunctions;
                }

                // if (this.applicationFrame === 'mobex-health-plus-v1') {
                //     if (this.pageData.page2.coreFunctions) {
                //         this.coreFunctionList = this.pageData.page2.coreFunctions;
                //     }
                //     if (this.pageData.page2.signupFieldList) {
                //         await this.getSignupFields();
                //         this.signupFieldList = this.pageData.page2.signupFieldList;
                //     }
                //     if (this.pageData.page2.introSlideList) {
                //         await this.getSlides();
                //         this.introSlideList = this.pageData.page2.introSlideList;
                //     }

                //     this.deletedSlideIds = this.pageData?.page2?.deletedSlideIds ?? [];
                //     this.tags = this.pageData.page2.tags ?? [];
                //     this.tagsList = this.tagsList.filter(
                //         (item1) => !this.tags.some((item2) => item2.name == item1.name)
                //     );
                // }

                this.enableQR = this.pageData?.page2?.enableQR === 1 ? true : false;
                this.showQR = this.pageData?.page2?.showQR === 1 ? true : false;
                this.bidirectionalMessaging = this.pageData?.page2?.bidirectional_messaging ?? false;
                this.bidirectionalDisclaimer = this.pageData?.page2?.bidirectionalDisclaimer ?? '';
                this.isMaster = this.pageData?.page2?.isMaster ?? false;
            } else {
                if (['kiosk', 'star-kid'].includes(this.applicationFrame)) {
                    await this.checkMasterDashboardAvailable();
                }

                this.$emit('captureValues', {
                    page2: {
                        coreFunctions: this.coreFunctionList.filter((el) => el.by_default_selected),
                        genericFunctions: this.genericModuleList.filter((el) => el.by_default_selected),
                        signupFieldList: this.signupFieldList,
                    },
                });
            }

            this.loading = false;
        },
    };
</script>

<style scoped>
    .btn-save {
        border-radius: 10px;
        font-weight: 600;
        width: 8rem;
        background: transparent;
        color: #5155c3;
        border: 2px solid #5155c3;
    }
</style>
