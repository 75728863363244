<template>
    <NoButtonModal :open="open" id="the_new_client_dropdown_modal">
        <!-- first modal -->
        <div class="modal-list-third-party-app">
            <div
                v-if="!showLinkForm"
                class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
            >
                <div v-if="isEditScreen" class="d-flex justify-content-between align-items-center">
                    <span class="label-app-list-header">Edit Module</span>
                </div>

                <div v-else class="d-flex justify-content-between align-items-center">
                    <div v-if="!showAppListing" @click="showPage('FUNCTION_LISTING')">
                        <button class="text-button no-bg pointer">
                            <span class="material-icons-outlined">arrow_back</span>
                        </button>
                    </div>
                    <span v-if="showSelectSvgModal" class="label-app-list-header">Select SVG</span>
                    <span v-if="!showSelectSvgModal" class="label-app-list-header">Add New Module</span>
                </div>

                <div
                    v-if="!showSelectSvgModal && clickedProfileFunction == 'Dashboard Page' && !isEditSubModule"
                    class="d-flex justify-content-between align-items-center"
                >
                    <div @click="showPage('ADD_SUBMODULE')">
                        <button
                            v-if="isEditScreen"
                            type="button"
                            class="no-bg text-button btn-add-new-app"
                            style="margin-right: 2rem"
                        >
                            Add Sub Module
                        </button>
                    </div>
                </div>
                <div v-if="showSelectSvgModal" class="d-flex justify-content-between align-items-center">
                    <span class="d-flex search-svg-icon mx-4 my-2">
                        <input
                            type="text"
                            placeholder="Find icon here"
                            v-model="searchedSvg"
                            @input="handleSearchedSVG"
                        />
                        <img
                            src="@/assets/px/drag-and-drop/search-icon.svg"
                            alt=""
                            class="img mx-2"
                            style="width: 20px"
                        />
                    </span>
                </div>
            </div>
            <div class="modal-list-third-party-app" v-if="showLinkForm">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <div @click="backToSubModule">
                            <button class="text-button no-bg pointer">
                                <span class="material-icons-outlined">arrow_back</span>
                            </button>
                        </div>
                        <span class="label-app-list-header">Add link in Sub-Module</span>
                    </div>
                </div>
            </div>
            <template v-if="!showSelectSvgModal && !showLoader && !isEditSubModule && !showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="subModulesList.length > 0">
                        <draggable :list="subModulesList">
                            <div
                                v-for="subModule in subModulesList"
                                :key="subModule.id"
                                class="d-flex justify-content-between align-items-center"
                            >
                                <ejs-tooltip
                                    v-if="subModulesList.length > 1"
                                    class="tooltipcontainer"
                                    content="Reorder"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                                </ejs-tooltip>
                                <span
                                    class="label-app-list-header"
                                    :class="showAddSubModuleLinkIcon(subModule) ? ' w-70' : ' w-75'"
                                    style="
                                        text-overflow: ellipsis;
                                        text-wrap: nowrap;
                                        overflow: hidden;
                                        text-transform: capitalize;
                                    "
                                    >{{ subModule.title }}</span
                                >
                                <div class="d-flex">
                                    <ejs-tooltip
                                        v-if="showAddSubModuleLinkIcon(subModule)"
                                        class="tooltipcontainer"
                                        content="Add Link"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-edit me-2"
                                            role="button"
                                            @click="addSubModuleLink(subModule.id)"
                                        >
                                            add_link
                                        </span>
                                    </ejs-tooltip>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Edit"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-edit me-2"
                                            role="button"
                                            @click="editSubModule(subModule.id)"
                                        >
                                            edit_square
                                        </span>
                                    </ejs-tooltip>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-delete"
                                            role="button"
                                            @click="deleteSubModule(subModule.id)"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </draggable>
                    </template>
                </div>
            </template>
            <template v-if="!showLoader && !showMainForm && showLinkForm">
                <div class="section-body content-checkbox-list">
                    <template v-if="subModuleLinksList.length > 0">
                        <draggable :list="subModuleLinksList">
                            <div
                                v-for="subModulelink in subModuleLinksList"
                                :key="subModulelink.id"
                                class="d-flex justify-content-between align-items-center"
                            >
                                <ejs-tooltip
                                    v-if="subModuleLinksList.length > 1"
                                    class="tooltipcontainer"
                                    content="Reorder"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                                </ejs-tooltip>
                                <span
                                    class="w-75 label-app-list-header"
                                    style="
                                        text-overflow: ellipsis;
                                        text-wrap: nowrap;
                                        overflow: hidden;
                                        text-transform: capitalize;
                                    "
                                    >{{ subModulelink.title }}</span
                                >
                                <div class="d-flex">
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Edit"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-edit me-2"
                                            role="button"
                                            @click="editSubModuleLink(subModulelink.id)"
                                        >
                                            edit_square
                                        </span>
                                    </ejs-tooltip>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="BottomRight"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined icon-delete"
                                            role="button"
                                            @click="deleteSubModuleLink(subModulelink.id)"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </draggable>
                    </template>
                </div>
            </template>
            <hr v-if="!showSelectSvgModal && !showLoader && subModulesList.length && !isEditSubModule" />
        </div>
        <!-- second modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showMainForm && !showSelectSvgModal">
                        <div class="section-body add-new-app-form mx-0">
                            <div
                                v-if="clickedProfileFunction !== 'Mood Tracker' || isHomeMoodTracker"
                                class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3"
                            >
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>

                                <div class="d-flex align-items-center w-100 gap-1">
                                    <button
                                        type="button"
                                        :class="
                                            (['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(
                                                applicationFrame
                                            )
                                                ? 'w-40'
                                                : 'w-100') + ' no-bg text-button btn-add-new-app'
                                        "
                                        @click="chooseFile"
                                    >
                                        Choose file
                                    </button>
                                    <button
                                        v-if="
                                            ['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(
                                                applicationFrame
                                            )
                                        "
                                        type="button"
                                        class="no-bg text-button btn-add-new-app w-40"
                                        @click="chooseFromCompany"
                                    >
                                        Choose icon
                                    </button>
                                    <!-- <span v-if="isFileAvailable" class="errorClass">Please upload file</span> -->
                                    <input
                                        id="fileid"
                                        type="file"
                                        name="upload"
                                        ref="upload"
                                        size="100000"
                                        @change="uploadFile"
                                        hidden
                                        required
                                        :accept="acceptedFileTypes"
                                    />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <img
                                            v-if="!uploadedImg && !showImgLoader && !isSvgPresent"
                                            src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                        />

                                        <img
                                            v-if="!showImgLoader && isSvgPresent"
                                            :src="svgToBase64"
                                            alt="image not found"
                                        />
                                        <img
                                            v-if="uploadedImg && !showImgLoader && !isSvgPresent"
                                            :src="uploadedImg"
                                            alt="image not uploaded"
                                        />

                                        <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main"
                                            >
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip
                                            v-if="uploadedImg || svgImg"
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="top"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined img-delete-small"
                                                role="button"
                                                @click="resetFileUpload()"
                                            >
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="
                                    !['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame) &&
                                    clickedProfileFunction !== 'Mood Tracker'
                                "
                                class="scroll-wrapper"
                            >
                                <div style="gap: 25px" class="scroll-container">
                                    <div v-for="bg in genericModulePhotos" :key="bg.image">
                                        <input type="radio" hidden />
                                        <label>
                                            <img
                                                :src="bg.image"
                                                style="width: 50px; height: 50px; max-width: 100px; cursor: pointer"
                                                :class="bg.file_id == backgroundPhoto.file_id ? 'active-bg' : ''"
                                                @click="selectBackground(bg)"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    !['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame) &&
                                    clickedProfileFunction !== 'Mood Tracker'
                                "
                                class="mx-5"
                            >
                                <div class="mb-4 d-flex w-100">
                                    <Input
                                        label="Select Button Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-color"
                                        @change="selectCustomColor($event)"
                                        v-model="functionInfo.color"
                                    />

                                    <Input
                                        label="Select Text Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-text-color"
                                        @change="selectCustomTextColor($event)"
                                        v-model="functionInfo.textColor"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    applicationFrame === 'kiosk' ||
                                    (isHomeTemplate && (clickedProfileFunction !== 'Mood Tracker' || isHomeMoodTracker))
                                "
                                class="d-flex flex-wrap px-4 my-4"
                                style="flex-direction: column; gap: 5px"
                            >
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="primary_colors"
                                                value="primary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'main-module')"
                                            />
                                            <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.primary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="secondary_colors"
                                                value="secondary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'main-module')"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Secondary Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.secondary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="customized_colors"
                                                value="customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'main-module')"
                                            />
                                            <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="module_customized_colors"
                                                value="module_customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="functionInfo.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'main-module')"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Custom Module Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.module_customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                            >
                                                <input
                                                    v-if="
                                                        functionInfo.selectedSchemeOption == 'module_customized_colors'
                                                    "
                                                    type="color"
                                                    name=""
                                                    id=""
                                                    v-model="colorScheme.module_customized_colors[index]"
                                                    @change="selectCustomColorScheme($event, index, 'main-module')"
                                                    class="w-100 p-0"
                                                />
                                                <div v-else class="default-color"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="clickedProfileFunction != 'Lumeca'" class="mx-5 px-3">
                                <Input
                                    label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="title"
                                    placeholder="Enter Title"
                                    v-model="functionInfo.title"
                                    required
                                />

                                <span
                                    v-if="clickedProfileFunction == 'Dashboard'"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                >
                                    <label
                                        class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end"
                                        >Select Company*</label
                                    >
                                    <Multiselect
                                        id="company"
                                        track-by="id"
                                        label="embeddedCompanyName"
                                        class="position-relative w-70"
                                        placeholder="Select Company"
                                        v-model="companyInfo"
                                        :options="companyList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="handleCompanySelection($event)"
                                    />
                                </span>

                                <span
                                    v-if="clickedProfileFunction == 'Dashboard'"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                >
                                    <label
                                        class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end"
                                        >Select Dashboard*</label
                                    >
                                    <Multiselect
                                        :options="companyDashboardList"
                                        class="position-relative w-70"
                                        :allow-empty="false"
                                        :searchable="true"
                                        :show-labels="false"
                                        placeholder="Select Dashboard"
                                        label="embeddedDashboardName"
                                        track-by="id"
                                        v-model="dashboardInfo"
                                        :disabled="!functionInfo.embeddedCompanyName"
                                        @input="handleDashboardSelection($event)"
                                    />
                                </span>

                                <div v-if="['PDF Link', 'Video Link', 'Web Link'].includes(clickedProfileFunction)">
                                    <span
                                        v-if="urlDropdownList.length > 1"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >URL Type*</label
                                        >
                                        <Multiselect
                                            id="fileType"
                                            track-by="type"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Select type"
                                            v-model="urlTypeInfo"
                                            :options="urlDropdownList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="handleUrlType($event)"
                                        />
                                    </span>
                                    <span
                                        v-if="urlTypeInfo.type === 'existingFiles'"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select File*</label
                                        >
                                        <Multiselect
                                            id="document"
                                            track-by="id"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Search or Select File"
                                            v-model="documentInfo"
                                            :options="selectedDocumentList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="functionInfo.document_id = $event.id"
                                        />
                                    </span>
                                    <span
                                        v-else-if="urlTypeInfo.type === 'lookupTable'"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Table*</label
                                        >
                                        <Multiselect
                                            id="document"
                                            track-by="id"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Search or Select Table"
                                            v-model="lookupTableInfo"
                                            :options="lookupTableList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="functionInfo.lookup_table_id = $event.id"
                                        />
                                    </span>
                                    <Input
                                        v-else
                                        label="URL"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                        name="URL"
                                        placeholder="Enter URL"
                                        v-model="functionInfo.url"
                                        required
                                    />
                                </div>

                                <span class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label
                                        for="category"
                                        class="me-3 d-flex add-new-app-label justify-content-end"
                                        style="width: 28%"
                                        >Category</label
                                    >
                                    <Multiselect
                                        id="category"
                                        track-by="id"
                                        label="title"
                                        class="w-70"
                                        placeholder="Search or Select Category"
                                        v-model="categoryInfo"
                                        :options="categoryList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="functionInfo.module_category = $event.id"
                                    />
                                </span>

                                <div v-if="clickedProfileFunction == 'Call Button'" class="lumeca-form">
                                    <Input
                                        label="Phone number"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="number"
                                        class="w-100 field-container d-flex align-items-center mb-4"
                                        name="Phone number"
                                        placeholder="Enter Phone number"
                                        rules="mobile"
                                        v-model="phoneNumber"
                                        required
                                    />

                                    <span class="d-flex align-items-center my-4">
                                        <input
                                            type="checkbox"
                                            id="enableAudio"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="functionInfo.isAudioEnabled"
                                            @input="handleAudio($event, 'main-module')"
                                        />
                                        <label
                                            class="margin-0 font-16 d-flex align-items-center fw-semibold"
                                            for="enableAudio"
                                            >Enable Audio Ring</label
                                        >
                                    </span>

                                    <template v-if="functionInfo.isAudioEnabled"
                                        ><span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                class="me-3 d-flex add-new-app-label"
                                                style="width: 28%; justify-content: end"
                                                >Select Audio File*</label
                                            >
                                            <Multiselect
                                                id="document"
                                                track-by="id"
                                                label="name"
                                                class="position-relative w-70"
                                                placeholder="Search or Select File"
                                                v-model="documentInfo"
                                                :options="selectedDocumentList"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="functionInfo.document_id = $event.id"
                                            />
                                        </span>
                                    </template>

                                    <span class="d-flex align-items-center my-4">
                                        <input
                                            type="checkbox"
                                            id="enabledtms"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="functionInfo.enabledtms"
                                            @input="handleDTMS($event, 'main-module')"
                                        />
                                        <label
                                            class="margin-0 font-16 d-flex align-items-center fw-semibold"
                                            for="enabledtms"
                                            >Enable DTMS Settings</label
                                        >
                                    </span>

                                    <template v-if="functionInfo.enabledtms">
                                        <div class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay"
                                                placeholder="Enter Delay*"
                                                v-model="dtmsDelay"
                                            />

                                            <Input
                                                label=""
                                                rules="integer"
                                                maxLength="1"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit"
                                                placeholder="Enter Digit*"
                                                v-model="dtmsDigit"
                                            />
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Add"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="BottomRight"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined"
                                                    style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    "
                                                    @click="addDtmsSettings('main-module')"
                                                >
                                                    add
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                        <div
                                            v-for="(value, index) in functionInfo.dtmsSettings.slice().reverse()"
                                            :key="index"
                                            class="d-flex w-100"
                                            style="justify-content: flex-end; gap: 5px"
                                        >
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay"
                                                placeholder="Enter Delay*"
                                                v-model="value.delay"
                                                required
                                            />
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit"
                                                placeholder="Enter Digit*"
                                                v-model="value.digit"
                                                required
                                            />
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Remove"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="BottomRight"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined"
                                                    style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    "
                                                    @click="removeDtmsSettings(index, 'main-module')"
                                                >
                                                    remove
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                    </template>
                                </div>

                                <div
                                    class="checkbox-container"
                                    v-if="
                                        ['kiosk', 'mobex-health-hub'].includes(applicationFrame) &&
                                        clickedProfileFunction !== 'Mood Tracker'
                                    "
                                >
                                    <span
                                        v-if="
                                            !['Dashboard Page', 'Dashboard'].includes(clickedProfileFunction) &&
                                            applicationFrame == 'kiosk'
                                        "
                                        class="d-flex align-items-center"
                                        style="color: grey"
                                    >
                                        <input
                                            type="checkbox"
                                            id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!functionInfo.enableQR"
                                            @input="functionInfo.enableQR = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR"
                                            >Show QR Code</label
                                        >
                                    </span>
                                    <span
                                        v-if="
                                            !['Dashboard Page', 'Dashboard', 'Call Button'].includes(
                                                clickedProfileFunction
                                            ) && applicationFrame === 'kiosk'
                                        "
                                        class="d-flex align-items-center"
                                        style="color: grey"
                                    >
                                        <input
                                            type="checkbox"
                                            id="customUserAgent"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!functionInfo.customUserAgent"
                                            @input="functionInfo.customUserAgent = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="customUserAgent"
                                            >Custom user agent</label
                                        >
                                    </span>
                                    <span
                                        v-if="
                                            !['Dashboard Page', 'Dashboard', 'Call Button'].includes(
                                                clickedProfileFunction
                                            )
                                        "
                                        class="d-flex align-items-center"
                                        style="color: grey"
                                    >
                                        <input
                                            type="checkbox"
                                            id="hideSensitiveInformation"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!functionInfo.isSensitive"
                                            @input="functionInfo.isSensitive = $event.target.checked"
                                        />
                                        <label
                                            class="margin-0 font-14 d-flex align-items-center"
                                            for="hideSensitiveInformation"
                                            >Information is sensitive</label
                                        >
                                    </span>
                                </div>

                                <div v-if="functionInfo.isSensitive">
                                    <span class="field-container d-flex align-items-center my-4">
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Sensitive Screen Timeout*</label
                                        >
                                        <Multiselect
                                            id="sensitiveScreenTimeout"
                                            track-by="id"
                                            label="value"
                                            class="position-relative w-70"
                                            placeholder="Select timeout"
                                            v-model="sensitiveScreenTimeout"
                                            :options="sensitiveScreenTimeoutList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                        />
                                    </span>
                                    <span class="field-container d-flex align-items-center my-4">
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Inactivity Timeout*</label
                                        >
                                        <Multiselect
                                            id="sensitiveAlertTimeout"
                                            track-by="id"
                                            label="value"
                                            class="position-relative w-70"
                                            placeholder="Select timeout"
                                            v-model="sensitiveAlertTimeout"
                                            :options="sensitiveAlertTimeoutList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div
                                v-if="clickedProfileFunction == 'Lumeca'"
                                class="section-body add-new-app-form lumeca-form mx-0"
                            >
                                <div class="mx-5 px-3">
                                    <Input
                                        label="Meeting Title"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mb-4"
                                        name="title"
                                        placeholder="Enter Title here"
                                        v-model="functionInfo.lumecatitle"
                                        required
                                    />
                                    <Input
                                        label="Location"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mb-4"
                                        name="location"
                                        placeholder="Enter location here"
                                        v-model="functionInfo.location"
                                        required
                                    />
                                    <div class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                        <Input
                                            label="Phone number"
                                            labelClass="w-45 me-3 d-flex justify-content-end add-new-app-label"
                                            type="number"
                                            class="w-100 field-container d-flex align-items-center mb-4"
                                            name="Phone"
                                            rules="phone"
                                            placeholder="Enter Phone number"
                                            v-model="phoneNumber"
                                            :required="!functionInfo.phoneNumbers.length"
                                        />
                                        <ejs-tooltip
                                            class="tooltipcontainer"
                                            content="Add"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="BottomRight"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined"
                                                style="
                                                    background: #5057c3;
                                                    height: fit-content;
                                                    border-radius: 50%;
                                                    color: white;
                                                    margin-top: 8px;
                                                    cursor: pointer;
                                                "
                                                @click="addPhoneNumber"
                                            >
                                                add
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                    <span class="field-container d-flex align-items-center mt-2 mb-4">
                                        <label
                                            for="category"
                                            class="me-3 d-flex add-new-app-label justify-content-end"
                                            style="width: 28%"
                                            >Category</label
                                        >
                                        <Multiselect
                                            id="category"
                                            track-by="id"
                                            label="title"
                                            class="w-70"
                                            placeholder="Search or Select Category"
                                            v-model="categoryInfo"
                                            :options="categoryList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="functionInfo.module_category = $event.id"
                                        />
                                    </span>
                                    <hr />
                                    <template>
                                        <div class="section-body content-checkbox-list">
                                            <h2>Phone Numbers</h2>
                                            <div
                                                v-if="!functionInfo.phoneNumbers.length"
                                                style="height: 100px"
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <h3>No phone numbers Added</h3>
                                            </div>
                                            <div class="show-phone-number-list" v-if="functionInfo.phoneNumbers.length">
                                                <div
                                                    v-for="(phoneNumber, index) in functionInfo.phoneNumbers"
                                                    :key="index"
                                                    class="d-flex justify-content-between align-items-center mx-2"
                                                >
                                                    <span
                                                        style="
                                                            text-overflow: ellipsis;
                                                            text-wrap: nowrap;
                                                            overflow: hidden;
                                                        "
                                                        >{{ phoneNumber }}</span
                                                    >
                                                    <div class="d-flex">
                                                        <ejs-tooltip
                                                            class="tooltipcontainer"
                                                            content="Edit"
                                                            target="#target"
                                                            cssClass="customtooltip"
                                                            position="BottomRight"
                                                        >
                                                            <span
                                                                id="target"
                                                                class="material-symbols-outlined icon-edit me-2"
                                                                role="button"
                                                                @click="editPhoneNumber(index)"
                                                            >
                                                                edit_square
                                                            </span>
                                                        </ejs-tooltip>
                                                        <ejs-tooltip
                                                            class="tooltipcontainer"
                                                            content="Delete"
                                                            target="#target"
                                                            cssClass="customtooltip"
                                                            position="BottomRight"
                                                        >
                                                            <span
                                                                id="target"
                                                                class="material-symbols-outlined icon-delete"
                                                                role="button"
                                                                @click="deletePhoneNumber(index)"
                                                            >
                                                                delete
                                                            </span>
                                                        </ejs-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditScreen"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addNewApplication"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditScreen"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateApplication(currentModuleId)"
                                >
                                    Save
                                </button>
                                <button type="button" class="no-bg text-button btn-cancel px-4" @click="resetAll()">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
        <!-- third modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formlink">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showLinkForm && !showMainForm">
                        <div
                            v-if="['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)"
                            class="d-flex flex-wrap px-4 my-4"
                            style="flex-direction: column; gap: 5px"
                        >
                            <div class="d-flex color-box w-100">
                                <div class="d-flex my-2 mx-4 w-100">
                                    <div class="d-flex align-items-center w-40" style="gap: 1%">
                                        <input
                                            type="radio"
                                            name="color_type_kiosk"
                                            id="primary_colors"
                                            value="primary_colors"
                                            style="transform: scale(0.8)"
                                            v-model="submodulelink.selectedSchemeOption"
                                            @change="selectColorScheme($event, 'sub-module-link')"
                                        />
                                        <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                    </div>
                                    <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                        <div
                                            v-for="(value, index) in colorScheme.primary_colors"
                                            class="d-flex align-items-center px-2 w-25"
                                            :key="index"
                                            :style="{
                                                height: '30px',
                                                width: '50%',
                                                background: value,
                                                color: '#FFFFFF',
                                                'font-size': '12px',
                                                border: '2px solid black',
                                                'text-transform': 'uppercase',
                                            }"
                                        >
                                            {{ value }}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex color-box w-100">
                                <div class="d-flex my-2 mx-4 w-100">
                                    <div class="d-flex align-items-center w-40" style="gap: 1%">
                                        <input
                                            type="radio"
                                            name="color_type_kiosk"
                                            id="secondary_colors"
                                            value="secondary_colors"
                                            style="transform: scale(0.8)"
                                            v-model="submodulelink.selectedSchemeOption"
                                            @change="selectColorScheme($event, 'sub-module-link')"
                                        />
                                        <label for="option2" style="font-weight: bold">Secondary Color Option</label>
                                    </div>
                                    <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                        <div
                                            v-for="(value, index) in colorScheme.secondary_colors"
                                            class="d-flex align-items-center px-2 w-25"
                                            :key="index"
                                            :style="{
                                                height: '30px',
                                                width: '50%',
                                                background: value,
                                                color: '#FFFFFF',
                                                'font-size': '12px',
                                                border: '2px solid black',
                                                'text-transform': 'uppercase',
                                            }"
                                        >
                                            {{ value }}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                <div class="d-flex my-2 mx-4 w-100">
                                    <div class="d-flex align-items-center w-40" style="gap: 1%">
                                        <input
                                            type="radio"
                                            name="color_type_kiosk"
                                            id="customized_colors"
                                            value="customized_colors"
                                            style="transform: scale(0.8)"
                                            v-model="submodulelink.selectedSchemeOption"
                                            @change="selectColorScheme($event, 'sub-module-link')"
                                        />
                                        <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                    </div>
                                    <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                        <div
                                            v-for="(value, index) in colorScheme.customized_colors"
                                            class="d-flex align-items-center px-2 w-25"
                                            :key="index"
                                            :style="{
                                                height: '30px',
                                                width: '50%',
                                                background: value,
                                                color: '#FFFFFF',
                                                'font-size': '12px',
                                                border: '2px solid black',
                                                'text-transform': 'uppercase',
                                            }"
                                        >
                                            {{ value }}
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex color-box w-100">
                                <div class="d-flex my-2 mx-4 w-100">
                                    <div class="d-flex align-items-center w-40" style="gap: 1%">
                                        <input
                                            type="radio"
                                            name="color_type_kiosk"
                                            id="module_customized_colors"
                                            value="module_customized_colors"
                                            style="transform: scale(0.8)"
                                            v-model="submodulelink.selectedSchemeOption"
                                            @change="selectColorScheme($event, 'sub-module-link')"
                                        />
                                        <label for="option2" style="font-weight: bold"
                                            >Custom Module Color Option</label
                                        >
                                    </div>
                                    <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                        <div
                                            v-for="(value, index) in colorScheme.module_customized_colors"
                                            class="d-flex align-items-center px-2 w-25"
                                            :key="index"
                                        >
                                            <input
                                                v-if="submodulelink.selectedSchemeOption == 'module_customized_colors'"
                                                type="color"
                                                name=""
                                                id=""
                                                v-model="colorScheme.module_customized_colors[index]"
                                                @change="selectCustomColorScheme($event, index, 'sub-module-link')"
                                                class="w-100 p-0"
                                            />
                                            <div v-else class="default-color"></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mb-4 d-flex w-100">
                            <Input
                                label="Select Button Color"
                                labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                type="color"
                                class="field-container d-flex align-items-center w-50"
                                inputClass="w-100 pp-5 p-1"
                                name="custom-color"
                                @change="selectCustomColor($event, 'sub-module-link')"
                                v-model="submodulelink.color"
                            />

                            <Input
                                label="Select Text Color"
                                labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                type="color"
                                class="field-container d-flex align-items-center w-50"
                                inputClass="w-100 pp-5 p-1"
                                name="custom-text-color"
                                @change="selectCustomTextColor($event, 'sub-module-link')"
                                v-model="submodulelink.textColor"
                            />
                        </div>
                        <div class="section-body add-new-app-form">
                            <Input
                                label="Title"
                                labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                type="text"
                                class="field-container d-flex align-items-center mb-4"
                                name="title"
                                placeholder="Enter Title here"
                                v-model="submodulelink.title"
                                required
                            />
                            <span
                                v-if="urlDropdownList.length > 1"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                            >
                                <label class="me-3 d-flex add-new-app-label" style="width: 28%; justify-content: end"
                                    >File Type*</label
                                >
                                <Multiselect
                                    id="fileType"
                                    track-by="type"
                                    label="name"
                                    class="position-relative w-70"
                                    placeholder="Select type"
                                    v-model="fileTypeInfo"
                                    :options="fileTypeList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="handleFiletype($event, 'sub-module-link')"
                                />
                            </span>
                            <span
                                v-if="urlDropdownList.length > 1"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                            >
                                <label class="me-3 d-flex add-new-app-label" style="width: 28%; justify-content: end"
                                    >URL Type*</label
                                >
                                <Multiselect
                                    id="fileType"
                                    track-by="type"
                                    label="name"
                                    class="position-relative w-70"
                                    placeholder="Select type"
                                    v-model="urlTypeInfo"
                                    :options="urlDropdownList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="handleUrlType($event)"
                                />
                            </span>
                            <span
                                v-if="urlTypeInfo.type === 'lookupTable'"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                            >
                                <label class="me-3 d-flex add-new-app-label" style="width: 28%; justify-content: end"
                                    >Select Table*</label
                                >
                                <Multiselect
                                    id="document"
                                    track-by="id"
                                    label="name"
                                    class="position-relative w-70"
                                    placeholder="Search or Select Table"
                                    v-model="lookupTableInfo"
                                    :options="lookupTableList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="submodulelink.lookup_table_id = $event.id"
                                />
                            </span>
                            <span
                                v-else-if="urlTypeInfo.type === 'existingFiles'"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                            >
                                <label class="me-3 d-flex add-new-app-label" style="width: 28%; justify-content: end"
                                    >Select File*</label
                                >
                                <Multiselect
                                    id="document"
                                    track-by="id"
                                    label="name"
                                    class="position-relative w-70"
                                    placeholder="Search or Select File"
                                    v-model="documentInfo"
                                    :options="selectedDocumentList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="submodulelink.document_id = $event.id"
                                />
                            </span>
                            <Input
                                v-else
                                label="URL"
                                labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                type="text"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                                name="URL"
                                placeholder="Enter URL"
                                v-model="submodulelink.url"
                                required
                            />
                            <span class="field-container d-flex align-items-center mt-2 mb-4">
                                <label
                                    for="category"
                                    class="me-3 d-flex add-new-app-label justify-content-end"
                                    style="width: 28%"
                                    >Category</label
                                >
                                <Multiselect
                                    id="category"
                                    track-by="id"
                                    label="title"
                                    class="w-70"
                                    placeholder="Search or Select Category"
                                    v-model="categoryInfo"
                                    :options="categoryList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="submodulelink.module_category = $event.id"
                                />
                            </span>

                            <div
                                class="checkbox-container"
                                v-if="['kiosk', 'mobex-health-hub'].includes(applicationFrame)"
                            >
                                <span
                                    v-if="applicationFrame == 'kiosk'"
                                    class="d-flex align-items-center"
                                    style="color: grey"
                                >
                                    <input
                                        type="checkbox"
                                        id="enableQR"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        :checked="!!submodulelink.enableQR"
                                        @input="submodulelink.enableQR = $event.target.checked"
                                    />
                                    <label class="margin-0 font-14 d-flex align-items-center" for="enableQR"
                                        >Show QR Code</label
                                    >
                                </span>
                                <span
                                    v-if="applicationFrame === 'kiosk'"
                                    class="d-flex align-items-center"
                                    style="color: grey"
                                >
                                    <input
                                        type="checkbox"
                                        id="customUserAgent"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        :checked="!!submodulelink.customUserAgent"
                                        @input="submodulelink.customUserAgent = $event.target.checked"
                                    />
                                    <label class="margin-0 font-14 d-flex align-items-center" for="customUserAgent"
                                        >Custom user agent</label
                                    >
                                </span>
                                <span class="d-flex align-items-center" style="color: grey">
                                    <input
                                        type="checkbox"
                                        id="hideSensitiveInformation"
                                        class="d-flex justify-content-end add-new-app-label right-5 color"
                                        :checked="!!submodulelink.isSensitive"
                                        @input="submodulelink.isSensitive = $event.target.checked"
                                    />
                                    <label
                                        class="margin-0 font-14 d-flex align-items-center"
                                        for="hideSensitiveInformation"
                                        >Information is sensitive</label
                                    >
                                </span>
                            </div>
                            <div v-if="submodulelink.isSensitive">
                                <span class="field-container d-flex align-items-center my-4">
                                    <label
                                        class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end"
                                        >Select Sensitive Screen Timeout*</label
                                    >
                                    <Multiselect
                                        id="sensitiveScreenTimeout"
                                        track-by="id"
                                        label="value"
                                        class="position-relative w-70"
                                        placeholder="Select timeout"
                                        v-model="sensitiveScreenTimeout"
                                        :options="sensitiveScreenTimeoutList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    />
                                </span>
                                <span class="field-container d-flex align-items-center my-4">
                                    <label
                                        class="me-3 d-flex add-new-app-label"
                                        style="width: 28%; justify-content: end"
                                        >Select Sensitive Inactivity Timeout*</label
                                    >
                                    <Multiselect
                                        id="sensitiveAlertTimeout"
                                        track-by="id"
                                        label="value"
                                        class="position-relative w-70"
                                        placeholder="Select timeout"
                                        v-model="sensitiveAlertTimeout"
                                        :options="sensitiveAlertTimeoutList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditSubModuleLink"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addNewSubModuleLink"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditSubModuleLink"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateSubModuleLink(currentSubModuleLinkId)"
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
        <!-- fourth modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="formsubmodule">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && !showSelectSvgModal && !showMainForm && !showLinkForm">
                        <div class="section-body add-new-app-form mx-0">
                            <span
                                v-if="['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)"
                                class="field-container d-flex align-items-center mt-2 mb-4"
                            >
                                <label
                                    for="subModuleType"
                                    class="me-3 d-flex add-new-app-label justify-content-end"
                                    style="width: 30%"
                                    >Select Module Type*</label
                                >
                                <Multiselect
                                    id="subModuleType"
                                    track-by="type"
                                    label="name"
                                    class="position-relative w-50"
                                    placeholder="Select type"
                                    v-model="subModuleType"
                                    :options="subModuleTypes"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="handleSubmoduleType"
                                    :disabled="isEditSubModule"
                                />
                            </span>
                            <span
                                v-else
                                class="field-container d-flex align-items-center mt-2 mb-4"
                                style="margin-left: 3.7rem"
                            >
                                <label class="me-3 d-flex add-new-app-label" style="max-width: 28%; text-align: end"
                                    >3rd Party Applications</label
                                >
                                <Toggle
                                    id="thirdPartyApp"
                                    class="toggle-switch"
                                    labelClass="switch"
                                    spanClass="slider"
                                    v-model="submodule.allow3rdParty"
                                    style="margin-left: 0"
                                />
                            </span>
                            <div
                                v-if="!submodule.allow3rdParty"
                                class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3"
                            >
                                <label class="me-3 d-flex justify-content-end add-new-app-label">Icon/Images*</label>
                                <div class="d-flex align-items-center w-100 gap-1">
                                    <button
                                        type="button"
                                        :class="
                                            (['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(
                                                applicationFrame
                                            )
                                                ? 'w-40'
                                                : 'w-100') + ' no-bg text-button btn-add-new-app'
                                        "
                                        @click="chooseFile"
                                    >
                                        Choose file
                                    </button>
                                    <button
                                        v-if="
                                            ['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(
                                                applicationFrame
                                            )
                                        "
                                        type="button"
                                        class="no-bg text-button btn-add-new-app w-40"
                                        @click="chooseFromCompany"
                                    >
                                        Choose icon
                                    </button>
                                    <!-- <span v-if="isFileAvailable" class="errorClass">Please upload file</span> -->
                                    <input
                                        id="submodule"
                                        type="file"
                                        name="upload"
                                        ref="upload"
                                        size="100000"
                                        @change="uploadFilesubModule"
                                        hidden
                                        required
                                        :accept="acceptedFileTypes"
                                    />

                                    <div class="img-container-small d-flex justify-content-center align-items-center">
                                        <img
                                            v-if="!uploadedImgSubModule && !showImgLoader && !isSvgPresent"
                                            src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                        />

                                        <img
                                            v-if="!showImgLoader && isSvgPresent"
                                            :src="svgToBase64"
                                            alt="image not found"
                                        />
                                        <img
                                            v-if="uploadedImgSubModule && !showImgLoader && !isSvgPresent"
                                            :src="uploadedImgSubModule"
                                            alt="image not uploaded"
                                        />
                                        <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                            <div
                                                class="d-flex justify-content-center align-items-center img-loader-main"
                                            >
                                                <b-spinner variant="primary" class="img-loader"></b-spinner>
                                            </div>
                                            <span class="img-loader-text">Uploading...</span>
                                        </div>
                                        <ejs-tooltip
                                            v-if="uploadedImgSubModule || svgImg"
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                            position="top"
                                        >
                                            <span
                                                id="target"
                                                class="material-symbols-outlined img-delete-small"
                                                role="button"
                                                @click="resetFileUpload()"
                                            >
                                                delete
                                            </span>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    !['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame) &&
                                    !submodule.allow3rdParty
                                "
                                class="scroll-wrapper"
                            >
                                <div style="gap: 25px" class="scroll-container">
                                    <div v-for="bg in subModulesPhotos" :key="bg.image">
                                        <input type="radio" hidden />
                                        <label>
                                            <img
                                                :src="bg.image"
                                                style="width: 50px; height: 50px; max-width: 100px; cursor: pointer"
                                                :class="bg.file_id == submodulePhoto.file_id ? 'active-bg' : ''"
                                                @click="selectBackgroundForSubModule(bg)"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    !submodule.allow3rdParty &&
                                    ['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)
                                "
                                class="d-flex flex-wrap px-4 my-4"
                                style="flex-direction: column; gap: 5px"
                            >
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="primary_colors"
                                                value="primary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="submodule.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'sub-module')"
                                            />
                                            <label for="option2" style="font-weight: bold">Primary Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.primary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="secondary_colors"
                                                value="secondary_colors"
                                                style="transform: scale(0.8)"
                                                v-model="submodule.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'sub-module')"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Secondary Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.secondary_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="isCustosmizedThemeSelected" class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="customized_colors"
                                                value="customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="submodule.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'sub-module')"
                                            />
                                            <label for="option2" style="font-weight: bold">Custom Color Option</label>
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                                :style="{
                                                    height: '30px',
                                                    width: '50%',
                                                    background: value,
                                                    color: '#FFFFFF',
                                                    'font-size': '12px',
                                                    border: '2px solid black',
                                                    'text-transform': 'uppercase',
                                                }"
                                            >
                                                {{ value }}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex color-box w-100">
                                    <div class="d-flex my-2 mx-4 w-100">
                                        <div class="d-flex align-items-center w-40" style="gap: 1%">
                                            <input
                                                type="radio"
                                                name="color_type_kiosk"
                                                id="module_customized_colors"
                                                value="module_customized_colors"
                                                style="transform: scale(0.8)"
                                                v-model="submodule.selectedSchemeOption"
                                                @change="selectColorScheme($event, 'sub-module')"
                                            />
                                            <label for="option2" style="font-weight: bold"
                                                >Custom Module Color Option</label
                                            >
                                        </div>
                                        <label class="d-flex py-2 w-60" style="gap: 5px" for="customized_color">
                                            <div
                                                v-for="(value, index) in colorScheme.module_customized_colors"
                                                class="d-flex align-items-center px-2 w-25"
                                                :key="index"
                                            >
                                                <input
                                                    v-if="submodule.selectedSchemeOption == 'module_customized_colors'"
                                                    type="color"
                                                    name=""
                                                    id=""
                                                    v-model="colorScheme.module_customized_colors[index]"
                                                    @change="selectCustomColorScheme($event, index, 'sub-module')"
                                                    class="w-100 p-0"
                                                />
                                                <div v-else class="default-color"></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    !submodule.allow3rdParty &&
                                    !['kiosk', 'mobex-health-home', 'mobex-health-plus'].includes(applicationFrame)
                                "
                                class="mx-5"
                            >
                                <div class="mb-4 d-flex w-100">
                                    <Input
                                        label="Select Button Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-color"
                                        @change="selectCustomColor($event, 'sub-module')"
                                        v-model="submodule.color"
                                    />

                                    <Input
                                        label="Select Text Color"
                                        labelClass="me-3 d-flex justify-content-end add-new-app-label w-100"
                                        type="color"
                                        class="field-container d-flex align-items-center w-50"
                                        inputClass="w-100 pp-5 p-1"
                                        name="custom-text-color"
                                        @change="selectCustomTextColor($event, 'sub-module')"
                                        v-model="submodule.textColor"
                                    />
                                </div>
                            </div>
                            <div class="mx-5 px-3">
                                <Input
                                    v-if="!submodule.allow3rdParty"
                                    label="Title"
                                    labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center mb-4"
                                    name="title"
                                    placeholder="Enter Title here"
                                    v-model="submodule.title"
                                    required
                                />
                                <span
                                    v-if="submodule.allow3rdParty"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                >
                                    <label class="me-3 d-flex add-new-app-label" style="width: 28%; text-align: end"
                                        >3rd Party Application</label
                                    >
                                    <Dropdown
                                        name="ThirdPartyoption"
                                        id="ThirdPartyoption"
                                        label=""
                                        v-model="submodule.thirdPartyValue.id"
                                        :options="thirdPartyFunctionWidgets"
                                        @change="(value) => handleSelect3rdParty(value)"
                                        placeholder="---- Select ----"
                                        style="width: 70%"
                                    />
                                </span>
                                <template v-if="!submodule.allow3rdParty && !submodule.isCallButtonModule">
                                    <span
                                        v-if="urlDropdownList.length > 1"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >File Type</label
                                        >
                                        <Multiselect
                                            id="fileType"
                                            track-by="type"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Select type"
                                            v-model="fileTypeInfo"
                                            :options="fileTypeList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="handleFiletype($event, 'sub-module')"
                                        />
                                    </span>
                                    <span
                                        v-if="urlDropdownList.length > 1"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >URL Type</label
                                        >
                                        <Multiselect
                                            id="fileType"
                                            track-by="type"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Select type"
                                            v-model="urlTypeInfo"
                                            :options="urlDropdownList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="handleUrlType($event)"
                                        />
                                    </span>
                                    <span
                                        v-if="urlTypeInfo.type === 'lookupTable'"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Table</label
                                        >
                                        <Multiselect
                                            id="document"
                                            track-by="id"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Search or Select Table"
                                            v-model="lookupTableInfo"
                                            :options="lookupTableList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="submodule.lookup_table_id = $event.id"
                                        />
                                    </span>
                                    <span
                                        v-else-if="urlTypeInfo.type === 'existingFiles'"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                    >
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select File</label
                                        >
                                        <Multiselect
                                            id="document"
                                            track-by="id"
                                            label="name"
                                            class="position-relative w-70"
                                            placeholder="Search or Select File"
                                            v-model="documentInfo"
                                            :options="selectedDocumentList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @input="submodule.document_id = $event.id"
                                        />
                                    </span>
                                    <Input
                                        v-else
                                        label="URL"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="text"
                                        class="field-container d-flex align-items-center mt-2 mb-4"
                                        name="URL"
                                        placeholder="Enter URL"
                                        v-model="submodule.url"
                                    />
                                </template>

                                <span class="field-container d-flex align-items-center mt-2 mb-4">
                                    <label
                                        for="category"
                                        class="me-3 d-flex add-new-app-label justify-content-end"
                                        style="width: 28%"
                                        >Category</label
                                    >
                                    <Multiselect
                                        id="category"
                                        track-by="id"
                                        label="title"
                                        class="w-70"
                                        placeholder="Search or Select Category"
                                        v-model="categoryInfo"
                                        :options="categoryList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="submodule.module_category = $event.id"
                                    />
                                </span>

                                <span
                                    v-if="!submodule.isCallButtonModule"
                                    class="field-container d-flex align-items-center mt-2 mb-4"
                                    style="margin-left: 4rem"
                                >
                                    <label for="" style="padding-right: 5%">Description </label>
                                    <span>
                                        <textarea
                                            placeholder="Write Description"
                                            name="description"
                                            id="description"
                                            cols="50"
                                            rows="3"
                                            class="input-text"
                                            style="border-radius: 8px !important"
                                            v-model="submodule.description"
                                        ></textarea>
                                    </span>
                                </span>

                                <div v-if="submodule.isCallButtonModule" class="lumeca-form">
                                    <Input
                                        label="Phone number"
                                        labelClass="w-40 me-3 d-flex justify-content-end add-new-app-label"
                                        type="number"
                                        class="field-container d-flex align-items-center mb-4"
                                        name="Phone number"
                                        placeholder="Enter Phone number"
                                        rules="mobile"
                                        v-model="submodule.callButtonData.phoneNumber"
                                        required
                                    />

                                    <span class="d-flex align-items-center my-4">
                                        <input
                                            type="checkbox"
                                            id="enableAudio"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="submodule.callButtonData.isAudioEnabled"
                                            @input="handleAudio($event, 'sub-module')"
                                        />
                                        <label
                                            class="margin-0 font-16 d-flex align-items-center fw-semibold"
                                            for="enableAudio"
                                            >Enable Audio Ring</label
                                        >
                                    </span>

                                    <template v-if="submodule.callButtonData.isAudioEnabled"
                                        ><span class="field-container d-flex align-items-center mt-2 mb-4">
                                            <label
                                                class="me-3 d-flex add-new-app-label"
                                                style="width: 28%; justify-content: end"
                                                >Select Audio File*</label
                                            >
                                            <Multiselect
                                                id="document"
                                                track-by="id"
                                                label="name"
                                                class="position-relative w-70"
                                                placeholder="Search or Select File"
                                                v-model="documentInfo"
                                                :options="selectedDocumentList"
                                                :searchable="true"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                @input="submodule.document_id = $event.id"
                                            />
                                        </span>
                                    </template>

                                    <span class="d-flex align-items-center my-4">
                                        <input
                                            type="checkbox"
                                            id="enabledtms"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="submodule.callButtonData.enabledtms"
                                            @input="handleDTMS($event, 'sub-module')"
                                        />
                                        <label
                                            class="margin-0 font-16 d-flex align-items-center fw-semibold"
                                            for="enabledtms"
                                            >Enable DTMS Settings</label
                                        >
                                    </span>

                                    <template v-if="submodule.callButtonData.enabledtms">
                                        <div class="d-flex w-100" style="justify-content: flex-end; gap: 5px">
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay"
                                                placeholder="Enter Delay*"
                                                v-model="dtmsDelay"
                                            />

                                            <Input
                                                label=""
                                                rules="integer"
                                                maxLength="1"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit"
                                                placeholder="Enter Digit*"
                                                v-model="dtmsDigit"
                                            />
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Add"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="BottomRight"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined"
                                                    style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    "
                                                    @click="addDtmsSettings('sub-module')"
                                                >
                                                    add
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                        <div
                                            v-for="(value, index) in submodule.callButtonData.dtmsSettings
                                                .slice()
                                                .reverse()"
                                            :key="index"
                                            class="d-flex w-100"
                                            style="justify-content: flex-end; gap: 5px"
                                        >
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="delay"
                                                placeholder="Enter Delay*"
                                                v-model="value.delay"
                                                required
                                            />
                                            <Input
                                                label=""
                                                type="number"
                                                rules="integer"
                                                class="w-100 field-container d-flex align-items-center mb-4"
                                                name="digit"
                                                placeholder="Enter Digit*"
                                                v-model="value.digit"
                                                required
                                            />
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Remove"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="BottomRight"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined"
                                                    style="
                                                        background: #5057c3;
                                                        height: fit-content;
                                                        border-radius: 50%;
                                                        color: white;
                                                        margin-top: 8px;
                                                        cursor: pointer;
                                                    "
                                                    @click="removeDtmsSettings(index, 'sub-module')"
                                                >
                                                    remove
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                    </template>
                                </div>

                                <div
                                    class="checkbox-container"
                                    v-if="
                                        !submodule.allow3rdParty &&
                                        !submodule.isCallButtonModule &&
                                        ['kiosk', 'mobex-health-hub'].includes(applicationFrame)
                                    "
                                >
                                    <span
                                        v-if="applicationFrame == 'kiosk'"
                                        class="d-flex align-items-center"
                                        style="color: grey"
                                    >
                                        <input
                                            type="checkbox"
                                            id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!submodule.enableQR"
                                            @input="submodule.enableQR = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR"
                                            >Show QR Code</label
                                        >
                                    </span>
                                    <span
                                        v-if="applicationFrame === 'kiosk'"
                                        class="d-flex align-items-center"
                                        style="color: grey"
                                    >
                                        <input
                                            type="checkbox"
                                            id="customUserAgent"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!submodule.customUserAgent"
                                            @input="submodule.customUserAgent = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="customUserAgent"
                                            >Custom user agent</label
                                        >
                                    </span>
                                    <span class="d-flex align-items-center" style="color: grey">
                                        <input
                                            type="checkbox"
                                            id="hideSensitiveInformation"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="!!submodule.isSensitive"
                                            @input="submodule.isSensitive = $event.target.checked"
                                        />
                                        <label
                                            class="margin-0 font-14 d-flex align-items-center"
                                            for="hideSensitiveInformation"
                                            >Information is sensitive</label
                                        >
                                    </span>
                                </div>

                                <div v-if="submodule.isSensitive">
                                    <span class="field-container d-flex align-items-center m-4">
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Sensitive Screen Timeout*</label
                                        >
                                        <Multiselect
                                            id="sensitiveScreenTimeout"
                                            track-by="id"
                                            label="value"
                                            class="position-relative w-70"
                                            placeholder="Select timeout"
                                            v-model="sensitiveScreenTimeout"
                                            :options="sensitiveScreenTimeoutList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                        />
                                    </span>
                                    <span class="field-container d-flex align-items-center m-4">
                                        <label
                                            class="me-3 d-flex add-new-app-label"
                                            style="width: 28%; justify-content: end"
                                            >Select Sensitive Inactivity Timeout*</label
                                        >
                                        <Multiselect
                                            id="sensitiveAlertTimeout"
                                            track-by="id"
                                            label="value"
                                            class="position-relative w-70"
                                            placeholder="Select timeout"
                                            v-model="sensitiveAlertTimeout"
                                            :options="sensitiveAlertTimeoutList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button
                                    v-if="!isEditSubModule"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="addSubModule"
                                >
                                    Save
                                </button>
                                <button
                                    v-if="isEditSubModule"
                                    type="button"
                                    class="primary btn-save-application"
                                    :disabled="showImgLoader"
                                    @click="updateSubModule(currentSubModuleId)"
                                >
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showPage('FUNCTION_LISTING')"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <!-- Select SVG Modal -->
        <div class="modal-list-third-party-app">
            <ValidationObserver ref="svgForm">
                <form style="box-shadow: none" class="p-0">
                    <template v-if="!showLoader && showSelectSvgModal">
                        <div
                            v-if="svgList.length"
                            class="d-flex flex-wrap gap-3 m-3 p-3 overflow-auto"
                            style="max-height: 350px; min-height: 200px; padding: 5px; padding-bottom: 10px"
                        >
                            <div
                                v-for="(svg, index) in svgList"
                                :key="index"
                                :class="(selectedSvg.id === svg.id ? 'active-bg ' : '') + 'img-container'"
                                style="max-height: 50px"
                                @click="selectedSvg = svg"
                            >
                                <ejs-tooltip
                                    target="#target"
                                    :content="svg.title"
                                    class="tooltipcontainer"
                                    cssClass="customtooltip"
                                >
                                    <img id="target" :src="svg.imageUrl" alt="" class="img" style="width: 50px" />
                                </ejs-tooltip>
                            </div>
                        </div>
                        <div v-else-if="searchedSvg !== ''" class="w-100">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                                style="min-height: 200px"
                            >
                                <h4>No SVG available</h4>
                            </div>
                        </div>
                        <div v-else class="w-100">
                            <div
                                class="d-flex justify-content-center align-items-center py-5"
                                style="min-height: 200px"
                            >
                                <h4>No SVG Added</h4>
                            </div>
                        </div>
                        <div class="section-footer add-new-app-form my-4">
                            <div class="d-flex justify-content-center">
                                <button type="button" class="primary btn-save-application" @click="saveSvg">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="no-bg text-button btn-cancel px-4"
                                    @click="showSelectSvgModal = false"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>

        <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
    import draggable from 'vuedraggable';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import { ValidationObserver } from 'vee-validate';
    import {
        memberProfile,
        dashboardBuilder,
        companies,
        fileManager,
        lookupTable,
        svgPicker,
        category,
    } from '@/util/apiRequests';

    const initialFunctionInfoState = {
        title: '',
        url: '',
        color: '',
        textColor: '',
        primaryColor: null,
        secondaryColor: null,
        isSensitive: false,
        enableQR: false,
        customUserAgent: false,
        embeddedDashboardId: '',
        embeddedDashboardName: '',
        embeddedCompanyId: null,
        embeddedCompanyName: '',
        lumecatitle: '',
        location: '',
        selectedSchemeOption: 'primary_colors',
        phoneNumbers: [],
        file_id: null,
        document_id: null,
        sensitiveScreenTimeout: 0,
        sensitiveAlertTimeout: 0,
        isAudioEnabled: false,
        enabledtms: false,
        dtmsSettings: [],
        lookup_table_id: null,
        module_category: null,
    };

    const subModuleState = {
        title: '',
        image: '',
        url: '',
        description: '',
        allow3rdParty: false,
        isSensitive: false,
        enableQR: false,
        customUserAgent: false,
        color: '',
        textColor: '',
        primaryColor: null,
        secondaryColor: null,
        selectedSchemeOption: 'primary_colors',
        thirdPartyValue: {
            active: 1,
            android_id: '',
            deleted: 0,
            file_id: '',
            function_name: '',
            function_type: '',
            height: '',
            width: '',
            image: '',
            ios_id: '',
            id: null,
        },
        document_id: null,
        document_type: 'weblink',
        sensitiveScreenTimeout: 0,
        sensitiveAlertTimeout: 0,
        lookup_table_id: null,
        isCallButtonModule: false,
        callButtonData: {
            enabledtms: false,
            dtmsSettings: [],
            isAudioEnabled: false,
            phoneNumber: '',
        },
        module_category: null,
    };

    const subModuleLinkState = {
        title: '',
        url: '',
        color: '',
        textColor: '',
        primaryColor: null,
        secondaryColor: null,
        selectedSchemeOption: 'primary_colors',
        isSensitive: false,
        enableQR: false,
        customUserAgent: false,
        document_id: null,
        document_type: 'weblink',
        sensitiveScreenTimeout: 0,
        sensitiveAlertTimeout: 0,
        lookup_table_id: null,
        module_category: null,
    };

    export default {
        name: 'AddDetailModal',
        components: { NoButtonModal, ValidationObserver, SpinLoader, draggable },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            clickedProfileFunction: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            current_template: {
                type: Number,
            },
            group: {
                type: String,
            },
        },
        data() {
            return {
                showAppListing: true,
                showLinkListing: true,
                showLoader: true,
                isEditScreen: false,
                isEditSubModule: false,
                isEditSubModuleLink: false,
                subModulesList: [],
                subModuleLinksList: [],
                functionInfo: { ...initialFunctionInfoState },
                submodule: { ...subModuleState },
                submodulelink: { ...subModuleLinkState },
                uploadedImg: '',
                uploadedImgSubModule: '',
                showImgLoader: false,
                showMainForm: true,
                color: null,
                textColor: null,
                showLinkForm: false,
                colorScheme: {},
                isCustosmizedThemeSelected: false,
                isModuleLogoUpdated: false,
                svgImg: '',
                svgElement: '',
                currentModuleId: '',
                reorderedSubModuleLinkList: [],
                isSubmoduleLinkListChanged: false,
                showSelectSvgModal: false,
                documentInfo: '',
                documentList: [],
                selectedDocumentList: [],
                fileTypeInfo: {
                    type: 'weblink',
                    name: 'Web Link',
                },
                fileTypeList: [
                    {
                        type: 'weblink',
                        name: 'Web Link',
                    },
                    {
                        type: 'pdf',
                        name: 'PDF',
                    },
                    {
                        type: 'video',
                        name: 'Video',
                    },
                ],
                lookupTableInfo: '',
                lookupTableList: [],
                urlTypeInfo: {
                    type: 'url',
                    name: 'URL',
                },
                urlTypeList: [
                    {
                        type: 'url',
                        name: 'URL',
                    },
                    {
                        type: 'existingFiles',
                        name: 'Use Existing Files',
                    },
                    {
                        type: 'lookupTable',
                        name: 'Lookup Table',
                    },
                ],
                isSensitive: false,
                initialSensitiveScreenTimeout: '',
                sensitiveScreenTimeout: '',
                sensitiveScreenTimeoutList: [
                    {
                        id: 30,
                        value: '30 seconds',
                    },
                    {
                        id: 60,
                        value: '1 minute',
                    },
                    {
                        id: 120,
                        value: '2 minutes',
                    },
                    {
                        id: 180,
                        value: '3 minutes',
                    },
                    {
                        id: 240,
                        value: '4 minutes',
                    },
                    {
                        id: 300,
                        value: '5 minutes',
                    },
                ],
                initialSensitiveAlertTimeout: '',
                sensitiveAlertTimeout: '',
                sensitiveAlertTimeoutList: [
                    {
                        id: 15,
                        value: '15 seconds',
                    },
                    {
                        id: 30,
                        value: '30 seconds',
                    },
                    {
                        id: 60,
                        value: '1 minute',
                    },
                ],
                currentSubModuleId: '',
                currentSubModuleLinkId: '',
                genericModulePhotos: [],
                backgroundPhoto: {},
                subModulesPhotos: [],
                submodulePhoto: {},
                Position: null,
                thirdPartyFunctionWidgets: [],
                thirdPartyCompleteData: [],
                allThirdPartyFunctions: [],
                companyInfo: '',
                dashboardInfo: '',
                companyList: [],
                dashboardList: [],
                companyDashboardList: [],
                pdfLoading: false,
                videoLoading: false,
                phoneNumber: '',
                phoneNumberIndex: null,
                dtmsDelay: '',
                dtmsDigit: '',
                isSvgPresent: false,
                dashboard_id: null,
                allSvgList: [],
                svgList: [],
                selectedSvg: {},
                searchedSvg: '',
                subModuleType: {
                    type: 'other',
                    name: 'Other',
                },
                subModuleTypes: [
                    {
                        type: '3rdPartyApp',
                        name: '3rd Party Application',
                    },
                    {
                        type: 'callButton',
                        name: 'Call Button',
                    },
                    {
                        type: 'other',
                        name: 'Other',
                    },
                ],
                categoryInfo: '',
                categoryList: [],
            };
        },
        computed: {
            acceptedFileTypes() {
                if (
                    ['kiosk', 'mobex-health-hub', 'mobex-health-home', 'mobex-health-plus'].includes(
                        this.applicationFrame
                    )
                ) {
                    return '.svg, .png, .jpg, .jpeg';
                }
                return '.png, .jpg, .jpeg';
            },
            svgToBase64() {
                if (!this.svgImg) {
                    return;
                }

                // Get the outer HTML representation of the SVG element
                const svgHtml = this.svgImg.outerHTML;

                // Encode the SVG HTML string to base64
                const base64Svg = btoa(svgHtml);

                // Return the base64 encoded SVG data
                return 'data:image/svg+xml;base64,' + base64Svg;
            },
            urlDropdownList() {
                const isKioskOrMHH = ['kiosk', 'mobex-health-hub'].includes(this.applicationFrame);
                const isWebLink =
                    this.clickedProfileFunction === 'Web Link' ||
                    (this.clickedProfileFunction === 'Dashboard Page' && this.fileTypeInfo?.type === 'weblink');

                return this.urlTypeList.filter((item) => {
                    if (!isKioskOrMHH) return item.type === 'url';
                    if (isWebLink) return item.type !== 'existingFiles';
                    return item;
                });
            },
            isHomeTemplate() {
                return ['mobex-health-home', 'mobex-health-plus'].includes(this.applicationFrame);
            },
            isHomeMoodTracker() {
                return this.isHomeTemplate && this.clickedProfileFunction === 'Mood Tracker';
            },
        },
        methods: {
            showAddSubModuleLinkIcon(subModule) {
                return (
                    !subModule.allow3rdParty &&
                    !subModule.submodule_id &&
                    !subModule.document_id &&
                    !subModule.url &&
                    !subModule.lookup_table_id
                );
            },
            async handleSelect3rdParty(val) {
                let temp = this.thirdPartyCompleteData.find((item) => item.id == val);
                this.submodule.thirdPartyValue = temp;
                this.submodule.title = temp.function_name;
            },
            async handleSubmoduleType(val) {
                this.submodule.isCallButtonModule = false;
                this.submodule.allow3rdParty = false;
                this.submodule.thirdPartyValue.id = null;
                if (val.type === '3rdPartyApp') {
                    this.submodule.allow3rdParty = true;
                } else if (val.type === 'callButton') {
                    this.submodule.isCallButtonModule = true;
                    this.selectedDocumentList = this.documentList.filter((item) => item.type == 'audio');
                }
            },
            selectBackground(bg) {
                this.backgroundPhoto = {
                    file_id: bg.file_id,
                    id: bg.id,
                    image: bg.image,
                };

                this.isSvgPresent = false;
                this.uploadedImg = this.backgroundPhoto.image;
                this.functionInfo.image = this.backgroundPhoto.file_id;
                // this.$toasted.success('Module image uploaded successfully !');
            },
            selectBackgroundForSubModule(bg) {
                this.submodulePhoto = {
                    file_id: bg.file_id,
                    id: bg.id,
                    image: bg.image,
                };

                this.isSvgPresent = false;
                this.uploadedImgSubModule = this.submodulePhoto.image;
                this.submodule.image = this.submodulePhoto.file_id;
                // this.$toasted.success('SubModule image uploaded successfully !');
            },
            selectCustomColor(event, type) {
                let color = event.target.value;
                if (type == 'main-module') {
                    this.functionInfo.color = color;
                } else if (type == 'sub-module') {
                    this.submodule.color = color;
                } else {
                    this.submodulelink.color = color;
                }
                // this.errors.color = null;
            },
            selectCustomTextColor(event, type) {
                let textColor = event.target.value;
                if (type == 'main-module') {
                    this.functionInfo.textColor = textColor;
                } else if (type == 'sub-module') {
                    this.submodule.textColor = textColor;
                } else {
                    this.submodulelink.textColor = textColor;
                }
                // this.errors.textColor = null;
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },

            isFileAvailable() {
                return this.$refs.upload.value === '' ? false : true;
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async addNewApplication() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    const {
                        document_id,
                        lookup_table_id,
                        url,
                        embeddedCompanyId,
                        embeddedDashboardId,
                        phoneNumbers,
                        isAudioEnabled,
                        enabledtms,
                        dtmsSettings,
                        image,
                        isSensitive,
                    } = this.functionInfo;

                    if (!url && ['Video Link', 'PDF Link', 'Web Link'].includes(this.clickedProfileFunction)) {
                        if (
                            this.clickedProfileFunction !== 'Web Link' &&
                            this.urlTypeInfo?.type === 'existingFiles' &&
                            !document_id
                        ) {
                            this.$toasted.error('Please select any document');
                            return;
                        } else if (this.urlTypeInfo?.type === 'lookupTable' && !lookup_table_id) {
                            this.$toasted.error('Please select any table');
                            return;
                        }
                    }

                    if (this.clickedProfileFunction == 'Dashboard' && (!embeddedCompanyId || !embeddedDashboardId)) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Lumeca' && !phoneNumbers?.length) {
                        this.$toasted.error('Please add atleast one phone number');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && isAudioEnabled && !document_id) {
                        this.$toasted.error('Please select any audio File');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && enabledtms && !dtmsSettings.length) {
                        this.$toasted.error('Please add atleast one DTMS setting');
                        return;
                    }

                    if (this.clickedProfileFunction !== 'Mood Tracker' && !this.isFileAvailable() && !image.length) {
                        this.$toasted.error('Please upload or select Image');
                        return;
                    }

                    if (isSensitive && this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && this.phoneNumber != '') {
                        if (phoneNumbers.length) {
                            this.functionInfo.phoneNumbers[0] = this.phoneNumber;
                        } else {
                            this.functionInfo.phoneNumbers.unshift(this.phoneNumber);
                        }
                    }

                    this.functionInfo.sensitiveScreenTimeout = this.functionInfo.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.functionInfo.sensitiveAlertTimeout = this.functionInfo.isSensitive
                        ? this.sensitiveAlertTimeout?.id
                        : 0;

                    this.showLoader = true;
                    if (this.isSvgPresent) {
                        let isImageUploaded = await this.uploadModuleLogo('main-module-image');

                        if (!isImageUploaded) {
                            return;
                        }
                    }

                    this.functionInfo = {
                        ...this.functionInfo,
                        assigned_to: this.clickedProfileFunction,
                        position: this.Position,
                        dashboard_assigned: this.dashboard_id,
                        issaved: 0,
                        application: this.applicationFrame,
                        isMaster: this.pageData?.page2?.isMaster,
                    };

                    if (this.isHomeTemplate) {
                        this.functionInfo.section = this.group === 'LI' ? 'Upper' : 'Lower';
                    }

                    const endpoint = memberProfile.createGenericModule();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.functionInfo);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    if (this.clickedProfileFunction == 'Dashboard Page') {
                        if (this.subModulesList.length) {
                            await this.$api.put(memberProfile.reorderSubmodules(), { newOrder: this.subModulesList });
                        }
                        if (this.reorderedSubModuleLinkList.length) {
                            await this.$api.put(memberProfile.reorderSubmodulesLink(), {
                                newOrder: this.reorderedSubModuleLinkList,
                            });
                        }
                    }

                    const { template, templateData } = this.pageData.page4;
                    this.template = template;

                    if (this.applicationFrame == 'kiosk') {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.color != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }
                                        this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                        this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                    }
                                });
                            }
                        });
                    }

                    if (this.isHomeTemplate) {
                        Object.keys(this.template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == group + ' ' + this.position) {
                                        if (this.functionInfo.color != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }
                                        if (this.functionInfo.textColor != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }

                                        this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                        this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                    }
                                });
                            }
                        });
                    }

                    Object.keys(this.template).forEach((group) => {
                        if (this.applicationFrame == 'kiosk') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.clickedProfileFunction == 'Lumeca') {
                                            this.template[group][li].title = this.functionInfo.lumecatitle;
                                        } else {
                                            this.template[group][li].title = this.functionInfo.title;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    let position = this.group === 'GSI' ? this.Position[1] : this.Position;
                                    if (li === group + ' ' + position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].color = this.functionInfo?.color;
                                        this.template[group][li].textColor = this.functionInfo?.textColor;
                                        templateData[group][li].title = this.functionInfo?.title;
                                        templateData[group][li].url = this.functionInfo?.url;
                                        templateData[group][li].primaryColor = this.functionInfo.primaryColor;
                                        templateData[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                        templateData[group][li].color = this.functionInfo?.color;
                                        templateData[group][li].textColor = this.functionInfo?.textColor;
                                        templateData[group][li].document_id = this.functionInfo.document_id;
                                        templateData[group][li].company_id = this.functionInfo?.embeddedCompanyId;
                                        templateData[group][li].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                        templateData[group][li].lookup_table_id = this.functionInfo?.lookup_table_id;
                                        templateData[group][li].module_category = this.categoryInfo?.id ?? null;
                                        templateData[group][li].module_title = this.categoryInfo?.title ?? null;
                                        // templateData[group][li].enableQR = Number(this.functionInfo.enableQR);
                                        templateData[group][li].isSensitive = Number(this.functionInfo.isSensitive);
                                        templateData[group][li].sensitiveScreenTimeout = Number(
                                            this.functionInfo.sensitiveScreenTimeout
                                        );
                                        templateData[group][li].sensitiveAlertTimeout = Number(
                                            this.functionInfo.sensitiveAlertTimeout
                                        );

                                        if (this.clickedProfileFunction == 'Call Button') {
                                            templateData[group][li].isAudioEnabled = this.functionInfo.isAudioEnabled;
                                            templateData[group][li].phoneNumber = this.phoneNumber;
                                            templateData[group][li].enabledtms = this.functionInfo?.enabledtms;
                                            templateData[group][li].dtmsSettings = JSON.stringify(
                                                this.functionInfo?.dtmsSettings
                                            );
                                        }

                                        let liStyle = this.template[group][li].style ?? '';

                                        if (liStyle.includes('background-color')) {
                                            this.template[group][li].style = liStyle.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][
                                                li
                                            ].style += `background-color:${this.functionInfo.color};`;
                                        }

                                        liStyle = this.template[group][li].style;

                                        let colorText = 0;
                                        const styleArray = liStyle.split(';');

                                        for (let i = 0; i < styleArray.length; i++) {
                                            const propertyValue = styleArray[i].split(':');
                                            if (propertyValue[0].trim() === 'color') {
                                                colorText = 1;
                                                styleArray[i] = `color: ${this.functionInfo.textColor}`;
                                                break;
                                            }
                                        }

                                        this.template[group][li].style = styleArray.join(';');

                                        if (!colorText) {
                                            this.template[group][li].style += `color:${this.functionInfo.textColor};`;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame === 'star-kid') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].url = this.functionInfo.url;
                                        this.template[group][li].color = this.functionInfo.color;
                                        this.template[group][li].textColor = this.functionInfo.textColor;
                                    }
                                });
                            }
                        } else if (this.isHomeTemplate) {
                            Object.keys(this.template).forEach((group) => {
                                if (group === this.group) {
                                    Object.keys(this.template[group]).forEach((item) => {
                                        if (item == group + ' ' + this.position) {
                                            this.template[group][item].title = this.functionInfo?.title;
                                        }
                                    });
                                }
                            });
                        } else {
                            if (group === 'GSI') {
                                Object.keys(this.template[group]).forEach(async (gsi) => {
                                    if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                        this.template[group][gsi].title = this.functionInfo?.title;
                                        this.template[group][gsi].color = this.functionInfo.color;
                                        this.template[group][gsi].textColor = this.functionInfo.textColor;
                                        templateData[group][gsi].title = this.functionInfo.title;
                                        templateData[group][gsi].url = this.functionInfo.url;
                                        templateData[group][gsi].color = this.functionInfo.color;
                                        templateData[group][gsi].textColor = this.functionInfo.textColor;
                                        templateData[group][gsi].document_id = this.functionInfo.document_id;
                                        templateData[group][gsi].company_id = this.functionInfo?.embeddedCompanyId;
                                        templateData[group][gsi].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                        templateData[group][gsi].lookup_table_id = this.functionInfo?.lookup_table_id;
                                        templateData[group][gsi].module_category = this.categoryInfo?.id ?? null;
                                        templateData[group][gsi].module_title = this.categoryInfo?.title ?? null;
                                        // templateData[group][gsi].enableQR = Number(this.functionInfo.enableQR);
                                        templateData[group][gsi].isSensitive = Number(this.functionInfo.isSensitive);
                                        templateData[group][gsi].sensitiveScreenTimeout = Number(
                                            this.functionInfo.sensitiveScreenTimeout
                                        );
                                        templateData[group][gsi].sensitiveAlertTimeout = Number(
                                            this.functionInfo.sensitiveAlertTimeout
                                        );

                                        let textcolor, bgcolor;
                                        const { colorScheme } = this.pageData.page3;

                                        if (this.functionInfo.color != '') {
                                            bgcolor = this.functionInfo.color;
                                        } else {
                                            bgcolor = colorScheme.colors[0][0];
                                        }

                                        if (this.functionInfo.textColor !== '') {
                                            textcolor = this.functionInfo.textColor;
                                        } else {
                                            // textcolor = '#FFFFFF';
                                            textcolor = colorScheme.colors[0][1];
                                        }

                                        this.template[group][
                                            gsi
                                        ].style = `background-color:${bgcolor}; color:${textcolor};`;
                                    }
                                });
                            }
                        }
                    });

                    if (this.applicationFrame == 'kiosk') {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.textColor != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            //primary color of text
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }
                                    }
                                });
                            }
                        });
                    }

                    if (this.applicationFrame == 'kiosk') {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                        this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                        Object.keys(this.template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    let position = this.group === 'GSI' ? this.Position[1] : this.Position;
                                    if (li === group + ' ' + position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            templateData[group][li].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                            templateData[group][li].image = res;
                                        }
                                        this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    } else if (this.applicationFrame === 'star-kid') {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                        this.template[group][li].elements[0].id = saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    } else if (this.isHomeTemplate) {
                        Object.keys(this.template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach(async (item) => {
                                    if (item == group + ' ' + this.position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][item].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][item].elements[0].image = res;
                                        }
                                        this.template[group][item].elements[0].id =
                                            saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    } else {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'GSI') {
                                Object.keys(this.template[group]).forEach(async (gsi) => {
                                    if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                        if (this.functionInfo.image) {
                                            this.template[group][gsi].elements[0].image_id = this.functionInfo.image;
                                            templateData[group][gsi].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][gsi].elements[0].image = res;
                                            templateData[group][gsi].image = res;
                                        }
                                        this.template[group][gsi].elements[0].id =
                                            saveFunctionResponse.data.response[0];
                                    }
                                });
                            }
                        });
                    }
                    this.$toasted.success('Module added successfully !');
                    // this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                    this.$emit('close');
                } catch (e) {
                    const errorMesage = 'Please enter all required fields';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async getGenericImages() {
                const endpoint = dashboardBuilder.getAssets('cde_generic_modules');
                const assetsResponse = await this.$api.get(endpoint);

                if (!assetsResponse.data.success) {
                    throw assetsResponse.data.error;
                }

                this.genericModulePhotos = assetsResponse.data.list;
                return true;
            },
            async getSubModulemages() {
                const endpoint = dashboardBuilder.getAssets('cde_sub_modules');
                const assetsResponse = await this.$api.get(endpoint);

                if (!assetsResponse.data.success) {
                    throw assetsResponse.data.error;
                }

                this.subModulesPhotos = assetsResponse.data.list;
                return true;
            },
            async updateApplication(id) {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    const {
                        document_id,
                        lookup_table_id,
                        url,
                        embeddedCompanyId,
                        embeddedDashboardId,
                        phoneNumbers,
                        isAudioEnabled,
                        enabledtms,
                        dtmsSettings,
                        image,
                        isSensitive,
                    } = this.functionInfo;

                    if (!url && ['Video Link', 'PDF Link', 'Web Link'].includes(this.clickedProfileFunction)) {
                        if (
                            this.clickedProfileFunction !== 'Web Link' &&
                            this.urlTypeInfo?.type === 'existingFiles' &&
                            !document_id
                        ) {
                            this.$toasted.error('Please select any document');
                            return;
                        } else if (this.urlTypeInfo?.type === 'lookupTable' && !lookup_table_id) {
                            this.$toasted.error('Please select any table');
                            return;
                        }
                    }

                    if (this.clickedProfileFunction == 'Dashboard' && (!embeddedCompanyId || !embeddedDashboardId)) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Lumeca' && !phoneNumbers?.length) {
                        this.$toasted.error('Please add atleast one phone number');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && isAudioEnabled && !document_id) {
                        this.$toasted.error('Please select any audio File');
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && enabledtms && !dtmsSettings.length) {
                        this.$toasted.error('Please add atleast one DTMS setting');
                        return;
                    }

                    if (
                        (this.clickedProfileFunction !== 'Mood Tracker' ||
                            (this.clickedProfileFunction === 'Mood Tracker' && this.isHomeTemplate)) &&
                        !this.isFileAvailable() &&
                        !image.length
                    ) {
                        this.$toasted.error('Please upload or select Image');
                        return;
                    }

                    if (isSensitive && this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }

                    if (this.clickedProfileFunction == 'Call Button' && this.phoneNumber != '') {
                        if (phoneNumbers.length) {
                            this.functionInfo.phoneNumbers[0] = this.phoneNumber;
                        } else {
                            this.functionInfo.phoneNumbers.unshift(this.phoneNumber);
                        }
                    }

                    this.functionInfo.sensitiveScreenTimeout = this.functionInfo.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.functionInfo.sensitiveAlertTimeout = this.functionInfo.isSensitive
                        ? this.sensitiveAlertTimeout?.id
                        : 0;

                    this.showLoader = true;
                    const { template, templateData } = this.pageData.page4;
                    this.template = template;
                    this.functionInfo.sensitiveScreenTimeout = this.functionInfo.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;

                    if (this.isSvgPresent && this.isModuleLogoUpdated) {
                        let isImageUploaded = await this.uploadModuleLogo('main-module-image');

                        if (!isImageUploaded) {
                            return;
                        }
                    }

                    if (this.isHomeTemplate) {
                        this.functionInfo.section = this.group === 'LI' ? 'Upper' : 'Lower';
                    }

                    const endpoint = memberProfile.updateGenericModule(id);

                    const saveFunctionResponse = await this.$api.put(endpoint, {
                        ...this.functionInfo,
                        dashboard_assigned: this.dashboard_id,
                        application: this.applicationFrame,
                        generic_function: this.clickedProfileFunction,
                        isMaster: this.pageData?.page2?.isMaster,
                    });

                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    if (this.applicationFrame == 'kiosk') {
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.color != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }
                                        this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                        this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                    }
                                });
                            }
                        });
                    }

                    if (this.isHomeTemplate) {
                        Object.keys(this.template).forEach((group) => {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == group + ' ' + this.position) {
                                        if (this.functionInfo.color != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][0]
                                                };`
                                            );
                                        }

                                        if (this.functionInfo.textColor != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }

                                        this.template[group][li].primaryColor = this.functionInfo.primaryColor;
                                        this.template[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                    }
                                });
                            }
                        });
                    }

                    Object.keys(this.template).forEach((group) => {
                        if (this.applicationFrame == 'kiosk') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.clickedProfileFunction === 'Lumeca') {
                                            this.template[group][li].title = this.functionInfo.lumecatitle;
                                        } else {
                                            this.template[group][li].title = this.functionInfo.title;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    let position = this.group === 'GSI' ? this.Position[1] : this.Position;
                                    if (li === group + ' ' + position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].color = this.functionInfo?.color;
                                        this.template[group][li].textColor = this.functionInfo?.textColor;
                                        templateData[group][li].title = this.functionInfo?.title;
                                        templateData[group][li].url = this.functionInfo?.url;
                                        templateData[group][li].primaryColor = this.functionInfo.primaryColor;
                                        templateData[group][li].secondaryColor = this.functionInfo.secondaryColor;
                                        templateData[group][li].color = this.functionInfo?.color;
                                        templateData[group][li].textColor = this.functionInfo?.textColor;
                                        templateData[group][li].document_id = this.functionInfo.document_id;
                                        templateData[group][li].company_id = this.functionInfo?.embeddedCompanyId;
                                        templateData[group][li].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                        templateData[group][li].lookup_table_id = this.functionInfo?.lookup_table_id;
                                        templateData[group][li].module_category = this.categoryInfo?.id ?? null;
                                        templateData[group][li].module_title = this.categoryInfo?.title ?? null;
                                        // templateData[group][li].enableQR = Number(this.functionInfo.enableQR);
                                        templateData[group][li].isSensitive = Number(this.functionInfo.isSensitive);
                                        templateData[group][li].sensitiveScreenTimeout = Number(
                                            this.functionInfo.sensitiveScreenTimeout
                                        );
                                        templateData[group][li].sensitiveAlertTimeout = Number(
                                            this.functionInfo.sensitiveAlertTimeout
                                        );

                                        if (this.clickedProfileFunction == 'Call Button') {
                                            this.template[group][li].elements[0].phoneNumber = this.phoneNumber;
                                            templateData[group][li].isAudioEnabled = this.functionInfo.isAudioEnabled;
                                            templateData[group][li].phoneNumber = this.phoneNumber;
                                            templateData[group][li].enabledtms = this.functionInfo?.enabledtms;
                                            templateData[group][li].dtmsSettings = JSON.stringify(
                                                this.functionInfo?.dtmsSettings
                                            );
                                        }

                                        let liStyle = this.template[group][li].style ?? '';

                                        if (liStyle?.includes('background-color')) {
                                            this.template[group][li].style = liStyle.replace(
                                                /background-color:[^;]*/,
                                                `background-color:${this.functionInfo.color};`
                                            );
                                        } else {
                                            this.template[group][
                                                li
                                            ].style += `background-color:${this.functionInfo.color};`;
                                        }

                                        liStyle = this.template[group][li].style;

                                        let colorText = 0;
                                        const styleArray = liStyle.split(';');

                                        for (let i = 0; i < styleArray.length; i++) {
                                            const propertyValue = styleArray[i].split(':');
                                            if (propertyValue[0].trim() === 'color') {
                                                colorText = 1;
                                                styleArray[i] = `color: ${this.functionInfo.textColor}`;
                                                break;
                                            }
                                        }

                                        this.template[group][li].style = styleArray.join(';');

                                        if (!colorText) {
                                            this.template[group][li].style += `color:${this.functionInfo.textColor}`;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame === 'star-kid') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        this.template[group][li].title = this.functionInfo?.title;
                                        this.template[group][li].url = this.functionInfo.url;
                                        this.template[group][li].color = this.functionInfo.color;
                                        this.template[group][li].textColor = this.functionInfo.textColor;
                                    }
                                });
                            }
                        } else if (this.isHomeTemplate) {
                            Object.keys(this.template).forEach((group) => {
                                if (group === this.group) {
                                    Object.keys(this.template[group]).forEach((item) => {
                                        if (item == group + ' ' + this.position) {
                                            this.template[group][item].title = this.functionInfo?.title;
                                        }
                                    });
                                }
                            });
                        } else {
                            if (group === 'GSI') {
                                Object.keys(this.template[group]).forEach(async (gsi) => {
                                    if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                        this.template[group][gsi].title = this.functionInfo?.title;
                                        this.template[group][gsi].color = this.functionInfo.color;
                                        this.template[group][gsi].textColor = this.functionInfo.textColor;
                                        templateData[group][gsi].title = this.functionInfo.title;
                                        templateData[group][gsi].url = this.functionInfo.url;
                                        templateData[group][gsi].color = this.functionInfo.color;
                                        templateData[group][gsi].textColor = this.functionInfo.textColor;
                                        templateData[group][gsi].document_id = this.functionInfo.document_id;
                                        templateData[group][gsi].company_id = this.functionInfo?.embeddedCompanyId;
                                        templateData[group][gsi].dashboard_id = this.functionInfo?.embeddedDashboardId;
                                        templateData[group][gsi].lookup_table_id = this.functionInfo?.lookup_table_id;
                                        templateData[group][gsi].module_category = this.categoryInfo?.id ?? null;
                                        templateData[group][gsi].module_title = this.categoryInfo?.title ?? null;
                                        // templateData[group][gsi].enableQR = Number(this.functionInfo.enableQR);
                                        templateData[group][gsi].isSensitive = Number(this.functionInfo.isSensitive);
                                        templateData[group][gsi].sensitiveScreenTimeout = Number(
                                            this.functionInfo.sensitiveScreenTimeout
                                        );
                                        templateData[group][gsi].sensitiveAlertTimeout = Number(
                                            this.functionInfo.sensitiveAlertTimeout
                                        );

                                        let textcolor, bgcolor;

                                        const { colorScheme } = this.pageData.page3;

                                        if (this.functionInfo.color.length != 0) {
                                            bgcolor = this.functionInfo.color;
                                        } else {
                                            // bgcolor = 'rgba(255, 255, 255, 0.85)';
                                            bgcolor = colorScheme.colors[0][0];
                                        }

                                        if (this.functionInfo.textColor.length != 0) {
                                            textcolor = this.functionInfo.textColor;
                                        } else {
                                            // textcolor = '#FFFFFF';
                                            textcolor = colorScheme.colors[0][1];
                                        }

                                        this.template[group][
                                            gsi
                                        ].style = `background-color:${bgcolor}; color:${textcolor};`;
                                    }
                                });
                            }
                        }
                    });

                    if (this.applicationFrame == 'kiosk') {
                        // Text color
                        Object.keys(this.template).forEach((group) => {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach((li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.textColor != '') {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${this.functionInfo.textColor}`
                                            );
                                        } else {
                                            this.template[group][li].style = this.template[group][li].style.replace(
                                                /(?:^|;)\s*color\s*:[^;]*/,
                                                `color:${
                                                    this.pageData.page3.colorScheme[
                                                        this.pageData.page3.colorScheme.selectedOption
                                                    ][1]
                                                }`
                                            );
                                        }
                                    }
                                });
                            }
                        });
                    }

                    Object.keys(this.template).forEach((group) => {
                        if (this.applicationFrame == 'kiosk') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1) {
                            if (group === this.group) {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    let position = this.group === 'GSI' ? this.Position[1] : this.Position;
                                    if (li === group + ' ' + position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            templateData[group][li].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                            templateData[group][li].image = res;
                                        }
                                    }
                                });
                            }
                        } else if (this.applicationFrame === 'star-kid') {
                            if (group === 'LI') {
                                Object.keys(this.template[group]).forEach(async (li) => {
                                    if (li == 'LI ' + this.Position) {
                                        if (this.functionInfo.image) {
                                            this.template[group][li].elements[0].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][li].elements[0].image = res;
                                        }
                                    }
                                });
                            }
                        } else if (this.isHomeTemplate) {
                            Object.keys(this.template).forEach((group) => {
                                if (group === this.group) {
                                    Object.keys(this.template[group]).forEach(async (item) => {
                                        if (item == group + ' ' + this.position) {
                                            if (this.functionInfo.image) {
                                                this.template[group][item].elements[0].image_id =
                                                    this.functionInfo.image;
                                                let res = await this.geturl(this.functionInfo.image);
                                                this.template[group][item].elements[0].image = res;
                                            }
                                        }
                                    });
                                }
                            });
                        } else {
                            if (group === 'GSI') {
                                Object.keys(this.template[group]).forEach(async (gsi) => {
                                    if (gsi == 'GSI ' + JSON.stringify(this.Position - 1) + '1') {
                                        if (this.functionInfo.image) {
                                            this.template[group][gsi].elements[0].image_id = this.functionInfo.image;
                                            templateData[group][gsi].image_id = this.functionInfo.image;
                                            let res = await this.geturl(this.functionInfo.image);
                                            this.template[group][gsi].elements[0].image = res;
                                            templateData[group][gsi].image = res;
                                        }
                                    }
                                });
                            }
                        }
                    });

                    if (this.clickedProfileFunction == 'Dashboard Page') {
                        if (this.subModulesList.length) {
                            await this.$api.put(memberProfile.reorderSubmodules(), { newOrder: this.subModulesList });
                        }
                        if (this.reorderedSubModuleLinkList.length) {
                            await this.$api.put(memberProfile.reorderSubmodulesLink(), {
                                newOrder: this.reorderedSubModuleLinkList,
                            });
                        }
                    }

                    //   this.$emit('captureValues', { 'page4': { template: this.template } });
                    this.$toasted.success('Module updated successfully !');
                    // this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                    this.$emit('close');
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update module !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async updateSubModule(id) {
                try {
                    const form = this.$refs.formsubmodule;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    if (this.urlTypeInfo === 'lookupTable' && !this.submodule?.lookup_table_id) {
                        this.$toasted.error('Please select any table');
                        return;
                    }
                    if (this.urlTypeInfo === 'existingFiles' && !this.submodule?.document_id) {
                        this.$toasted.error('Please select any document');
                        return;
                    }
                    if (!this.submodule.allow3rdParty && this.submodule.image.length == 0) {
                        this.$toasted.error('Please upload or select image');
                        return;
                    }
                    if (this.submodule.allow3rdParty && !this.submodule.thirdPartyValue?.id) {
                        this.$toasted.error('Please select any 3rd party application');
                        return;
                    }
                    if (
                        this.submodule.isSensitive &&
                        this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id
                    ) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }
                    if (
                        this.submodule.isCallButtonModule &&
                        this.submodule.callButtonData.isAudioEnabled &&
                        !this.submodule.document_id
                    ) {
                        this.$toasted.error('Please select any audio File');
                        return;
                    }

                    if (
                        this.submodule.isCallButtonModule &&
                        this.submodule.callButtonData.enabledtms &&
                        !this.submodule.callButtonData.dtmsSettings.length
                    ) {
                        this.$toasted.error('Please add atleast one DTMS setting');
                        return;
                    }
                    this.submodule.sensitiveScreenTimeout = this.submodule.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.submodule.sensitiveAlertTimeout = this.submodule.isSensitive
                        ? this.sensitiveAlertTimeout?.id
                        : 0;
                    this.showLoader = true;
                    if (this.isSvgPresent && this.isModuleLogoUpdated) {
                        let isImageUploaded = await this.uploadModuleLogo('sub-module-image');

                        if (!isImageUploaded) {
                            return;
                        }
                    }
                    this.submodule = {
                        ...this.submodule,
                        isMaster: this.pageData?.page2?.isMaster,
                    };
                    const endpoint = memberProfile.updateSubModule(id);
                    const saveFunctionResponse = await this.$api.put(endpoint, this.submodule);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subModulesList.map((item, index, dummy) => {
                        if (item.id == id) {
                            dummy[index].title = this.submodule.title;
                        }
                    });

                    this.$toasted.success('Sub Module updated successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetSubModuleForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update sub module !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async addSubModule() {
                try {
                    const form = this.$refs.formsubmodule;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    if (this.urlTypeInfo === 'lookupTable' && !this.submodule?.lookup_table_id) {
                        this.$toasted.error('Please select any table');
                        return;
                    }
                    if (this.urlTypeInfo === 'existingFiles' && !this.submodule.document_id) {
                        this.$toasted.error('Please select any document');
                        return;
                    }
                    if (!this.submodule.allow3rdParty && !this.isFileAvailable() && this.submodule.image.length == 0) {
                        this.$toasted.error('Please upload or select Image');
                        return;
                    }
                    if (this.submodule.allow3rdParty && !this.submodule.thirdPartyValue?.id) {
                        this.$toasted.error('Please select any 3rd party application');
                        return;
                    }
                    if (
                        this.submodule.isSensitive &&
                        this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id
                    ) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }
                    if (
                        this.submodule.isCallButtonModule &&
                        this.submodule.callButtonData.isAudioEnabled &&
                        !this.submodule.document_id
                    ) {
                        this.$toasted.error('Please select any audio File');
                        return;
                    }

                    if (
                        this.submodule.isCallButtonModule &&
                        this.submodule.callButtonData.enabledtms &&
                        !this.submodule.callButtonData.dtmsSettings.length
                    ) {
                        this.$toasted.error('Please add atleast one DTMS setting');
                        return;
                    }
                    this.submodule.sensitiveScreenTimeout = this.submodule.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.submodule.sensitiveAlertTimeout = this.submodule.isSensitive
                        ? this.sensitiveAlertTimeout?.id
                        : 0;
                    this.showLoader = true;
                    if (this.isSvgPresent) {
                        let isImageUploaded = await this.uploadModuleLogo('sub-module-image');

                        if (!isImageUploaded) {
                            return;
                        }
                    }
                    this.submodule = {
                        ...this.submodule,
                        assigned_to: this.currentModuleId,
                        isMaster: this.pageData?.page2?.isMaster,
                        position: this.position,
                        application: this.applicationFrame,
                        dashboardId: this.dashboard_id,
                    };
                    const endpoint = memberProfile.createSubModule();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.submodule);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.submodule.thirdPartyValue = JSON.stringify(this.submodule.thirdPartyValue);

                    this.subModulesList.push({
                        id: saveFunctionResponse?.data?.response[0],
                        submodule_id: this.submodule.isCallButtonModule
                            ? saveFunctionResponse?.data?.response[0]
                            : null,
                        ...this.submodule,
                    });

                    this.$toasted.success('Sub Module added successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetSubModuleForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add submodule !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async getCategories() {
                try {
                    const endpoint = category.getCategories(this.$store.state.user.company_id);
                    const response = await this.$api.get(endpoint);
                    this.categoryList = response.data ?? [];
                } catch (err) {
                    this.$toasted.error('Failed to fetch Categories.');
                }
            },
            async fetchApplications() {
                try {
                    this.showLoader = true;
                    this.isSvgPresent = false;
                    this.isModuleLogoUpdated = false;
                    this.svgImg = '';
                    this.svgElement = '';

                    // HTTP call for get specific 3rd party application detail
                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.Position,
                        this.dashboard_id,
                        this.applicationFrame,
                        this.group === 'LI' ? 'Upper' : 'Lower'
                    );
                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    let { info } = functionResponse.data;
                    if (info.length) {
                        this.functionInfo.title = info[0].title;
                        this.functionInfo.url = info[0].url;
                        this.functionInfo.image = info[0].image ?? '';
                        this.functionInfo.color = info[0].color;
                        this.functionInfo.primaryColor = info[0].primaryColor;
                        this.functionInfo.secondaryColor = info[0].secondaryColor;
                        this.functionInfo.textColor = info[0].textColor;
                        this.functionInfo.selectedSchemeOption =
                            info[0]?.selectedSchemeOption ?? this.functionInfo?.selectedSchemeOption;

                        this.functionInfo.isSensitive = info[0].isSensitive;
                        this.functionInfo.document_id = info[0].document_id;
                        this.functionInfo.lookup_table_id = info[0].lookup_table_id;
                        this.functionInfo.sensitiveScreenTimeout = info[0].sensitiveScreenTimeout;
                        if (info[0].isSensitive) {
                            this.sensitiveScreenTimeout =
                                this.sensitiveScreenTimeoutList.find(
                                    (item) => item.id == info[0].sensitiveScreenTimeout
                                ) ?? '';
                            this.sensitiveAlertTimeout =
                                this.sensitiveAlertTimeoutList.find(
                                    (item) => item.id == info[0].sensitiveAlertTimeout
                                ) ?? '';
                        } else {
                            this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
                            this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
                        }

                        if (this.functionInfo.document_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'existingFiles');
                            this.documentInfo = this.documentList.find((item) => item.id == info[0].document_id) ?? '';
                        } else if (this.functionInfo.lookup_table_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'lookupTable');
                            this.lookupTableInfo =
                                this.lookupTableList.find((item) => item.id == info[0].lookup_table_id) ?? '';
                        } else {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'url');
                        }

                        this.functionInfo.enableQR = info[0].enableQR;
                        this.functionInfo.customUserAgent = info[0].customUserAgent;
                        this.functionInfo.embeddedDashboardId = info[0]?.embedded_dashboard_id ?? '';
                        this.functionInfo.embeddedDashboardName = info[0]?.embedded_dashboard_name ?? '';
                        this.functionInfo.embeddedCompanyId = info[0]?.embedded_company_id;
                        this.functionInfo.embeddedCompanyName = info[0]?.embedded_company_name ?? '';
                        this.functionInfo.lumecatitle = info[0].lumecatitle;
                        this.functionInfo.location = info[0].location;
                        this.functionInfo.phoneNumbers = info[0]?.phoneNumbers ? JSON.parse(info[0]?.phoneNumbers) : [];
                        (this.functionInfo.enabledtms = info[0]?.enabledtms),
                            (this.functionInfo.dtmsSettings = info[0]?.dtmsSettings
                                ? JSON.parse(info[0]?.dtmsSettings)
                                : []),
                            (this.backgroundPhoto.file_id = info[0].image);
                        this.functionInfo.isAudioEnabled = info[0].isAudioEnabled;
                        this.functionInfo.file_id = info[0].file_id;
                        this.uploadedImg = info[0].imageUrl ?? '';
                        this.currentModuleId = info[0].id;
                        this.isEditScreen = true;
                        this.functionInfo.module_category = info[0].module_category;

                        this.categoryInfo = this.categoryList.find(
                            (category) => category.id === info[0].module_category
                        );

                        if (this.clickedProfileFunction === 'Dashboard') {
                            this.companyInfo = this.companyList.find(
                                (company) => info[0].embedded_company_id === company.id
                            );
                            this.dashboardInfo = this.dashboardList.find(
                                (dashboard) => info[0].embedded_dashboard_id === dashboard.id
                            );
                        }
                        if (this.clickedProfileFunction == 'Call Button') {
                            this.phoneNumber = this.functionInfo?.phoneNumbers?.length
                                ? this.functionInfo.phoneNumbers[0]
                                : '';
                        }
                        if (this.functionInfo.selectedSchemeOption == 'module_customized_colors') {
                            this.colorScheme = {
                                ...this.colorScheme,
                                selectedOption: 'module_customized_colors',
                                module_customized_colors: [
                                    info[0].primaryColor,
                                    info[0].secondaryColor,
                                    info[0].color,
                                    info[0].textColor,
                                ],
                            };
                        }
                        if (this.clickedProfileFunction == 'Dashboard Page' && this.subModulesList.length == 0) {
                            const submodules = memberProfile.getSubModuleList(this.currentModuleId);
                            const response = await this.$api.get(submodules);
                            if (!response) {
                                throw 'e';
                            }
                            this.subModulesList = response.data;
                        }

                        if (this.functionInfo.file_id != null) {
                            this.functionInfo.url = await this.geturl(this.functionInfo.file_id);
                        }
                        if (this.functionInfo?.image) {
                            let fileExtension = this.functionInfo.image?.split('.')[1];
                            if (
                                (this.applicationFrame === 'kiosk' ||
                                    this.isHomeTemplate ||
                                    (this.applicationFrame === 'mobex-health-hub' &&
                                        this.clickedProfileFunction === 'Dashboard')) &&
                                (fileExtension === 'svg' || fileExtension === 'svg+xml')
                            ) {
                                await this.urlToSvg(this.uploadedImg, 'main-module');
                            }
                        }
                    }

                    // To fetch dashboard-list for fetched company
                    this.companyDashboardList = this.dashboardList.filter((dashboard) => {
                        return dashboard.company_id == this.functionInfo?.embeddedCompanyId;
                    });
                } catch (err) {
                    this.$toasted.error('Failed to fetch module details');
                } finally {
                    this.showLoader = false;
                }
            },

            async editSubModule(id) {
                try {
                    const endpoint = memberProfile.getSubModule(id);
                    const response = await this.$api.get(endpoint);
                    let data = response?.data?.info[0];
                    this.showMainForm = false;
                    this.showLoader = true;
                    this.isSvgPresent = false;
                    this.isModuleLogoUpdated = false;
                    this.svgImg = '';
                    this.svgElement = '';
                    this.submodule.module_category = data.module_category;
                    this.categoryInfo = this.categoryList.find((category) => category.id === data.module_category);

                    if (data.allow3rdParty) {
                        this.submodule.title = data.title;
                        this.submodulePhoto.file_id = data.image;
                        this.submodule.description = data.description;
                        this.submodule.color = data.color;
                        this.submodule.textColor = data.textColor;
                        this.submodule.allow3rdParty = data.allow3rdParty;
                        this.submodule.isSensitive = data.isSensitive;
                        this.submodule.enableQR = data.enableQR;
                        this.submodule.customUserAgent = data.customUserAgent;
                        this.submodule.thirdPartyValue = JSON.parse(data.thirdPartyValue);
                        this.currentSubModuleId = id;
                        this.subModuleType = this.subModuleTypes.find((item) => item.type === '3rdPartyApp');
                        this.filter3rdPartyApplications(id);
                    } else if (data?.submodule_id) {
                        this.submodule.title = data.title;
                        this.submodule.image = data.image;
                        this.submodule.color = data.color;
                        this.submodule.textColor = data.textColor;
                        this.submodule.document_id = data.document_id;
                        this.uploadedImgSubModule = data.imageUrl;
                        this.currentSubModuleId = id;
                        this.submodule.isCallButtonModule = true;
                        this.submodule.callButtonData = {
                            enabledtms: data.enabledtms,
                            phoneNumber: data.phoneNumber,
                            isAudioEnabled: data.isAudioEnabled,
                            dtmsSettings: JSON.parse(data.dtmsSettings),
                        };

                        if (data.document_id) {
                            this.documentInfo = this.documentList.find((item) => item.id == data.document_id) ?? '';
                        }
                        if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                            this.submodule.primaryColor = data.primaryColor ?? this.submodule.primaryColor;
                            this.submodule.secondaryColor = data.secondaryColor ?? this.submodule.secondaryColor;
                            this.submodule.selectedSchemeOption =
                                data.selectedSchemeOption ?? this.submodule?.selectedSchemeOption;

                            if (this.submodule.selectedSchemeOption == 'module_customized_colors') {
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'module_customized_colors',
                                    module_customized_colors: [
                                        this.submodule.primaryColor,
                                        this.submodule.secondaryColor,
                                        this.submodule.color,
                                        this.submodule.textColor,
                                    ],
                                };
                            } else {
                                this.colorScheme.module_customized_colors = [
                                    '#FFFFFF',
                                    '#FF6600',
                                    '#FFFFFF',
                                    '#000000',
                                ];
                            }
                        }

                        if (this.submodule?.image) {
                            let fileExtension = data.image.split('.')[1];
                            if (
                                (this.applicationFrame === 'kiosk' || this.isHomeTemplate) &&
                                (fileExtension === 'svg' || fileExtension === 'svg+xml')
                            ) {
                                await this.urlToSvg(this.uploadedImgSubModule, 'sub-module');
                            }
                        }

                        this.subModuleType = this.subModuleTypes.find((item) => item.type === 'callButton');
                        this.selectedDocumentList = this.documentList.filter((item) => item.type == 'audio');
                    } else {
                        if (this.applicationFrame !== 'kiosk' && !this.isHomeTemplate) {
                            await this.getSubModulemages();
                        }
                        this.submodule.title = data.title;
                        this.submodule.url = data.url;
                        this.submodule.image = data.image;
                        this.submodulePhoto.file_id = data.image;
                        this.submodule.description = data.description;
                        this.submodule.color = data.color;
                        this.submodule.textColor = data.textColor;
                        this.submodule.isSensitive = data.isSensitive;
                        this.submodule.enableQR = data.enableQR;
                        this.submodule.customUserAgent = data.customUserAgent;
                        this.uploadedImgSubModule = data.imageUrl ?? '';
                        this.currentSubModuleId = id;
                        this.submodule.document_id = data.document_id;
                        this.submodule.lookup_table_id = data.lookup_table_id;
                        this.submodule.document_type = data.document_type;
                        this.submodule.sensitiveScreenTimeout = data.sensitiveScreenTimeout;
                        this.submodule.sensitiveAlertTimeout = data.sensitiveAlertTimeout;
                        this.fileTypeInfo = this.fileTypeList.find((item) => item.type == data.document_type);
                        this.selectedDocumentList = this.documentList.filter(
                            (item) => item.type === data.document_type
                        );
                        if (this.submodule.document_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'existingFiles');
                            this.documentInfo = this.documentList.find((item) => item.id == data.document_id) ?? '';
                        } else if (this.submodule.lookup_table_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'lookupTable');
                            this.lookupTableInfo =
                                this.lookupTableList.find((item) => item.id == data.lookup_table_id) ?? '';
                        } else {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'url');
                        }
                        if (data.isSensitive) {
                            this.sensitiveScreenTimeout =
                                this.sensitiveScreenTimeoutList.find(
                                    (item) => item.id == data.sensitiveScreenTimeout
                                ) ?? '';
                            this.sensitiveAlertTimeout =
                                this.sensitiveAlertTimeoutList.find((item) => item.id == data.sensitiveAlertTimeout) ??
                                '';
                        } else {
                            this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
                            this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
                        }
                        if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                            this.submodule.primaryColor = data.primaryColor ?? this.submodule.primaryColor;
                            this.submodule.secondaryColor = data.secondaryColor ?? this.submodule.secondaryColor;
                            this.submodule.selectedSchemeOption =
                                data.selectedSchemeOption ?? this.submodule?.selectedSchemeOption;

                            if (this.submodule.selectedSchemeOption == 'module_customized_colors') {
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'module_customized_colors',
                                    module_customized_colors: [
                                        this.submodule.primaryColor,
                                        this.submodule.secondaryColor,
                                        this.submodule.color,
                                        this.submodule.textColor,
                                    ],
                                };
                            } else {
                                this.colorScheme.module_customized_colors = [
                                    '#FFFFFF',
                                    '#FF6600',
                                    '#FFFFFF',
                                    '#000000',
                                ];
                            }
                        }

                        if (this.submodule.file_id != null) {
                            this.submodule.url = await this.geturl(this.submodule.file_id);
                        }

                        if (this.submodule?.image) {
                            let fileExtension = data.image.split('.')[1];
                            if (
                                (this.applicationFrame === 'kiosk' || this.isHomeTemplate) &&
                                (fileExtension === 'svg' || fileExtension === 'svg+xml')
                            ) {
                                await this.urlToSvg(this.uploadedImgSubModule, 'sub-module');
                            }
                        }

                        this.subModuleType = this.subModuleTypes.find((item) => item.type === 'other');
                    }

                    this.isEditSubModule = true;
                    this.showLoader = false;
                } catch (err) {
                    this.$toasted.error('Failed to fetch sub module details');
                }
            },
            async editSubModuleLink(id) {
                try {
                    const endpoint = memberProfile.getSubModuleLink(id);
                    const response = await this.$api.get(endpoint);
                    let data = response?.data?.info[0];
                    this.showLoader = true;

                    if (data) {
                        this.submodulelink.title = data.title;
                        this.submodulelink.url = data.url;
                        this.submodulelink.color = data.color;
                        this.submodulelink.textColor = data.textColor;
                        this.submodulelink.isSensitive = data.isSensitive;
                        this.submodulelink.enableQR = data.enableQR;
                        this.submodulelink.customUserAgent = data.customUserAgent;
                        this.submodulelink.document_id = data.document_id;
                        this.submodulelink.document_type = data.document_type;
                        this.currentSubModuleLinkId = id;
                        this.submodulelink.sensitiveScreenTimeout = data.sensitiveScreenTimeout;
                        this.submodulelink.sensitiveAlertTimeout = data.sensitiveAlertTimeout;
                        this.fileTypeInfo = this.fileTypeList.find((item) => item.type == data.document_type) ?? '';
                        this.submodulelink.lookup_table_id = data.lookup_table_id;
                        this.submodulelink.module_category = data.module_category;
                        this.categoryInfo = this.categoryList.find((category) => category.id === data.module_category);

                        if (this.submodulelink.document_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'existingFiles');
                            this.documentInfo = this.documentList.find((item) => item.id == data.document_id) ?? '';
                            this.selectedDocumentList = this.documentList.filter(
                                (item) => item.type == data.document_type
                            );
                        } else if (this.submodulelink.lookup_table_id) {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'lookupTable');
                            this.lookupTableInfo =
                                this.lookupTableList.find((item) => item.id == data.lookup_table_id) ?? '';
                        } else {
                            this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'url');
                        }
                        if (data.isSensitive) {
                            this.sensitiveScreenTimeout =
                                this.sensitiveScreenTimeoutList.find(
                                    (item) => item.id == data.sensitiveScreenTimeout
                                ) ?? '';
                            this.sensitiveAlertTimeout =
                                this.sensitiveAlertTimeoutList.find((item) => item.id == data.sensitiveAlertTimeout) ??
                                '';
                        } else {
                            this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
                            this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
                        }
                        if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                            this.submodulelink.primaryColor = data.primaryColor ?? this.submodulelink.primaryColor;
                            this.submodulelink.secondaryColor =
                                data.secondaryColor ?? this.submodulelink.secondaryColor;
                            this.submodulelink.selectedSchemeOption =
                                data.selectedSchemeOption ?? this.submodulelink?.selectedSchemeOption;

                            if (this.submodulelink.selectedSchemeOption == 'module_customized_colors') {
                                this.colorScheme = {
                                    ...this.colorScheme,
                                    selectedOption: 'module_customized_colors',
                                    module_customized_colors: [
                                        this.submodulelink.primaryColor,
                                        this.submodulelink.secondaryColor,
                                        this.submodulelink.color,
                                        this.submodulelink.textColor,
                                    ],
                                };
                            } else {
                                this.colorScheme.module_customized_colors = [
                                    '#FFFFFF',
                                    '#FF6600',
                                    '#FFFFFF',
                                    '#000000',
                                ];
                            }
                        }
                        this.isEditSubModuleLink = true;
                    }
                    this.showLoader = false;
                } catch (err) {
                    this.$toasted.error('Failed to fetch sub module link details');
                }
            },
            async deleteSubModule(id) {
                try {
                    this.showLoader = true;
                    const endpoint = memberProfile.deleteSubModule(
                        id,
                        this.pageData?.page2?.isMaster,
                        this.currentModuleId
                    );
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subModulesList = this.subModulesList.filter((item) => item.id != id);

                    this.$toasted.success('Submodule deleted successfully !');
                    this.showPage('FUNCTION_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete submodule !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },

            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (
                        (['kiosk', 'mobex-health-hub'].includes(this.applicationFrame) || this.isHomeTemplate) &&
                        !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG', 'svg'].includes(fileExtension)
                    ) {
                        this.$toasted.error('Only svg, png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }
                    if (
                        !['kiosk', 'mobex-health-hub'].includes(this.applicationFrame) &&
                        !this.isHomeTemplate &&
                        !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)
                    ) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    this.showImgLoader = true;

                    try {
                        // Wrap FileReader in a Promise
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImg = readerResult;

                        // Handle SVG for kiosk and mobex-health-home
                        if ((this.applicationFrame === 'kiosk' || this.isHomeTemplate) && fileExtension === 'svg') {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, 'main-module', false);
                            this.isSvgPresent = true;
                        }
                        // Handle SVG for mobex-health-hub dashboard
                        else if (
                            fileExtension === 'svg' &&
                            this.applicationFrame === 'mobex-health-hub' &&
                            this.clickedProfileFunction === 'Dashboard'
                        ) {
                            this.svgImg = this.base64ToSvg(this.uploadedImg);
                            this.svgElement = this.svgImg;
                            if (this.functionInfo.embeddedDashboardName !== '') {
                                this.renderSvg(this.svgImg, 'main-module', false);
                                this.isSvgPresent = true;
                            }
                        }
                        // Handle other file types
                        else {
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);

                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }

                            this.functionInfo.image = uploadImgResponse.data.s3_link;
                            this.isSvgPresent = false;
                        }

                        this.backgroundPhoto = {};
                        this.isModuleLogoUpdated = true;
                        this.$toasted.success('Application image uploaded successfully!');
                    } catch (error) {
                        const errorMessage = error || 'Image upload failed!';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            base64ToSvg(base64String) {
                const base64Data = base64String.replace(/^data:image\/svg\+xml;base64,/, '');
                const svgXml = atob(base64Data);
                return svgXml;
            },
            renderSvg(svgXml, type, isColorChanged, fetchImage = false) {
                let primaryColor, secondaryColor;

                if (type == 'main-module') {
                    primaryColor = this.functionInfo.primaryColor;
                    secondaryColor = this.functionInfo.secondaryColor;
                } else {
                    primaryColor = this.submodule.primaryColor;
                    secondaryColor = this.submodule.secondaryColor;
                }

                if (!primaryColor || !secondaryColor) {
                    return;
                }

                // Parse SVG XML string
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

                // Get the SVG element
                const svgElement = svgDoc.documentElement;

                if (fetchImage) {
                    this.svgImg = svgElement;
                    return;
                }

                const allPaths = svgElement.querySelectorAll('path');

                let color = primaryColor.split('#')[1].toLowerCase();

                allPaths.forEach((path) => {
                    if (path.hasAttribute('fill')) {
                        let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                        let colorTypeAvailable = false;
                        if (isColorChanged) {
                            let colorType = path.getAttribute('id');
                            if (colorType === 'primaryColor') {
                                path.setAttribute('fill', primaryColor);
                                colorTypeAvailable = true;
                            } else if (colorType === 'secondaryColor') {
                                path.setAttribute('fill', secondaryColor);
                                colorTypeAvailable = true;
                            }
                        }
                        if (!colorTypeAvailable) {
                            if (
                                (color[0] === 'a' ||
                                    color[0] === 'b' ||
                                    color[0] === 'c' ||
                                    color[0] === 'd' ||
                                    color[0] === 'e' ||
                                    color[0] === 'f') &&
                                (color[2] === 'a' ||
                                    color[2] === 'b' ||
                                    color[2] === 'c' ||
                                    color[2] === 'd' ||
                                    color[2] === 'e' ||
                                    color[2] === 'f') &&
                                (color[4] === 'a' ||
                                    color[4] === 'b' ||
                                    color[4] === 'c' ||
                                    color[4] === 'd' ||
                                    color[4] === 'e' ||
                                    color[4] === 'f')
                            ) {
                                path.setAttribute('id', 'primaryColor');
                                path.setAttribute('fill', primaryColor);
                            } else {
                                path.setAttribute('id', 'secondaryColor');
                                path.setAttribute('fill', secondaryColor);
                            }
                        }
                    }
                });

                this.svgImg = svgElement;
            },
            async urlToSvg(url, type, fetchedImg = true) {
                const reader = new FileReader();
                let img = await fetch(url);
                let imgBlob = await img.blob();
                reader.readAsDataURL(imgBlob);

                reader.onload = () => {
                    const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                    this.svgImg = atob(base64Data);
                    this.svgElement = this.svgImg;
                    this.renderSvg(this.svgImg, type, false, fetchedImg);
                    this.isSvgPresent = true;
                };
            },
            async uploadFilesubModule(event) {
                if (event.target.files.length > 0 && !this.submodule.allow3rdParty) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (
                        ['kiosk', 'mobex-health-hub'].includes(this.applicationFrame) ||
                        (this.isHomeTemplate &&
                            !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG', 'svg'].includes(fileExtension))
                    ) {
                        this.$toasted.error('Only svg, png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }
                    if (
                        !['kiosk', 'mobex-health-hub'].includes(this.applicationFrame) &&
                        !this.isHomeTemplate &&
                        !['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)
                    ) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    this.showImgLoader = true;

                    try {
                        // Wrap FileReader in a Promise
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImgSubModule = readerResult;

                        // Handle SVG for kiosk and mobex-health-home
                        if ((this.applicationFrame === 'kiosk' || this.isHomeTemplate) && fileExtension === 'svg') {
                            this.svgImg = this.base64ToSvg(this.uploadedImgSubModule);
                            this.svgElement = this.svgImg;
                            this.renderSvg(this.svgImg, 'sub-module', false);
                            this.isSvgPresent = true;
                        }
                        // Handle other file types
                        else {
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);

                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }

                            this.submodule.image = uploadImgResponse.data.s3_link;
                            this.isSvgPresent = false;
                        }

                        this.isModuleLogoUpdated = true;
                        this.submodulePhoto = {};
                        this.$toasted.success('Application image uploaded successfully!');
                    } catch (error) {
                        const errorMessage = error || 'Image upload failed!';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            async updateSubModuleLink(id) {
                try {
                    const form = this.$refs.formlink;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    if (this.urlTypeInfo?.type === 'lookupTable' && !this.submodulelink?.lookup_table_id) {
                        this.$toasted.error('Please select any table');
                        return;
                    }
                    if (this.urlTypeInfo?.type === 'existingFiles' && !this.submodulelink?.document_id) {
                        this.$toasted.error('Please select any document');
                        return;
                    }
                    if (
                        this.submodule.isSensitive &&
                        this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id
                    ) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }

                    this.showLoader = true;

                    this.submodulelink.sensitiveScreenTimeout = this.submodulelink.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.submodulelink.sensitiveAlertTimeout = this.submodulelink.isSensitive
                        ? this.sensitiveAlertTimeout?.id
                        : 0;

                    this.submodulelink = {
                        ...this.submodulelink,
                        isMaster: this.pageData?.page2?.isMaster,
                        module_id: this.currentModuleId,
                        application: this.applicationFrame,
                    };

                    const endpoint = memberProfile.updateSubModuleLink(id);
                    const saveFunctionResponse = await this.$api.put(endpoint, this.submodulelink);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subModuleLinksList.map((item, index, dummy) => {
                        if (item.id == id) {
                            dummy[index].title = this.submodulelink.title;
                        }
                    });

                    this.$toasted.success('Sub Module link updated successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetSubModuleLinkForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to update sub module link !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.uploadedImgSubModule = '';
                this.$refs.upload.value = '';
                this.functionInfo.image = '';
                this.submodule.image = '';
                this.svgImg = '';
                this.isSvgPresent = false;
            },
            async showPage(pageType) {
                if (pageType == 'ADD_SUBMODULE') {
                    this.isSvgPresent = false;
                    await this.getSubModulemages();
                    this.showAppListing = false;
                    this.resetSubModuleForm();
                    this.uploadedImg = '';
                    this.uploadedImgSubModule = '';
                    this.showMainForm = false;
                    this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
                    this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
                    this.submodule.sensitiveScreenTimeout = this.sensitiveScreenTimeout?.id;
                    this.submodule.sensitiveAlertTimeout = this.sensitiveAlertTimeout?.id;
                    this.subModuleType = this.subModuleTypes.find((item) => item.type === 'other');
                    this.categoryInfo = '';
                    this.filter3rdPartyApplications();
                }
                if (pageType === 'FUNCTION_LISTING') {
                    this.showAppListing = true;
                    this.showMainForm = true;
                    this.isEditScreen = false;
                    this.showLinkForm = false;
                    this.isEditSubModule = false;
                    if (this.isSubmoduleLinkListChanged && this.subModuleLinksList.length) {
                        let isLinkPresent = this.reorderedSubModuleLinkList.find(
                            (item) => item.id == this.currentSubModuleId
                        );
                        if (isLinkPresent) {
                            this.reorderedSubModuleLinkList.map((item, index, dummy) => {
                                if (item.id == this.currentSubModuleId) {
                                    dummy[index].subModuleLinksList = this.subModuleLinksList;
                                }
                            });
                        } else {
                            this.reorderedSubModuleLinkList.push({
                                id: this.currentSubModuleId,
                                subModuleLinksList: this.subModuleLinksList,
                            });
                        }
                    }
                    this.submodulePhoto = {};
                    this.subModuleLinksList = [];
                    this.resetSubModuleLinkForm();
                    this.fetchApplications();
                }
                if (pageType === 'LINK_LISTING') {
                    this.showLinkListing = true;
                    this.showAppListing = false;
                    this.showMainForm = false;
                    this.isEditSubModuleLink = false;
                    this.addSubModuleLink(this.currentSubModuleId);
                } else {
                    this.showAppListing = false;
                    this.showLinkListing = false;
                    this.isEditScreen = false;
                    this.resetForm();
                    this.resetSubModuleForm();
                    this.uploadedImg = '';
                    this.uploadedImgSubModule = '';
                }
                this.resetDocumentInfo();
                this.resetLookupTableInfo();
            },
            async addSubModuleLink(submoduleid) {
                this.showLinkForm = true;
                this.showMainForm = false;
                this.showLoader = true;
                this.currentSubModuleId = submoduleid;
                if (this.subModuleLinksList.length == 0) {
                    let submoduledata = this.reorderedSubModuleLinkList.find((item) => item.id == submoduleid);
                    if (submoduledata) {
                        let data = { ...submoduledata };
                        this.subModuleLinksList = data?.subModuleLinksList;
                    } else {
                        const endpoint = memberProfile.getSubModuleLinkList(submoduleid);
                        const response = await this.$api.get(endpoint);
                        if (!response) {
                            throw 'e';
                        }
                        this.subModuleLinksList = response.data;
                    }
                }
                if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                    const { selectedOption } = this.pageData.page3.colorScheme;
                    this.submodulelink.selectedSchemeOption = this.pageData.page3.colorScheme.selectedOption;
                    this.submodulelink.primaryColor = this.pageData.page3.colorScheme[selectedOption][0];
                    this.submodulelink.secondaryColor = this.pageData.page3.colorScheme[selectedOption][1];
                    this.submodulelink.color = this.pageData.page3.colorScheme[selectedOption][2];
                    this.submodulelink.textColor = this.pageData.page3.colorScheme[selectedOption][3];
                } else {
                    this.submodulelink.color = this.color;
                    this.submodulelink.textColor = this.textColor;
                }
                this.isSubmoduleLinkListChanged = false;
                this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
                this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
                this.submodulelink.sensitiveScreenTimeout = this.sensitiveScreenTimeout?.id;
                this.submodulelink.sensitiveAlertTimeout = this.sensitiveAlertTimeout?.id;
                this.categoryInfo = '';
                this.resetDocumentInfo();
                this.resetLookupTableInfo();
                this.showLoader = false;
            },
            selectColorScheme(event, type) {
                let colors;
                switch (event.target.value) {
                    case 'primary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                    case 'secondary_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'secondary_colors',
                        };
                        colors = this.colorScheme.secondary_colors;
                        break;
                    case 'customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'customized_colors',
                        };
                        colors = this.colorScheme.customized_colors;
                        break;
                    case 'module_customized_colors':
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'module_customized_colors',
                        };
                        colors = this.colorScheme.module_customized_colors;
                        break;
                    default:
                        this.colorScheme = {
                            ...this.colorScheme,
                            selectedOption: 'primary_colors',
                        };
                        colors = this.colorScheme.primary_colors;
                        break;
                }

                if (event.target.value !== 'module_customized_colors') {
                    this.colorScheme = {
                        ...this.colorScheme,
                        module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                    };
                }

                if (type == 'main-module') {
                    this.functionInfo.primaryColor = colors[0];
                    this.functionInfo.secondaryColor = colors[1];
                    this.functionInfo.color = colors[2];
                    this.functionInfo.textColor = colors[3];
                } else if (type == 'sub-module') {
                    this.submodule.primaryColor = colors[0];
                    this.submodule.secondaryColor = colors[1];
                    this.submodule.color = colors[2];
                    this.submodule.textColor = colors[3];
                } else {
                    this.submodulelink.primaryColor = colors[0];
                    this.submodulelink.secondaryColor = colors[1];
                    this.submodulelink.color = colors[2];
                    this.submodulelink.textColor = colors[3];
                }
                if (this.isSvgPresent) {
                    this.renderSvg(this.svgElement, type, true);
                    this.isModuleLogoUpdated = true;
                }
            },
            selectCustomColorScheme(event, colorNo, type) {
                const { selectedOption } = this.colorScheme;
                let colors = [...this.colorScheme[selectedOption]];
                colors[colorNo] = event.target.value;

                if (type == 'main-module') {
                    this.functionInfo.primaryColor = colors[0];
                    this.functionInfo.secondaryColor = colors[1];
                    this.functionInfo.color = colors[2];
                    this.functionInfo.textColor = colors[3];
                } else if (type === 'sub-module') {
                    this.submodule.primaryColor = colors[0];
                    this.submodule.secondaryColor = colors[1];
                    this.submodule.color = colors[2];
                    this.submodule.textColor = colors[3];
                } else {
                    this.submodulelink.primaryColor = colors[0];
                    this.submodulelink.secondaryColor = colors[1];
                    this.submodulelink.color = colors[2];
                    this.submodulelink.textColor = colors[3];
                }
                if (type !== 'sub-module-link' && (colorNo == 0 || colorNo == 1) && this.isSvgPresent) {
                    this.isModuleLogoUpdated = true;
                    this.renderSvg(this.svgElement, type, true);
                }
            },
            async uploadModuleLogo(type) {
                try {
                    let filename = 'module-logo.svg';
                    let svgElement = this.svgImg;

                    const svgData = new XMLSerializer().serializeToString(svgElement);

                    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                    const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                    const formData = new FormData();
                    formData.append('file', svgFile);

                    const endpoint1 = memberProfile.uploadProfileFunctionsImage();
                    const uploadImgResponse = await this.$api.post(endpoint1, formData);

                    if (!uploadImgResponse.data.success) {
                        throw uploadImgResponse.data.error;
                    }

                    if (type === 'main-module-image') {
                        this.functionInfo.image = uploadImgResponse.data.s3_link;
                    } else {
                        this.submodule.image = uploadImgResponse.data.s3_link;
                    }

                    return true;
                } catch (err) {
                    this.$toasted.error('Failed to upload Image');
                }
                return false;
            },
            async addNewSubModuleLink() {
                try {
                    const form = this.$refs.formlink;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    if (this.urlTypeInfo?.type === 'lookupTable' && !this.submodulelink?.lookup_table_id) {
                        this.$toasted.error('Please select any table');
                        return;
                    }
                    if (this.urlTypeInfo?.type === 'existingFiles' && !this.submodulelink?.document_id) {
                        this.$toasted.error('Please select any document');
                        return;
                    }
                    if (
                        this.submodule.isSensitive &&
                        this.sensitiveAlertTimeout?.id >= this.sensitiveScreenTimeout?.id
                    ) {
                        this.$toasted.error(
                            'Sensitive screen timeout must be greater than Sensitive inactivity timeout'
                        );
                        return;
                    }
                    this.showLoader = true;
                    this.submodulelink.sensitiveScreenTimeout = this.submodulelink.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.submodulelink.sensitiveScreenTimeout = this.submodulelink.isSensitive
                        ? this.sensitiveScreenTimeout?.id
                        : 0;
                    this.submodulelink = {
                        ...this.submodulelink,
                        assigned_to: this.currentSubModuleId,
                        isMaster: this.pageData?.page2?.isMaster,
                        module_id: this.currentModuleId,
                        application: this.applicationFrame,
                        dashboardId: this.dashboard_id,
                    };
                    const endpoint = memberProfile.createSubModuleLink();
                    const saveFunctionResponse = await this.$api.post(endpoint, this.submodulelink);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subModuleLinksList.push({
                        id: saveFunctionResponse?.data?.response[0],
                        ...this.submodulelink,
                    });

                    this.$toasted.success('Sub Module Link added successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetSubModuleLinkForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to add submodulelink !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            async deleteSubModuleLink(id) {
                try {
                    this.showLoader = true;
                    const endpoint = memberProfile.deleteSubModuleLink(
                        id,
                        this.pageData?.page2?.isMaster,
                        this.currentModuleId
                    );
                    const saveFunctionResponse = await this.$api.delete(endpoint);
                    if (!saveFunctionResponse.data.success) {
                        throw saveFunctionResponse.data.error;
                    }

                    this.subModuleLinksList = this.subModuleLinksList.filter((subItem) => subItem.id != id);

                    this.$toasted.success('Submodule link deleted successfully !');
                    this.showPage('LINK_LISTING');
                    this.resetForm();
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to delete submodulelink !';
                    this.$toasted.error(errorMesage);
                } finally {
                    this.showLoader = false;
                }
            },
            resetForm() {
                this.functionInfo = { ...initialFunctionInfoState };
                this.uploadedImg = '';
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.form?.reset();
                    });
                }
            },
            resetSubModuleForm() {
                this.submodule = { ...subModuleState };
                this.submodule.callButtonData = {
                    enabledtms: false,
                    dtmsSettings: [],
                    isAudioEnabled: false,
                    phoneNumber: '',
                };
                this.submodule.thirdPartyValue = {
                    active: 1,
                    android_id: '',
                    deleted: 0,
                    file_id: '',
                    function_name: '',
                    function_type: '',
                    height: '',
                    width: '',
                    image: '',
                    ios_id: '',
                    id: null,
                };
                this.uploadedImgSubModule = '';
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.formsubmodule?.reset();
                    });
                }

                if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                    const { selectedOption } = this.pageData.page3.colorScheme;
                    this.submodule.selectedSchemeOption = this.pageData.page3.colorScheme.selectedOption;
                    this.submodule.primaryColor = this.pageData.page3.colorScheme[selectedOption][0];
                    this.submodule.secondaryColor = this.pageData.page3.colorScheme[selectedOption][1];
                    this.submodule.color = this.pageData.page3.colorScheme[selectedOption][2];
                    this.submodule.textColor = this.pageData.page3.colorScheme[selectedOption][3];
                } else {
                    this.submodule.color = this.color;
                    this.submodule.textColor = this.textColor;
                }
            },
            resetSubModuleLinkForm() {
                this.submodulelink = { ...subModuleLinkState };
                if (!this.showAppListing) {
                    requestAnimationFrame(() => {
                        this.$refs.formlink?.reset();
                    });
                }
                if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                    const { selectedOption } = this.pageData.page3.colorScheme;
                    this.submodulelink.selectedSchemeOption = this.pageData.page3.colorScheme.selectedOption;
                    this.submodulelink.primaryColor = this.pageData.page3.colorScheme[selectedOption][0];
                    this.submodulelink.secondaryColor = this.pageData.page3.colorScheme[selectedOption][1];
                    this.submodulelink.color = this.pageData.page3.colorScheme[selectedOption][2];
                    this.submodulelink.textColor = this.pageData.page3.colorScheme[selectedOption][3];
                } else {
                    this.submodulelink.color = this.color;
                    this.submodulelink.textColor = this.textColor;
                }
            },
            resetLookupTableInfo() {
                this.lookupTableInfo = '';
            },
            resetDocumentInfo() {
                this.documentInfo = '';
                this.fileTypeInfo = this.fileTypeList[0];
                this.selectedDocumentList = [];
            },
            resetAll() {
                this.resetForm();
                this.resetSubModuleForm();
                if (this.clickedProfileFunction !== 'Mood Tracker') {
                    this.resetFileUpload();
                }
                this.resetSubModuleLinkForm();
                this.resetDocumentInfo();
                this.resetLookupTableInfo();
                this.$emit('close');
            },
            async get3rdPartyFunctionWidgets() {
                this.showLoader = true;
                if (this.pageData.page2.thirdPartyFunctions) {
                    const requestData = {
                        functionIds: this.pageData.page2.thirdPartyFunctions.map((el) => el.id),
                    };
                    const endpoint = memberProfile.getFunctionDetailsByIds();
                    let response = await this.$api.post(endpoint, requestData);
                    let thirdPartyWidgets = response.data.data;

                    let options = thirdPartyWidgets
                        .filter((item) => item.active === 1)
                        .map((item) => ({
                            text: item.function_name,
                            value: item.id,
                        }));

                    let option1 = thirdPartyWidgets.filter((item) => item.active === 1);

                    this.allThirdPartyFunctions = options;
                    this.thirdPartyCompleteData = option1;
                }
            },
            async handleCompanySelection(e) {
                this.dashboardInfo = '';
                this.functionInfo.embeddedCompanyName = e.company_name;
                this.functionInfo.embeddedCompanyId = e.id;
                this.functionInfo.embeddedDashboardName = null;
                this.functionInfo.embeddedDashboardId = null;
                this.companyDashboardList = this.dashboardList.filter((dashboard) => {
                    return dashboard.company_id == e.id;
                });

                if (!this.companyDashboardList.length) {
                    this.$toasted.error('No kiosk dashboard available with this company');
                }
            },
            handleUrlType(val) {
                if (val.type === 'lookupTable') {
                    this.functionInfo.document_id = null;
                    this.functionInfo.url = '';
                    this.submodule.document_id = null;
                    this.submodule.url = '';
                    this.submodulelink.document_id = null;
                    this.submodulelink.url = '';
                } else if (val.type === 'existingFiles') {
                    this.functionInfo.lookup_table_id = null;
                    this.functionInfo.url = '';
                    this.submodule.lookup_table_id = null;
                    this.submodule.url = '';
                    this.submodulelink.lookup_table_id = null;
                    this.submodulelink.url = '';
                } else {
                    this.functionInfo.document_id = null;
                    this.functionInfo.lookup_table_id = null;
                    this.submodule.document_id = null;
                    this.submodule.lookup_table_id = null;
                    this.submodulelink.document_id = null;
                    this.submodulelink.lookup_table_id = null;
                }
                this.documentInfo = '';
                this.lookupTableInfo = '';
            },
            async getAllLookupTable() {
                try {
                    let list = await this.$api.get(lookupTable.getAllLookupTable());
                    let data = list.data.info;
                    this.lookupTableList = data;
                } catch (err) {
                    this.$toasted.error('Failed to retrieve Look-up table list');
                }
            },
            async getAllDashboards() {
                try {
                    let response = await this.$api.get(dashboardBuilder.getAllTemplateList('completed'));
                    const { templateMetaList } = response.data;

                    this.dashboardList = templateMetaList.filter((dashboard) => {
                        return dashboard.application == 'kiosk' && dashboard.id !== this.dashboard_id;
                    });

                    this.dashboardList.forEach((dashboard) => {
                        dashboard.embeddedDashboardName = dashboard.dashboardName;
                    });
                } catch (err) {
                    this.$toasted.error('Failed to retrieve Dashboards');
                }
            },
            async getAllCompanies() {
                try {
                    const endpoint = companies.getAllCompanies();
                    const response = await this.$api.get(endpoint);
                    this.companyList = response.data.info;
                    response.data.info?.forEach((company) => {
                        company.embeddedCompanyName = company?.company_name;
                    });
                } catch (err) {
                    this.$toasted.error('Failed to retrieve Companies');
                }
            },
            async handleDashboardSelection(e) {
                this.functionInfo.embeddedDashboardName = e.dashboardName;
                this.functionInfo.embeddedDashboardId = e.id;
                if (this.applicationFrame === 'mobex-health-hub') {
                    let pageData = JSON.parse(e.data);
                    const { colorScheme } = pageData.page3;
                    const { selectedOption } = colorScheme;
                    this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                    this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                    this.functionInfo.color = colorScheme[selectedOption][2];
                    this.functionInfo.textColor = colorScheme[selectedOption][3];
                    if (this.svgImg !== '') {
                        this.renderSvg(this.svgElement, 'main-module', true);
                        this.isSvgPresent = true;
                        this.isModuleLogoUpdated = true;
                    }
                }
            },
            addPhoneNumber() {
                if (this.phoneNumber == '' || this.phoneNumber.length != 10) {
                    return this.$toasted.error('Phone number should contains only 10 digits');
                }
                if (this.isEditPhoneNumber) {
                    this.functionInfo.phoneNumbers[this.phoneNumberIndex] = this.phoneNumber;
                } else {
                    let isPhoneNumberExists = this.functionInfo.phoneNumbers.find(
                        (phoneNumber) => phoneNumber == this.phoneNumber
                    );
                    if (isPhoneNumberExists) {
                        return this.$toasted.error('Phone number already exist');
                    }
                    this.functionInfo.phoneNumbers.push(this.phoneNumber);
                }
                this.phoneNumber = '';
                this.phoneNumberIndex = null;
                this.isEditPhoneNumber = false;
            },
            editPhoneNumber(index) {
                this.phoneNumber = this.functionInfo.phoneNumbers[index];
                this.phoneNumberIndex = index;
                this.isEditPhoneNumber = true;
            },
            deletePhoneNumber(index) {
                this.functionInfo.phoneNumbers.splice(index, 1);
                if (this.isEditPhoneNumber) {
                    this.phoneNumber = '';
                    this.isEditPhoneNumber = false;
                }
            },
            handleDTMS(event, type) {
                if (type === 'main-module') {
                    this.functionInfo.enabledtms = event.target.checked;
                } else if (type === 'sub-module') {
                    this.submodule.callButtonData.enabledtms = event.target.checked;
                }
            },
            handleAudio(event, type) {
                if (type === 'main-module') {
                    this.functionInfo.isAudioEnabled = event.target.checked;
                    this.functionInfo.document_id = null;
                } else {
                    this.submodule.callButtonData.isAudioEnabled = event.target.checked;
                    this.submodule.callButtonData.document_id = null;
                }
            },
            addDtmsSettings(type) {
                if (this.dtmsDigit == '' || this.dtmsDelay == '') {
                    return this.$toasted.error('Please fill required fields');
                }
                if (type === 'main-module') {
                    this.functionInfo.dtmsSettings.unshift({
                        delay: this.dtmsDelay,
                        digit: this.dtmsDigit,
                    });
                } else {
                    this.submodule.callButtonData.dtmsSettings.unshift({
                        delay: this.dtmsDelay,
                        digit: this.dtmsDigit,
                    });
                }
                this.dtmsDelay = '';
                this.dtmsDigit = '';
            },
            removeDtmsSettings(index, type) {
                if (type === 'main-module') {
                    this.functionInfo.dtmsSettings.splice(index, 1);
                } else {
                    this.submodule.callButtonData.dtmsSettings.splice(index, 1);
                }
            },
            backToSubModule() {
                this.isSubmoduleLinkListChanged = true;
                this.showPage('FUNCTION_LISTING');
            },
            async getDocumentList() {
                try {
                    let response = await this.$api.get(fileManager.getDocumentList());
                    let data = response?.data?.info;
                    this.documentList = data;
                    if (this.clickedProfileFunction == 'PDF Link') {
                        this.selectedDocumentList = data.filter((item) => item.type == 'pdf');
                    } else if (this.clickedProfileFunction == 'Video Link') {
                        this.selectedDocumentList = data.filter((item) => item.type == 'video');
                    } else if (this.clickedProfileFunction == 'Call Button') {
                        this.selectedDocumentList = data.filter((item) => item.type == 'audio');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch details');
                }
            },
            async getSvgList() {
                try {
                    const endpoint = svgPicker.getSvgList();
                    const res = await this.$api.get(endpoint);
                    this.allSvgList = res.data;
                    this.svgList = res.data;
                    this.searchedSvg = '';
                } catch (err) {
                    this.$toasted.error('Failed to fetch SVG List');
                }
            },
            handleFiletype(val, type) {
                if (val.type == 'pdf') {
                    this.selectedDocumentList = this.documentList.filter((item) => item.type == 'pdf');
                } else if (val.type == 'video') {
                    this.selectedDocumentList = this.documentList.filter((item) => item.type == 'video');
                } else {
                    this.selectedDocumentList = [];
                }

                if (type == 'sub-module') {
                    this.submodule.url = '';
                    this.submodule.document_id = null;
                    this.submodule.lookup_table_id = null;
                    this.submodule.document_type = val.type;
                } else {
                    this.submodulelink.url = '';
                    this.submodulelink.document_id = null;
                    this.submodulelink.lookup_table_id = null;
                    this.submodulelink.document_type = val.type;
                }

                this.urlTypeInfo = this.urlTypeList.find((item) => item.type === 'url');
                this.documentInfo = '';
            },
            handleToggleExistingFile(type) {
                if (type == 'main-module') {
                    this.functionInfo.url = '';
                    this.functionInfo.document_id = null;
                } else if (type == 'sub-module') {
                    this.submodule.url = '';
                    this.submodule.document_id = null;
                } else {
                    this.submodulelink.url = '';
                    this.submodulelink.document_id = null;
                }

                this.documentInfo = '';
            },
            handleLookupTables(type) {
                if (type == 'sub-module') {
                    this.submodule.url = '';
                    this.submodule.document_id = null;
                    this.submodule.lookup_table_id = null;
                } else {
                    this.submodulelink.url = '';
                    this.submodulelink.document_id = null;
                    this.submodulelink.lookup_table_id = null;
                }
                this.lookupTableInfo = '';
            },
            chooseFromCompany() {
                this.showSelectSvgModal = true;
                this.searchedSvg = '';
                this.svgList = this.allSvgList;

                if (this.showMainForm) {
                    const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.functionInfo?.image);
                    this.selectedSvg = selectedSvgData ?? {};
                } else {
                    const selectedSvgData = this.allSvgList.find((svg) => svg.file_id === this.submodule?.image);
                    this.selectedSvg = selectedSvgData ?? {};
                }
            },
            handleSearchedSVG() {
                const searchedText = this.searchedSvg?.toLowerCase();
                this.svgList = this.allSvgList.filter(
                    (item) =>
                        item.title.toLowerCase().includes(searchedText) ||
                        item.categories.toLowerCase().includes(searchedText)
                );
            },
            async saveSvg() {
                if (!this.selectedSvg?.file_id) {
                    return this.$toasted.error('Please select any svg image');
                }

                this.showLoader = true;

                const img = await this.geturl(this.selectedSvg?.file_id);
                await this.urlToSvg(img, this.showMainForm ? 'main-module' : 'sub-module', false);

                if (this.showMainForm) {
                    this.functionInfo.image = this.selectedSvg.file_id;
                } else {
                    this.submodule.image = this.selectedSvg.file_id;
                }

                this.showSelectSvgModal = false;
                this.isModuleLogoUpdated = true;
                this.showLoader = false;
            },
            filter3rdPartyApplications(id = null) {
                const thirdPartyApplicationIds = this.subModulesList
                    .filter((item) => item.allow3rdParty && item.id !== id)
                    .map((item) => {
                        const thirdPartyValue = JSON.parse(item.thirdPartyValue);
                        return +thirdPartyValue.id;
                    });

                this.thirdPartyFunctionWidgets = this.allThirdPartyFunctions.filter(
                    (application) => !thirdPartyApplicationIds.includes(application.value)
                );
            },
        },
        async created() {
            this.dashboard_id = this.$route?.query?.id;
            this.Position = this.position;

            if (this.applicationFrame == 'mobex-health-hub' && this.current_template != 1 && this.group === 'GSI') {
                this.Position = '1' + this.Position;
            }

            this.functionInfo.phoneNumbers = []; // Empty Phone Number List
            this.initialSensitiveScreenTimeout = this.sensitiveScreenTimeoutList.find(
                (item) => this.pageData.page3.sensitiveScreenTimeout == item.id
            );
            this.sensitiveScreenTimeout = this.initialSensitiveScreenTimeout;
            this.initialSensitiveAlertTimeout = this.sensitiveAlertTimeoutList.find(
                (item) => this.pageData.page3.sensitiveAlertTimeout == item.id
            );
            this.sensitiveAlertTimeout = this.initialSensitiveAlertTimeout;
            if (this.showAppListing) {
                const { colorScheme } = this.pageData.page3;
                if (this.applicationFrame === 'kiosk' || this.isHomeTemplate) {
                    const { selectedOption } = colorScheme;
                    this.colorScheme = {
                        ...colorScheme,
                        module_customized_colors: ['#FFFFFF', '#FF6600', '#FFFFFF', '#000000'],
                    };
                    this.isCustosmizedThemeSelected = colorScheme.selectedOption == 'customized_colors';
                    this.functionInfo.selectedSchemeOption = this.colorScheme.selectedOption;
                    this.functionInfo.primaryColor = colorScheme[selectedOption][0];
                    this.functionInfo.secondaryColor = colorScheme[selectedOption][1];
                    this.color = colorScheme[selectedOption][2];
                    this.textColor = colorScheme[selectedOption][3];
                } else if (this.applicationFrame === 'mobex-health-hub') {
                    this.color = colorScheme.colors[0][0];
                    this.textColor = colorScheme.colors[0][1];
                } else if (this.applicationFrame !== 'family-connect') {
                    this.color = colorScheme.colors[0];
                    this.textColor = colorScheme.colors[1];
                }

                this.functionInfo.color = this.color;
                this.functionInfo.textColor = this.textColor;

                const isKioskOrHub = ['kiosk', 'mobex-health-hub'].includes(this.applicationFrame);
                const isKioskOrHome = this.applicationFrame === 'kiosk' || this.isHomeTemplate;

                if (this.clickedProfileFunction === 'Dashboard') {
                    await this.getAllCompanies();
                    await this.getAllDashboards();
                }

                if (isKioskOrHub) {
                    const documentProfiles = ['Video Link', 'PDF Link', 'Dashboard Page', 'Call Button'];
                    const lookupTableProfiles = ['Video Link', 'PDF Link', 'Web Link', 'Dashboard Page'];

                    if (documentProfiles.includes(this.clickedProfileFunction)) {
                        await this.getDocumentList();
                    }
                    if (lookupTableProfiles.includes(this.clickedProfileFunction)) {
                        await this.getAllLookupTable();
                    }
                }

                if (isKioskOrHome) {
                    if (
                        this.clickedProfileFunction === 'Call Button' ||
                        (this.isHomeTemplate && this.clickedProfileFunction === 'Dashboard Page')
                    ) {
                        await this.getDocumentList();
                    }
                    await this.getSvgList();
                } else {
                    await this.getGenericImages();
                }

                if (this.clickedProfileFunction === 'Dashboard Page') {
                    await this.get3rdPartyFunctionWidgets();
                }

                await this.getCategories();
                await this.fetchApplications();
            }
        },
    };
</script>

<style scoped>
    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .img-loader-main {
        min-height: 30px;
    }

    .pp-5 {
        padding: 5% !important;
    }

    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }

    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }

    .scroll-wrapper {
        background-color: #b4cff1f6;
        margin-bottom: 5%;
        width: 100%;
        text-align: -webkit-center;
    }

    .scroll-container {
        width: 80%;
        overflow: auto;
        white-space: nowrap;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }

    .active-bg {
        width: 50px !important;
        height: 50px !important;
        border: 2px solid lime;
        padding: 2px;
    }

    .color {
        accent-color: var(--base-theme-tab-color);
    }

    .checkbox-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 30%;
        gap: 10px;
    }

    .upload-section {
        margin: 0 15% 0 0;
        border-radius: 10px;
        border: 2px solid var(--grey, #7d7d7d);
        background: #fff;
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
        cursor: pointer;
    }

    .upload-btn {
        border-radius: 6px;
        background: #5155c3;
        color: #fff;
        font-family: 'Roboto';
        box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .show-phone-number-list {
        max-height: 100px;
        height: 100px;
        overflow: auto;
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .search-svg-icon {
        background: #f1f7ff;
        border-radius: 10px;
        border: 2px solid #b1d0ff;
    }

    .search-svg-icon input {
        box-shadow: none;
        background: transparent !important;
    }

    .search-svg-icon input:focus-visible,
    .search-svg-icon input:focus {
        outline: none;
    }

    .search-svg-icon input::placeholder {
        color: #919fad;
        font-size: 14px;
    }

    .show-phone-number-list::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    .show-phone-number-list::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    .show-phone-number-list::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    .color-box {
        width: 45%;
        border-radius: 10px;
        background: #fff;
        border: 2px solid rgba(0, 0, 0, 0.12);
        /*box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);*/
    }

    .default-color {
        height: 1.93rem;
        width: 4.24rem;
        background-color: #efefef;
        border: 2px solid black;
    }
</style>

<style>
    .lumeca-form input[type='number'] {
        border-radius: 8px !important;
    }

    .lumeca-form input[type='number']::-webkit-inner-spin-button,
    .lumeca-form input[type='number']::-webkit-outer-spin-button {
        opacity: 0;
    }

    .lumeca-form input[type='number']::placeholder {
        color: #999;
    }
</style>
